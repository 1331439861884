import React, { useCallback, useEffect, useLayoutEffect } from 'react';
import PropTypes from 'prop-types';
import Common from 'src/components/Common';
import { trackScreenView } from 'src/utils/analytics/GAtracker';
import useTheme from 'src/hooks/useTheme';
import { getMessengerSource, getOSName, isElectron } from 'src/utils';
import routeNames from 'src/constants/routeNames';
import CompanyImage from 'src/components/CompanyImage';
import navigationService from 'src/utils/navigation';
import useProfile from 'src/hooks/useProfile';
import XButton from 'src/components/XButton';
import { useSafeArea } from 'react-native-safe-area-context';
import ConnectionIndicator, { ApolloConnectionIndicator } from 'src/components/ConnectionIndicator';
import Spinner from 'src/components/Common/Spinner';
import { useTwilioClient } from '@Thread-Magic/chat-utils';
import styles from './style';
import { LIVE_CHAT_STATES, MESSENGER_STATUS, NEW_REQUEST_STATES } from 'src/constants';
import { screenCommonStyles } from 'src/screens/common.styles';
import { NewChatButton } from 'src/components/ScreenButtons/NewChatButton';
import { NewRequestButton } from 'src/components/ScreenButtons/NewRequestButton';
import { withApolloProvider } from 'src/graphql/client-provider';
import useChatState from 'src/screens/Chat/hooks/useChatState';
import { normalizeTicket } from 'src/utils/normalizer';
import { useDispatchMapper } from 'src/hooks/actionHooks';
import { setActiveTicketAction } from 'src/redux/chat/chatActions';
import { ThreadServiceList } from 'src/screens/Now/components/ServicesList';
import { useAvailabilityHours } from 'src/hooks/useAvailabilityHours';
import { chatgenieAPI } from 'src/lib/api/chatgenieApi';
import { version as webAppVersion } from '../../../../web/package.json';
import WorkspaceDisabledState from 'src/screens/Now/components/WorkspaceDisabledState';
import useThreadSubscriptions from './hooks/useThreadSubscriptions';
import UserProfilePicture from 'src/components/Common/UserProfilePicture';

const Now = ({ navigation }) => {
  const { userInfo, companyInfo, uuid } = useProfile();
  const { theme, isDarkMode } = useTheme();
  const { client } = useTwilioClient();
  const insets = useSafeArea();
  const saveActiveTicket = useDispatchMapper(setActiveTicketAction);
  const { chatState } = useChatState();

  // "all", "live", "request"
  const supportType = companyInfo.supportType || 'live';
  const isLoading = !client;
  const showNewRequestButton = NEW_REQUEST_STATES.includes(supportType);
  const showLiveChatButton = LIVE_CHAT_STATES.includes(supportType);

  const isWorkspaceDisabled = companyInfo.messengerStatus === MESSENGER_STATUS.DISABLED;

  const handleThreadUpdatedEvent = useCallback(
    (thread) => {
      const normalizedThread = normalizeTicket(thread);
      const hasCurrentThreadUpdated = normalizedThread?.id === chatState.activeTicket?.id;
      if (hasCurrentThreadUpdated) {
        saveActiveTicket(normalizedThread);
      }
    },
    [chatState, saveActiveTicket],
  );

  const reportMessengerInstallationAnalytics = async () => {
    try {
      const sessionApp = getMessengerSource();

      // as default, we prepare analyticsData for web and teams
      const analyticsData = {
        installation_id: uuid,
        version: webAppVersion,
        session_app: sessionApp,
        os: getOSName(),
        installation_level: null,
        contact_id: userInfo?.contactId,
      };

      // if it is desktop - we change it
      if (isElectron() && window.ipc) {
        const { desktopVersion, desktopInstallType, desktopUUID } = await window.ipc.invoke('get-desktop-info');
        analyticsData.installation_id = desktopUUID;
        analyticsData.version = desktopVersion;
        analyticsData.installation_level = desktopInstallType; // user, system levels
      }

      chatgenieAPI.setMessengerInstallation(analyticsData);
    } catch (err) {
      console.log('err', err);
    }
  };

  useThreadSubscriptions({
    onThreadUpdated: handleThreadUpdatedEvent,
  })

  // hook that controls display of Messenger available/out-of-business hours banner
  useAvailabilityHours();

  useLayoutEffect(() => {
    navigation.setOptions({
      gestureEnabled: false,
      headerTitle: () => <CompanyImage />,
      headerLeft: () => (
        <Common.TouchableOpacity
          style={styles.accountBtn}
          activeOpacity={0.8}
          onPress={() => navigationService.navigate(routeNames.ACCOUNT)}
        >
          <UserProfilePicture
            size={37}
            url={userInfo?.profilePicture?.path}
            alt={userInfo?.fullname || userInfo?.email}
          />
        </Common.TouchableOpacity>
      ),
      headerRight: () => <XButton />,
      headerTitleContainerStyle: {
        ...screenCommonStyles.headerTitleContainerStyle,
        width: '70%',
      },
    });
  }, [theme, userInfo]);

  useEffect(() => {
    reportMessengerInstallationAnalytics();
    trackScreenView(routeNames.NOW);
  }, []);

  if (isLoading) {
    return <Spinner />;
  }

  return (
    <Common.View flexGrow={1} style={{ backgroundColor: theme.background }}>
      <ConnectionIndicator />
      <ApolloConnectionIndicator />
      {isWorkspaceDisabled ? <WorkspaceDisabledState /> : <ThreadServiceList />}
      <Common.View
        style={[styles.bottomMenu, { height: insets.bottom + 56 }, isDarkMode && { backgroundColor: theme.foreground }]}
      >
        {showNewRequestButton && (
          <NewRequestButton
            btnStyle={[
              styles.actionBtn,
              styles.actionBtnBorder,
              { paddingBottom: insets.bottom },
              isDarkMode && { borderRightColor: theme.background },
            ]}
            labelStyle={styles.btnLabel}
          />
        )}
        {showLiveChatButton && (
          <NewChatButton btnStyle={[styles.actionBtn, { paddingBottom: insets.bottom }]} labelStyle={styles.btnLabel} />
        )}
      </Common.View>
    </Common.View>
  );
};

Now.propTypes = {
  navigation: PropTypes.object.isRequired,
};

export default withApolloProvider(React.memo(Now));
