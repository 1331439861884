import React from 'react';
import { TextInput } from 'react-native';
import { colors, fonts } from 'src/constants/theme';
import useTheme from 'src/hooks/useTheme';

const getStyles = (extraStyles) => ({
  fontSize: 14,
  fontFamily: fonts.regular,
  ...extraStyles,
});

const Input = (props) => {
  const { colors: themeColors } = useTheme();
  return (
    <TextInput
      style={getStyles({ color: themeColors.text })}
      placeholderTextColor={colors.grey}
      {...props}
    />
  );
};

export default Input;
