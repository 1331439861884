import { useEffect } from 'react';

export const MAX_ATTACHMENT_SIZE = 150 * 1024 * 1024; // 150MB

export const getImageDimensions = (dataURL) =>
  new Promise((resolve, reject) => {
    const img = new Image();
    img.onload = () => {
      resolve({
        height: img.height,
        width: img.width,
      });
    };
    img.onerror = () => {
      reject();
    };
    img.src = dataURL;
  });

export const getImagePreview = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = () => {
      resolve(reader.result);
    };
    reader.onerror = () => {
      reject();
    };
    reader.readAsDataURL(file);
  });

export const useClipboardFiles = (callback, deps) => {
  useEffect(() => {
    const pasteHandler = (event) => {
      const { items, files } = event.clipboardData || event.originalEvent.clipboardData || {};
      const clipboardData = items?.length ? items : files;
      const clipboardItems = Object.values(clipboardData || {});
      const isFileInClipboard = clipboardItems.some((el) => el?.kind === 'file');
      if (isFileInClipboard) {
        const clipboardFiles = clipboardItems.map((item) => item.getAsFile());
        callback(clipboardFiles);
        // preventing file names to appear as value in textarea
        event.preventDefault();
      }
    };
    document.addEventListener('paste', pasteHandler);
    return () => {
      document.removeEventListener('paste', pasteHandler);
    };
  }, [deps]);
};
