import React, { useEffect } from 'react';
import { PostHogProvider as PostHogClientProvider, usePostHog } from 'posthog-js/react';
import { captureException } from '@sentry/react-native';
import useProfile from 'src/hooks/useProfile';
import { isElectron } from 'src/utils';
import { getSessionApp, POSTHOG_API_KEY, POSTHOG_PROVIDER_OPTIONS } from './postHog.constants';

const PostHogProvider = ({ children }) => {
  const postHog = usePostHog();
  const { companyInfo, loggedIn, userInfo } = useProfile();
  const { contactId, email, fullname, role } = userInfo || {};
  const { id: childId, parentId, name: companyName } = companyInfo || {};

  const handlePostHogIdentify = async () => {
    try {
      const sessionApp = getSessionApp();

      const identifyObj = {
        user_member_id: null,
        user_contact_id: contactId,
        user_fullname: fullname,
        user_email: email,
        user_role: role,
        company_id: childId,
        company_parent_id: parentId,
        company_psa: null,
        company_name: companyName,
        session_app: sessionApp,
      };

      if (isElectron() && window.ipc) {
        const { desktopVersion, desktopInstallType } = await window.ipc.invoke('get-desktop-info');
        identifyObj.desktopVersion = desktopVersion;
        identifyObj.desktopInstallType = desktopInstallType;
      }

      postHog?.identify(`c|${contactId}`, identifyObj);
    } catch (err) {
      captureException(new Error(`Failed handlePostHogIdentify: ${err.message}`));
    }
  };

  useEffect(() => {
    if (loggedIn && contactId) {
      handlePostHogIdentify();
    }
  }, [loggedIn, contactId]);

  return (
    <PostHogClientProvider options={POSTHOG_PROVIDER_OPTIONS} apiKey={POSTHOG_API_KEY}>
      {children}
    </PostHogClientProvider>
  );
};

export default PostHogProvider;
