import { getMessengerSource } from 'src/utils';
import { chatgenieAPI } from './chatgenieApi';

export const getMyDevices = (nextPage, { userId, role, companyId }) => {
  // Disabled until we implement site contact support
  // if (role === 'admin') {
  //   return chatgenieAPI.devices.fetchByCompany(nextPage, companyId).then(({ data }) => data);
  // }
  return chatgenieAPI.devices.fetchByContact(nextPage, userId).then(({ data }) => data);
};

export const reportDeviceService = ({ payload }) => {
  const source = getMessengerSource();
  return chatgenieAPI.createTicket({ ...payload, source }).then(({ data: { data } }) => data);
};

export const submitDeviceConfiguration = ({ ticketId, payload }) =>
  chatgenieAPI.devices.attach(ticketId, payload).catch((err) => console.log(err));

export const getCompanyContacts = ({ companyId }) =>
  chatgenieAPI.getCompanyContacts(companyId).then(({ data: { data } }) => data);
