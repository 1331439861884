import React, { useState, useEffect, useLayoutEffect, useRef, useMemo } from 'react';
import PropTypes from 'prop-types';

import Common from 'src/components/Common';
import { ArrowDownIcon } from 'src/assets/icons';
import { colors } from 'src/constants/theme';
import { Platform } from 'react-native';
import { queryCache } from 'react-query';
import { reportDeviceService, submitDeviceConfiguration, getCompanyContacts } from 'src/lib/api/connectWise';
import navigationService from 'src/utils/navigation';
import routeNames from 'src/constants/routeNames';
import reactQueryKeys from 'src/constants/reactQueryKeys';
import { trackScreenView } from 'src/utils/analytics/GAtracker';
import useProfile from 'src/hooks/useProfile';
import useTheme from 'src/hooks/useTheme';
import { useUpdate } from 'src/hooks/useFetch';
import ConfirmModal from 'src/components/ConfirmModal';
import { reportError } from 'src/utils/monitoring';
import { userRoles } from 'src/constants';
import styles from './style';
import useTranslation from 'src/hooks/useTranslation';
import { screenCommonStyles } from 'src/screens/common.styles';

const NewRequest = ({ route, navigation }) => {
  const passedDevice = route?.params?.device;
  const [state, setState] = useState({
    summary: '',
    description: '',
    device: passedDevice,
    contact: passedDevice?.contact,
  });
  const stateRef = useRef(state);
  stateRef.current = state;
  const { role, companyInfo } = useProfile();
  const [submitIssue, { isLoading: issueSaving }] = useUpdate(reportDeviceService);
  const { translate } = useTranslation();

  const isAdmin = role === userRoles.ADMIN;

  const onUpdate = (key, value) => {
    setState((prevState) => ({ ...prevState, [key]: value }));
  };

  const [submitConfiguration, { isLoading: deviceSaving }] = useUpdate(submitDeviceConfiguration, {
    onError: () => {},
  });

  const onSubmit = () => {
    const { description, device, summary } = stateRef.current;
    if (!description) {
      return ConfirmModal.alert(translate('newRequest.error.fillDescription'));
    }
    const payload = {
      summary: summary || 'New Service Request',
      initialDescription: description,
      board: 'support',
    };
    submitIssue({ payload })
      .then((res) => {
        if (res?.ticket && device) {
          submitConfiguration({
            ticketId: res.ticket.id,
            payload: {
              id: device.id,
              deviceIdentifier: device?.deviceIdentifier,
              _info: device?._info,
            },
          })
            .then(() => {
              navigationService.goBack();
            })
            .catch((err) => console.log(err));
        } else {
          navigationService.goBack();
        }
      })
      .catch((err) => {
        if (err?.response?.data?.status === 400) {
          reportError(err?.response?.data?.message);
          ConfirmModal.alert(err?.response?.data?.message);
        } else {
          ConfirmModal.alert('Failed. Please contact support!');
        }
      });
    return null;
  };

  const onSelectDevice = (selectedDevice) => {
    if (selectedDevice) {
      onUpdate('device', selectedDevice);
      navigationService.goBack();
    }
  };

  const onSelectContact = (selectedContact) => {
    if (selectedContact) {
      onUpdate('contact', {
        id: selectedContact?.id,
        contactSystemId: selectedContact.contact_system_id,
        name: selectedContact?.fullname,
      });
      navigationService.goBack();
    }
  };

  useEffect(() => {
    if (isAdmin) {
      // prefetch company contacts if user is Admin
      queryCache.prefetchQuery([reactQueryKeys.CONTACTS, { companyId: companyInfo?.id }], getCompanyContacts);
    }
    trackScreenView(routeNames.NEW_REQUEST);
  }, []);

  const loading = useMemo(() => deviceSaving || issueSaving, [deviceSaving, issueSaving]);
  const { isDarkMode, theme } = useTheme();
  const textCreate = translate('common.text.create');
  const textCreating = translate('common.text.creating');

  useLayoutEffect(() => {
    navigation.setOptions({
      headerRight: () => (
        <Common.TouchableOpacity style={styles.createBtn} disabled={loading} onPress={onSubmit}>
          <Common.Text color={theme.primaryColor} size={15} weight="400">
            {!loading ? textCreate : textCreating}
          </Common.Text>
        </Common.TouchableOpacity>
      ),
      headerTitleContainerStyle: screenCommonStyles.headerTitleContainerStyle,
    });
  }, [loading]);

  const borderDividerColor = isDarkMode ? colors.grey800 : colors.grey20;
  return (
    <Common.View flexGrow={1}>
      <Common.ScrollView
        keyboardDismissMode="interactive"
        scrollEnabled={false}
        style={{
          backgroundColor: isDarkMode ? theme.foreground : colors.white,
        }}
      >
        <Common.Row
          style={[styles.formField, isDarkMode && { backgroundColor: theme.foreground }]}
          alignItems="center"
          justifyContent="space-between"
        >
          <Common.Text bold style={styles.title}>
            {translate('newRequest.device')}
          </Common.Text>
          <Common.TouchableOpacity
            disabled={loading}
            style={styles.dropdownBtn}
            onPress={() => navigationService.navigate(routeNames.SELECT_DEVICE, { onClickListItem: onSelectDevice })}
          >
            <Common.Row flexGrow={1} alignItems="center" justifyContent="space-between">
              <Common.Text weight="400" size={14}>
                {state?.device ? state?.device.name : translate('newRequest.device.select')}
              </Common.Text>
              <ArrowDownIcon />
            </Common.Row>
          </Common.TouchableOpacity>
        </Common.Row>
        {isAdmin ? (
          <Common.Row
            style={[styles.formField, isDarkMode && { backgroundColor: theme.foreground }]}
            alignItems="center"
            justifyContent="space-between"
          >
            <Common.Text bold style={styles.title}>
              {translate('newRequest.contact')}
            </Common.Text>
            <Common.TouchableOpacity
              style={styles.dropdownBtn}
              onPress={() => navigationService.navigate(routeNames.SELECT_USER, { onClickListItem: onSelectContact })}
            >
              <Common.Row flexGrow={1} alignItems="center" justifyContent="space-between">
                <Common.Text weight="400" size={14}>
                  {state?.contact ? state?.contact?.name : translate('newRequest.contact.select')}
                </Common.Text>
                <ArrowDownIcon />
              </Common.Row>
            </Common.TouchableOpacity>
          </Common.Row>
        ) : null}
        <Common.Divider color={borderDividerColor} />
        <Common.Input
          style={[styles.formField, isDarkMode && { backgroundColor: theme.foreground }]}
          underlineColorAndroid="transparent"
          editable={!loading}
          value={state.summary}
          onChangeText={(val) => onUpdate('summary', val)}
          maxLength={100}
          placeholder={translate('newRequest.placeholder.summary')}
        />
        <Common.Divider color={borderDividerColor} />
        <Common.Input
          multiline
          underlineColorAndroid="transparent"
          editable={!loading}
          value={state.description}
          style={[styles.formField, { paddingVertical: 19 }, isDarkMode && { backgroundColor: theme.foreground }]}
          numberOfLines={Platform.OS === 'ios' ? null : 10}
          maxHeight={Platform.OS === 'ios' ? 300 : null}
          onChangeText={(val) => onUpdate('description', val)}
          placeholder={translate('newRequest.placeholder.info')}
        />
      </Common.ScrollView>
    </Common.View>
  );
};

NewRequest.propTypes = {
  navigation: PropTypes.object.isRequired,
  route: PropTypes.any.isRequired,
};

export default NewRequest;
