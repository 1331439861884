import React, { useCallback, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import Common, { MaterialCommunityIcons } from 'src/components/Common';
import { colors } from 'src/constants/theme';
import { isMobileWeb, isWeb } from 'src/utils';
import { responsiveWidth } from 'src/utils/responsive';
import styles from './style';

const ALERT_PADDING_LEFT = 15;

const AlertListArrows = ({ showRightArrow, ...props }) => {
  const [currentXOffset, setCurrentXOffset] = useState(0);
  const [reachedEnd, setReachedEnd] = useState(false);
  const listRef = useRef();
  const onScroll = useCallback(({
    nativeEvent: { layoutMeasurement, contentSize, contentOffset },
  }) => {
    const isEnd = layoutMeasurement.width + contentOffset.x >= contentSize.width - ALERT_PADDING_LEFT;
    if (isEnd) {
      setReachedEnd(true);
    } else if (isEnd !== reachedEnd) {
      setReachedEnd(false);
    }
    setCurrentXOffset(contentOffset.x);
  }, [reachedEnd]);

  const scrollTo = useCallback((xPosition) => {
    listRef?.current?.scrollTo({ x: xPosition, y: 0, animated: true });
  }, [listRef]);

  const onPressRightArrow = useCallback(() => {
    const currentXPosition = currentXOffset + responsiveWidth(75) - 10;
    scrollTo(currentXPosition);
  }, [listRef, currentXOffset]);

  const onPressLeftArrow = useCallback(() => {
    scrollTo(0);
  }, [listRef]);

  const renderArrows = () => (
    <>
      {currentXOffset > 50 ? (
        <Common.TouchableOpacity
          style={styles.leftArrow}
          onPress={onPressLeftArrow}
          testID="alerts-list-left-arrow"
        >
          <MaterialCommunityIcons name="chevron-left" size={50} color={colors.grey300} />
        </Common.TouchableOpacity>
      ) : null}
      {(!reachedEnd && showRightArrow) ? (
        <Common.TouchableOpacity
          style={styles.rightArrow}
          onPress={onPressRightArrow}
          testID="alerts-list-right-arrow"
        >
          <MaterialCommunityIcons name="chevron-right" size={50} color={colors.grey300} />
        </Common.TouchableOpacity>
      ) : null}
    </>
  );

  return (
    <>
      {props.children({ onScroll, listRef })}
      {isWeb() && !isMobileWeb() ? renderArrows() : null}
    </>
  );
};

AlertListArrows.defaultProps = {
  showRightArrow: true,
};

AlertListArrows.propTypes = {
  showRightArrow: PropTypes.bool,
  children: PropTypes.func.isRequired,
};

export default AlertListArrows;
