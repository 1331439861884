import { fonts, colors } from 'src/constants/theme';

export const styles = {
  // react-mentions styles overrides
  mentionsWrapper: {
    flexGrow: 1,
    height: 58,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    backgroundColor: '#fff',
    fontSize: 14,
    fontFamily: fonts.regular,
    // should be static, because mentions loader is absolute
    position: 'static',
  },
  mentionStyle: {
    fontFamily: fonts.regular,
    backgroundColor: colors.grey20,
    color: colors.grey800,
    borderRadius: 4,
    display: 'inline-block',
    position: 'relative',
    zIndex: 2,
    paddingTop: '1.5px',
    paddingBottom: '1.5px',
    pointerEvents: 'none',
  },
  mentionInput: {
    marginBottom: 3,
    backgroundColor: '#fff',
    // at-mentions "@" btn is 36px wide
    width: 'calc(100% - 36px)',
    control: {},
    input: {
      border: 'none',
    },
    highlighter: {
      // border: '1.5px inset transparent',
      maxHeight: 22,
    },
    '&singleLine': {},

    suggestions: {
      left: -81,
      marginTop: -5,
      boxShadow: 'rgba(0, 0, 0, 0.12) 0px -5px 10px -10px',
      list: {
        maxHeight: 150,
        overflowY: 'scroll',
        backgroundColor: 'white',
        borderBottom: '1px solid #EFEFEE',
        fontSize: 14,
      },
      item: {
        padding: '5px 10px',
        '&focused': {
          backgroundColor: '#EFEEEA',
        },
      },
    },
  },

  // at-mentions tag button
  mentionsTagBtn: {
    paddingLeft: 6,
    paddingRight: 11,
  },
  // suggestions list
  suggestionWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    flexDirection: 'row',
    overflow: 'hidden',
  },
  suggestionLabelWrapper: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    maxWidth: '58%',
  },
  suggestionLabelCircle: {
    marginRight: 10,
  },
  suggestionStatusWrapper: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  suggestionStatusMember: {
    fontSize: 12,
    color: colors.mentions.green,
    backgroundColor: colors.mentions.green2,
    marginRight: 8,
    borderRadius: 5,
    padding: 3,
  },
  suggestionInThreadStatus: {
    fontSize: 12,
    color: colors.grey8,
  },
  suggestionHeaderTitle: {
    color: '#666666',
    paddingLeft: 10,
    fontWeight: 'bold',
  },

  // loading indicator styles
  mentionsLoadingContainer: {
    width: '100%',
    position: 'absolute',
    bottom: 58,
    left: 0,
    background: '#fff',
    boxShadow: 'rgba(0, 0, 0, 0.12) 0px -5px 10px -10px',
    borderBottom: '1px solid #EFEFEE',
  },
  mentionsLoadingTitle: {
    color: '#666666',
    marginTop: 0,
    marginBottom: 10,
    fontWeight: 'bold',
  },
  mentionsLoadingSkeleton: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: 4,
  },
  loadingRect: {
    height: 25,
    flexGrow: 1,
    backgroundColor: '#ececec',
  },
  loadingCircle: {
    width: 30,
    height: 30,
    backgroundColor: '#ececec',
    borderRadius: '50%',
    marginRight: 15,
  },
};

export const getMentionInputStyles = (maxAreaWidth) => {
  return {
    ...styles.mentionInput,
    suggestions: {
      ...styles.mentionInput.suggestions,
      // suggestions block should take maximum width of widget screen
      width: maxAreaWidth,
    },
  };
};

export const getMentionsWrapperStyles = (sendBtnWidth) => {
  return {
    ...styles.mentionsWrapper,
    // mentions wrapper includes mentions input and btn "@"
    // 45px for attachment btn
    maxWidth: `calc(100% - 46px - ${sendBtnWidth}px)`,
  };
};
