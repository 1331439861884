export const colors = {
  white: '#FEFEFE',
  darkblue: '#00BB99',
  darkblue2: '#051275',
  black: '#000',
  yellow: '#ffb740',
  yellow2: '#f7cd46',
  yellow3: '#ef9837',
  blue: '#3498db',
  blue2: '#317FFF',
  grey2: '#555',
  grey3: '#777',
  grey4: '#b4b4b4',
  grey5: '#c4c4c4',
  grey6: '#cecece',
  grey7: '#B2B0A9',
  grey8: '#999999',
  grey9: '#eeeeee',
  red: '#eb4559',
  red2: '#bc1f31',
  red3: '#EB5757',
  lightGreen: '#88d13e',
  grey: '#A4A4A6',
  grey20: '#EFEFEE',
  grey100: '#E0E3E8',
  grey200: '#C4CCD4',
  grey300: '#8F9BA8',
  grey500: '#566371',
  grey600: '#485562',
  grey800: '#2C3742',
  backgroundColor: '#FAF9F6',
  lightBackground: '#FAF9F6',
  lightForeground: '#FFF',
  lightBlue: '#E1ECFE',
  darkBackground: '#14191F',
  darkForeground: '#1D252D',
  warning: '#FCECD6',
  success: '#D4F2E9',
  teal: 'teal',
  green: 'green',
  green2: '#27AE60',
  mentions: {
    blue: 'rgb(49, 127, 255)',
    blue2: 'rgba(49, 127, 255, 0.18)',
    blue3: 'rgba(49, 127, 255, 0.25)',
    blue4: 'rgba(217, 231, 254, 1)',
    green: 'rgb(0, 187, 153)',
    green2: 'rgba(0, 187, 153, 0.2)',
    green3: 'rgba(0, 187, 153, 0.3)',
    green4: 'rgb(0, 162, 132)',
    green5: 'rgb(216, 242, 235)',
  },
  priority: {
    Red: '#e06464',
    Orange: '#e4980E',
    Yellow: '#ffc367',
    White: '#4575d3',
    Purple: 'transparent',
    default: '#e7e7e7',
  },
};

export const fonts = {
  regular: 'Inter-Regular',
  bold: 'Inter-Bold',
  semiBold: 'Inter-SemiBold',
};

export const alertCardColors = {
  warning: colors.yellow,
  danger: colors.red,
  success: colors.green,
  info: colors.teal,
};

export const avatarBgColors = ['#FCECD6', '#D6DBFC', '#EFEADE', '#FFE7E5', '#D4F2E9', '#E1EFFF'];
