const reactQueryKeys = {
  TICKET_NOTES: 'TICKET_NOTES',
  TICKETS: 'TICKETS',
  CONNECTWISE_ACCOUNT: 'CONNECTWISE_ACCOUNT',
  DEVICES: 'DEVICES',
  ALERTS: 'ALERTS',
  TICKET_CONFIGURATIONS: 'TICKET_CONFIGURATIONS',
  SINGLE_CONFIGURATION: 'SINGLE_CONFIGURATION',
  CONTACTS: 'CONTACTS',
  COMPANIES: 'COMPANIES',
  CHANNELS: 'CHANNELS',
  COMPANY_INFO: 'COMPANY_INFO',
};

export default reactQueryKeys;
