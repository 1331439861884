import React, { useEffect, useLayoutEffect } from 'react';
import PropTypes from 'prop-types';
import { captureMessage } from '@sentry/react-native';
import Chatbox from 'src/components/Chatbox';
import CompanyImage from 'src/components/CompanyImage';
import XButton from 'src/components/XButton';
import useAuthenticationFlow from './hooks/useAuthenticationFlow';
import { screenCommonStyles } from 'src/screens/common.styles';
import useProfile from 'src/hooks/useProfile';
import NoAppId from 'src/screens/Auth/components/NoAppId';
import { APP_NAVIGATOR_STATES } from 'src/constants';

const Auth = ({ navigation }) => {
  const { messages, chatboxRef, inputConfig, onQuickReply, onSubmitText } = useAuthenticationFlow();
  const { appNavigatorState } = useProfile();

  useLayoutEffect(() => {
    navigation.setOptions({
      headerTitle: () => <CompanyImage />,
      headerRight: () => <XButton />,
      headerTitleContainerStyle: {
        ...screenCommonStyles.headerTitleContainerStyle,
        width: '85%',
        marginRight: 'auto',
        marginLeft: 0,
        paddingLeft: '15%',
      },
    });
  }, []);

  // TODO: remove this useEffect and the log, once we decide to enable NoAppId flow again
  useEffect(() => {
    if (appNavigatorState === APP_NAVIGATOR_STATES.hasNoAppId) {
      captureMessage('appId NOT provided!');
    }
  }, [appNavigatorState]);

  // if (appNavigatorState === APP_NAVIGATOR_STATES.hasNoAppId) return <NoAppId />;

  return (
    <Chatbox
      messages={messages}
      onSendMessage={onSubmitText}
      inputConfig={inputConfig}
      messagesRef={chatboxRef}
      onQuickReply={onQuickReply}
      isAuthChat
    />
  );
};

Auth.propTypes = {
  navigation: PropTypes.any.isRequired,
};

export default Auth;
