import { isEmpty, keyBy } from 'lodash';
import actionTypes from 'src/constants/actionTypes';
import { isElectron } from 'src/utils';

export const setUnreadAlertsAction = (payload) => ({
  type: actionTypes.SET_UNREAD_ALERTS,
  payload,
});

export const setReadAlertsAction = (payload) => ({
  type: actionTypes.SET_READ_ALERTS,
  payload,
});

export const resetUnreadAlertsAction = () => ({
  type: actionTypes.RESET_UNREAD_ALERTS,
});

export const setOpenAlertAction = (payload) => (dispatch, getState) => {
  const { alerts: alertsState } = getState();
  const newState = { ...alertsState, openAlert: payload };
  // if unread alert is opened
  if (alertsState.unreadAlerts?.[payload?.id]) {
    const newUnreadAlerts = { ...alertsState.unreadAlerts };
    // copy unread alert to read alerts object
    newState.readAlerts[payload.id] = newUnreadAlerts[payload.id];
    // delete alert from unread alerts object
    delete newUnreadAlerts[payload.id];
    // delete alert from notificaions list, to show proper icon
    newState.notificationsOrder = newState.notificationsOrder.filter(
      (alert) => alert?.id !== payload?.id,
    );
    newState.currentStatus = 'default';
    if (isElectron()) {
      if (isEmpty(newUnreadAlerts)) {
        window?.ipc?.send('show-default-icon');
      } else {
        // find next highest priority status
        // 1. danger, 2. warning, 3.info,  4. success
        const notifOrderMap = keyBy(newState.notificationsOrder, 'type');

        const nextNotif = notifOrderMap?.danger
        || notifOrderMap?.warning
        || notifOrderMap?.info
        || notifOrderMap?.success;

        if (nextNotif?.type) {
          // show correct icon on system tray
          newState.currentStatus = nextNotif.type;
          window?.ipc?.send(`show-${newState.currentStatus}-icon`);
        } else {
          // show default icon or default brand icon on system tray
          window?.ipc?.send('show-default-icon');
        }
      }
    }
    newState.unreadAlerts = newUnreadAlerts;
  } else {
    newState.currentStatus = 'default';
  }
  dispatch(({
    type: actionTypes.SET_OPEN_ALERT,
    payload: newState,
  }));
};

export const setApolloRetryStateAction = (payload) => ({
  type: actionTypes.SET_APOLLO_CONNECTION_INDICATOR,
  payload,
});
