import { isWeb } from './index';

export const webchatPayloadTypes = {
  FROM: 'CHATGENIE_MESSENGER',
  CLOSE: 'CLOSE',
  OPEN_PHOTO_PREVIEW_MODAL: 'OPEN_PHOTO_PREVIEW_MODAL',
  COMPANY_INFO: 'COMPANY_INFO',
  LOGOUT: 'LOGOUT',
  ON_LOGOUT: 'ON_LOGOUT',
  NEW_NOTIFICATION: 'NEW_NOTIFICATION',
  NOTIFICATION_CLICK: 'NOTIFICATION_CLICK',
  NOTIFICATION_COUNT: 'NOTIFICATION_COUNT',
  NOTIFICATION_PERMISSION: 'NOTIFICATION_PERMISSION',
};

const postMessageWrapperIframe = (payload) => {
  if (isWeb() && payload.type) {
    payload.from = webchatPayloadTypes.FROM;
    // we should use window.parent to communicate
    // with the nearest top level window
    window?.parent?.postMessage(payload, '*');
  }
};

export function isInIframe() {
  try {
    return window.self !== window.parent;
  } catch (e) {
    // browsers may block access to window.top due to same origin policy.
    return isWeb();
  }
}

// These methods will post a message from Iframe (localhost:3000) to Widget (localhost:4000)
const webchatIframe = {
  close: () => postMessageWrapperIframe({ type: webchatPayloadTypes.CLOSE }),
  openPhotoPreviewModal: (data) =>
    postMessageWrapperIframe({ type: webchatPayloadTypes.OPEN_PHOTO_PREVIEW_MODAL, data }),
  updateTheme: (primaryColor) =>
    postMessageWrapperIframe({
      type: webchatPayloadTypes.COMPANY_INFO,
      data: { primaryColor },
    }),
  updateCompanyInfo: (info) =>
    postMessageWrapperIframe({
      type: webchatPayloadTypes.COMPANY_INFO,
      data: info,
    }),
  onWebWidgetLogout: () =>
    postMessageWrapperIframe({
      type: webchatPayloadTypes.ON_LOGOUT,
    }),
  sendNotification: ({ title, options }) =>
    postMessageWrapperIframe({
      type: webchatPayloadTypes.NEW_NOTIFICATION,
      data: { title, options },
    }),
  updateNotificationCount: (data) =>
    postMessageWrapperIframe({
      type: webchatPayloadTypes.NOTIFICATION_COUNT,
      data,
    }),
  requestNotificationPermission: () =>
    postMessageWrapperIframe({
      type: webchatPayloadTypes.NOTIFICATION_PERMISSION,
    }),
};

export default webchatIframe;
