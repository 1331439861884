import { useSelector } from 'react-redux';
import { setOpenAlertAction, setReadAlertsAction, setUnreadAlertsAction } from 'src/redux/alerts/alertsActions';
import { useDispatchMapper } from './actionHooks';

export const useAlert = () => {
  const alerts = useSelector((store) => store?.alerts);
  const saveUnreadAlerts = useDispatchMapper(setUnreadAlertsAction);
  const saveReadAlerts = useDispatchMapper(setReadAlertsAction);
  const setOpenAlert = useDispatchMapper(setOpenAlertAction);
  return {
    unreadAlerts: alerts?.unreadAlerts,
    readAlerts: alerts?.readAlerts,
    notificationsOrder: alerts?.notificationsOrder,
    currentStatus: alerts?.currentStatus,
    saveUnreadAlerts,
    saveReadAlerts,
    setOpenAlert,
  };
};
