import { userFlows } from './index';
import { UITranslations } from 'src/translations';

export const messageTypes = {
  system: 'system',
  text: 'text',
  options: 'options',
};

export const quickReplyIds = {
  SELECT_USER_FLOW: 'SELECT_USER_FLOW',
  SELECT_VERIFY_METHOD: 'SELECT_VERIFY_METHOD',
  SELECT_DEVICE: 'SELECT_DEVICE',
  SELECT_NEW_USER_ACTION: 'SELECT_NEW_USER_ACTION',
  EMAIL_CONFIRMATION: 'EMAIL_CONFIRMATION',
  TICKET_OUTDATED: 'TICKET_OUTDATED',
  SMS_AUTHENTICATION_FAILURE: 'SMS_AUTHENTICATION_FAILURE',
  AUTHENTICATION_FLOW_RESTART: 'AUTHENTICATION_FLOW_RESTART',
  AUTHENTICATION_CODE_REENTER: 'AUTHENTICATION_CODE_REENTER',
};

export const parseBotPromptMessage = (prompt, { replaceTxt, actualTxt } = {}) => {
  if (!prompt?.length) return '';
  return prompt.replace(replaceTxt, actualTxt);
};

/**
 *
 * @param locale
 * @param translationParam
 * (translationParam) some translations are functions which accept
 * a dynamic parameter to be included in translation text
 */
export const quickReplyMessages = (locale = 'en', translationParam) => {
  const translations = UITranslations[locale];
  return {
    [quickReplyIds.SELECT_NEW_USER_ACTION]: {
      type: 'text',
      isMine: false,
      message: translations['bot.newUserAction'],
      quickReplies: {
        id: quickReplyIds.SELECT_NEW_USER_ACTION,
        values: [
          {
            label: translations['quickReply.newUser.chat'],
            value: 'chat_now',
          },
          {
            label: translations['quickReply.newUser.reach'],
            value: 'contact_later',
          },
        ],
      },
    },
    [quickReplyIds.SELECT_USER_FLOW]: {
      type: 'text',
      isMine: false,
      message: translations['bot.authGreeting'],
      quickReplies: {
        id: quickReplyIds.SELECT_USER_FLOW,
        values: [
          {
            label: translations['quickReply.user.flow.new'],
            value: userFlows.NEW_USER,
          },
          {
            label: translations['quickReply.user.flow.existing'],
            value: userFlows.EXISTING_USER,
          },
        ],
      },
    },
    [quickReplyIds.SELECT_VERIFY_METHOD]: {
      type: 'text',
      isMine: false,
      message: translations['bot.emailFound'],
      quickReplies: {
        id: quickReplyIds.SELECT_VERIFY_METHOD,
        values: [
          {
            label: translations['common.text.email'],
            value: 'email',
          },
          {
            label: translations['bot.text'](translationParam),
            value: 'text',
          },
        ],
      },
    },
    [quickReplyIds.SMS_AUTHENTICATION_FAILURE]: {
      type: 'text',
      isMine: false,
      message: '',
      quickReplies: {
        id: quickReplyIds.SMS_AUTHENTICATION_FAILURE,
        values: [
          {
            label: translations['common.text.email'],
            value: 'email',
          },
        ],
      },
    },
    [quickReplyIds.EMAIL_CONFIRMATION]: {
      type: 'text',
      isMine: false,
      quickReplies: {
        id: quickReplyIds.EMAIL_CONFIRMATION,
        values: [
          {
            label: translations['common.text.yes'],
            value: true,
          },
          {
            label: translations['common.text.no'],
            value: false,
          },
        ],
      },
    },
    [quickReplyIds.TICKET_OUTDATED]: {
      type: 'text',
      isMine: false,
      message: translations['bot.ticket.expired'],
      quickReplies: {
        id: quickReplyIds.TICKET_OUTDATED,
        values: [
          {
            label: translations['quickReply.expiredThread.createNew'],
            value: 'createNew',
          },
          {
            label: translations['quickReply.expiredThread.continueCurrent'],
            value: 'continueCurrent',
          },
        ],
      },
    },
    [quickReplyIds.AUTHENTICATION_FLOW_RESTART]: {
      type: 'text',
      isMine: false,
      message: translations['bot.contactNotFound'],
      quickReplies: {
        id: quickReplyIds.AUTHENTICATION_FLOW_RESTART,
        allowChatInput: true,
        values: [
          {
            label: translations['quickReply.authenticationFlow.restart'],
            value: 'restart_auth',
          },
        ],
      },
    },
    [quickReplyIds.AUTHENTICATION_CODE_REENTER]: {
      type: 'text',
      isMine: false,
      message: translations['bot.expiredCode'],
      quickReplies: {
        id: quickReplyIds.AUTHENTICATION_CODE_REENTER,
        allowChatInput: true,
        values: [
          {
            label: translations['quickReply.authenticationFlow.resendCode'],
            value: 'resend_code',
          },
          {
            label: translations['quickReply.authenticationFlow.restart'],
            value: 'restart_auth',
          },
        ],
      },
    },
  };
};
