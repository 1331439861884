import React, { useEffect, useState } from 'react';
import parse from 'url-parse';
import { app, authentication } from '@microsoft/teams-js';
import SuccessPage from 'src/components/SuccessPage';
import ErrorPage from 'src/components/ErrorPage';

export default function AuthEnd() {
  const [display, setDisplay] = useState(null);

  const handleTeams = (queryParams) => {
    app.initialize().then(() => {
      if (queryParams.code) {
        // Get the stored state parameter and compare with incoming state
        const expectedState = localStorage.getItem('chatgenie.state');

        if (expectedState !== queryParams.state) {
          // State does not match, report error
          authentication.notifyFailure(`StateDoesNotMatch: ${JSON.stringify(queryParams)}`);
        } else {
          // Success -- return token information to the parent page.
          authentication.notifySuccess(queryParams.code);
        }
      } else {
        // Unexpected condition: hash does not contain error or access_token parameter
        authentication.notifyFailure(`UnexpectedFailure: ${JSON.stringify(queryParams)}`);
      }
    });
  };

  const handleMessenger = (queryParams) => {
    if (queryParams.code) {
      const openDeepLink = () => (window.location.href = `thread-messenger://?open=true&code=${queryParams.code}`);
      setDisplay(<SuccessPage callback={openDeepLink} />);

      openDeepLink();
      return;
    }

    setDisplay(<ErrorPage />);
  };

  useEffect(() => {
    const queryParams = parse(window.location.href, true).query;
    const platform = localStorage.getItem('chatgenie.platform');

    if (platform === 'desktop') {
      handleMessenger(queryParams);
      return;
    }

    handleTeams(queryParams);
  }, []);

  return display;
}
