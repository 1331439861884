import React from 'react';
import PropTypes from 'prop-types';
import { colors } from 'src/constants/theme';
import { StyledView } from './style';

const View = ({
  children, height, transparent, flexGrow, color, ...rest
}) => (
  <StyledView height={height} color={transparent ? 'transparent' : color} flexGrow={flexGrow} {...rest}>
    {children}
  </StyledView>
);

View.defaultProps = {
  children: null,
  height: null,
  flexGrow: 0,
  color: colors.lightBackground,
  transparent: false,
};

View.propTypes = {
  children: PropTypes.node,
  flexGrow: PropTypes.number,
  color: PropTypes.string,
  transparent: PropTypes.bool,
  height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

export default View;
