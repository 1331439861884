import { StyleSheet } from 'react-native';
import { responsiveWidth } from 'src/utils/responsive';

export const getAttachmentBtnStyles = ({ paddingRight = 18 }) => ({
  height: '100%',
  paddingLeft: 18,
  paddingRight,
  justifyContent: 'center',
  alignItems: 'center',
  backgroundColor: '#fff',
})

const styles = StyleSheet.create({
  safeArea: {
    minHeight: 58,
    justifyContent: 'center',
    shadowOffset: {
      height: 5,
      width: 0,
    },
    shadowColor: '#EFEFEE',
    shadowOpacity: 1,
    shadowRadius: 10,
  },
  actions: {
    flexDirection: 'row',
    height: 58,
  },
  attachmentList: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    backgroundColor: '#fff',
    paddingVertical: 14,
    paddingHorizontal: 12,
  },
  textInput: {
    flexGrow: 1,
    flexShrink: 1,
    height: '100%',
    backgroundColor: 'white',
  },
  sendBtn: {
    height: '100%',
    paddingHorizontal: 18,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#fff',
  },
  attachmentPreviewImage: {
    width: 32,
    height: 32,
    borderRadius: 6,
    borderColor: '#ddd',
    borderWidth: 1,
  },
  attachmentName: {
    fontSize: 14,
    color: '#051275',
    paddingLeft: 8,
    width: responsiveWidth(100) - 32,
  },
  attachmentPreviewContainer: {
    borderRadius: 6,
    borderColor: '#ddd',
    position: 'relative',
    borderWidth: 1,
    backgroundColor: '#fff',
    flexDirection: 'row',
    alignItems: 'center',
    paddingHorizontal: 11,
    paddingVertical: 15,
    marginRight: 12,
    marginBottom: 12,
    width: 140,
  },
  attachmentRemoveBtn: {
    position: 'absolute',
    top: -6,
    right: -6,
    width: 20,
    height: 20,
    borderRadius: 10,
    backgroundColor: '#051275',
    padding: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  attachmentPreviewInner: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: 32,
    width: 32,
    backgroundColor: '#fff',
    borderWidth: 1,
    borderColor: '#ddd',
    borderRadius: 6,
  },
});
export const dragDropStyles = {
  container: {
    position: 'relative',
    minHeight: 58,
  },
  activePlaceholder: {
    position: 'absolute',
    inset: '8px 8px 8px 8px',
    zIndex: 2,
    backgroundColor: '#efeeea',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontWeight: 600,
    border: `4px dashed #dddddd`,
    outline: `8px solid #efeeea`,
    color: '#999',
  },
};
export default styles;
