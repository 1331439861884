const styles = {
  accountContainer: {
    height: '100%',
    overflow: 'scroll',
  },
  logoutBtn: {
    paddingVertical: 10,
    paddingHorizontal: 15,
  },
  padding: { paddingHorizontal: 15, paddingTop: 10 },
  integrationImage: {
    height: 37,
    width: 69,
    resizeMode: 'contain',
    marginLeft: 10,
  },
  integrationBtn: {
    height: 44,
    borderWidth: 1,
    borderColor: '#DDDDDD',
    borderRadius: 4,
    marginTop: 17,
    backgroundColor: '#FFF',
  },
  checkIcon: {
    position: 'absolute',
    right: 8,
  },
  notificationText: {
    maxWidth: 225,
  },
  greetingContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginTop: 10,
    marginBottom: 10,
  },
  accountInfo: {
    display: 'flex',
    flexWrap: 'wrap',
    flexDirection: 'row',
    marginBottom: 10,
  },
  greetingIcon: {
    width: 33,
    height: 33,
    marginLeft: 5,
  },
  appInfo: {
    paddingTop: 10,
    paddingLeft: 10,
    height: 100,
  },
  txtAppId: {
    marginTop: 15,
  },
  profilePictureWrapper: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: 10,
  },
  profilePlaceHolderAvatar: {
    borderRadius: 50,
    width: 70,
    height: 70,
  },
  profilePictureOverlay: {
    position: 'absolute',
    top: 0,
    right: 0,
    width: 70,
    height: 70,
    borderRadius: 50,
    backgroundColor: 'rgba(0, 0, 0, 0.4)',
    zIndex: 1,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  profilePic: {
    width: 70,
    height: 70,
    borderRadius: 50,
    objectFit: 'cover',
  },
  removeProfilePic: {
    marginTop: 12,
    color: '#EB5757',
  },
};

export const switchStyles = {
  switchContainer: {
    height: 26,
    width: 45,
    borderRadius: 18,
    padding: 3,
  },
  switchCircle: {
    height: 20,
    width: 20,
    borderRadius: 1000,
  },
};

export const getProfilePictureOverlayStyles = (visible) => ({
  ...styles.profilePictureOverlay,
  visibility: visible ? 'visible' : 'hidden',
});

export default styles;
