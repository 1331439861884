import { StyleSheet } from 'react-native';
import { colors } from 'src/constants/theme';
import { responsiveHeight, responsiveWidth } from 'src/utils/responsive';

const styles = StyleSheet.create({
  cardDimOverlay: {
    flexGrow: 1,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'rgba(0,0,0,0.7)',
    margin: 0,
    padding: 0,
    height: '100%',
  },
  card: {
    height: responsiveHeight(70),
    width: responsiveWidth(85),
    borderRadius: 10,
  },
  scrollview: { flexGrow: 1 },
  scrollviewContainer: { flexGrow: 1 },
  content: {
    backgroundColor: 'transparent',
    padding: 20,
    flexGrow: 1,
  },
  title: {
    marginBottom: 10,
    fontSize: 15,
    color: colors.white,
  },
  closeBtn: {
    marginBottom: 10,
    padding: 10,
  },
});

export default styles;
