import { useEffect } from 'react';
import Config from 'react-native-config';
import parse from 'url-parse';

export default function AuthStart() {
  const queryParams = parse(window.location.href, true).query;

  const options = {
    state: Date.now(),
    response_type: 'code',
    audience: Config.AUTH0_AUDIENCE,
    redirect_uri: `${Config.BASE_DOMAIN}/auth-end`,
    client_id: Config.AUTH0_CLIENT_ID,
    connection: 'Microsoft',
    scopes: 'openid profile offline_access',
  };

  useEffect(() => {
    localStorage.setItem('chatgenie.state', options.state);
    localStorage.setItem('chatgenie.platform', queryParams.platform || 'teams');
    localStorage.setItem('chatgenie.appId', queryParams.appId);

    window.location.assign(`https://${Config.AUTH0_DOMAIN}/authorize?${parse.qs.stringify(options)}`);
  }, []);

  return null;
}
