import Config from 'react-native-config';
import { isElectron, isInsideMSTeams } from 'src/utils';

export const POSTHOG_PROVIDER_OPTIONS = {
  api_host: Config.REACT_APP_PUBLIC_POSTHOG_HOST,
};

export const POSTHOG_API_KEY = Config.REACT_APP_PUBLIC_POSTHOG_KEY;

export const POSTHOG_SESSION_APP_NAMES = {
  MS_TEAMS: 'messenger_teams',
  DESKTOP: 'messenger_agent',
  WEB: 'messenger_widget',
};

export const getSessionApp = () => {
  if (isInsideMSTeams()) return POSTHOG_SESSION_APP_NAMES.MS_TEAMS;
  if (isElectron()) return POSTHOG_SESSION_APP_NAMES.DESKTOP;
  return POSTHOG_SESSION_APP_NAMES.WEB;
};