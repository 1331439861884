/* istanbul ignore file */

/* eslint-disable no-restricted-syntax */
import { useQuery, useMutation, useInfiniteQuery } from 'react-query';
import { useMemo } from 'react';

export const useFetch = (key, handler, config) => {
  const { isFetching, status, ...rest } = useQuery(key, handler, {
    onError: (err) => {
      console.log(err);
    },
    ...config,
  });
  return {
    isLoading: status === 'loading',
    isFetching: status !== 'loading' && isFetching,
    ...rest,
  };
};

const defaultConfig = {};

export const useFetchInfinite = (key, handler, config = defaultConfig) => {
  const {
    status, data, fetchMore, canFetchMore, ...rest
  } = useInfiniteQuery(
    key,
    Array.isArray(key) && key[1] ? (_, params, nextPage = 1) => handler(nextPage, params) : (_, nextPage = 1) => handler(nextPage),
    {
      queryFnParamsFilter: (args) => args,
      getFetchMore: (lastGroup) => {
        if (lastGroup?.meta?.last_page !== lastGroup?.meta?.current_page) {
          return lastGroup?.meta?.current_page + 1;
        }
        return null;
      },
      onError: (err) => {
        console.log(err);
      },
      ...config,
    },
  );
  const loadMore = () => {
    if (canFetchMore) {
      fetchMore();
    }
  };

  const memoizedData = useMemo(
    () => (Array.isArray(data) && data?.[0]?.data
      ? data.reduce((arr, elem) => {
        for (const c of elem.data) {
          arr.push(c);
        }
        return arr;
      }, [])
      : []),
    [data],
  );
  return {
    data: memoizedData,
    meta: data?.[0]?.meta || {},
    isLoading: status === 'loading',
    status,
    canFetchMore,
    fetchMore: loadMore,
    ...rest,
  };
};

export const useUpdate = (handler, config = {}) => {
  const [mutate, { isFetching, status, ...rest }] = useMutation(handler, {
    onError: (error) => console.log(error),
    ...config,
  });
  return [
    mutate,
    {
      isLoading: status === 'loading',
      isFetching: status !== 'loading' && isFetching,
      ...rest,
    },
  ];
};
