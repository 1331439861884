import React, { useEffect, useLayoutEffect, useMemo } from 'react';
import Common, { Ionicons } from 'src/components/Common';
import PropTypes from 'prop-types';
import { useFetchInfinite } from 'src/hooks/useFetch';
import reactQueryKeys from 'src/constants/reactQueryKeys';
import { chatgenieAPI } from 'src/lib/api/chatgenieApi';
import ListEmptyMessage from 'src/components/ListEmptyMessage';
import { colors } from 'src/constants/theme';
import useTheme from 'src/hooks/useTheme';
import { getEmailFromToken, isTestingEnv, isWeb } from 'src/utils';
import { normalizeUserInfoFromCompany } from 'src/utils/normalizer';
import { updateProfileAction } from 'src/redux/profile/profileActions';
import navigationService from 'src/utils/navigation';
import routeNames from 'src/constants/routeNames';
import useProfile from 'src/hooks/useProfile';
import webchatIframe from 'src/utils/webchatIframe';
import styles from './style';
import LogoutButton from 'src/components/Logout';
import useTranslation from 'src/hooks/useTranslation';
import { screenCommonStyles } from 'src/screens/common.styles';
import { useDispatchMapper } from 'src/hooks/actionHooks';

const SelectCompany = ({ navigation }) => {
  const { data, isLoading, refetch, fetchMore, isFetching, isFetchingMore } = useFetchInfinite(
    reactQueryKeys.COMPANIES,
    (nextPage, params) => chatgenieAPI.getCompanies({ page: nextPage }, params),
  );
  const { colors: themeColors, theme, isDarkMode } = useTheme();
  const { userInfo, companyInfo, token } = useProfile();
  const { translate } = useTranslation();
  const saveProfile = useDispatchMapper(updateProfileAction);

  const onSelectCompany = (company) => {
    const selectedCompany = {
      ...companyInfo,
      id: company?.id,
      parentId: company?.parent_id,
      appId: company?.app_id,
      isChildCompany: true,
    };
    saveProfile({
      companyInfo: selectedCompany,
      userInfo: {
        ...userInfo,
        ...normalizeUserInfoFromCompany(company?.contacts?.[0]),
      },
    });
    if (window?.ipc) {
      window.ipc?.send('store-data', {
        key: 'companyInfo',
        value: selectedCompany,
      });
    }
    if (isWeb()) {
      webchatIframe.updateCompanyInfo({
        appId: selectedCompany?.appId,
      });
    }
    navigationService.navigate(routeNames.NOW);
  };

  const companies = useMemo(() => {
    if (Array.isArray(data) && data.length) {
      return data?.filter((el) => !!el.is_contact);
    }
    return [];
  }, [data]);

  const renderCompanyAndAppIds = (company) => {
    if (!isTestingEnv) return null;

    return (
      <Common.Row style={{ flexDirection: 'column' }}>
        <Common.Text size={16} weight="700" color={themeColors.text}>
          -{company?.id ?? 'Unknown'}
        </Common.Text>
        <Common.Text size={16} weight="700" color={themeColors.text}>
          {company?.app_id ?? 'Unknown'}
        </Common.Text>
      </Common.Row>
    );
  };

  useEffect(() => {
    if (companies.length === 1) {
      onSelectCompany(companies[0]);
    }
  }, [companies]);

  useLayoutEffect(() => {
    navigation.setOptions({
      headerTitle: translate('common.text.selectCompany'),
      headerRight: () => <LogoutButton style={styles.logoutBtn} />,
      headerTitleContainerStyle: screenCommonStyles.headerTitleContainerStyle,
    });
  }, []);

  const noCompaniesText = translate('common.text.noCompanies');
  const emailFromToken = getEmailFromToken(token);

  return (
    <Common.View flexGrow={1} data-testid="select-company">
      <Common.FlatList
        contentContainerStyle={styles.listContainer}
        style={[
          styles.list,
          {
            backgroundColor: themeColors?.background,
          },
        ]}
        ListEmptyComponent={
          isLoading ? (
            <Common.Spinner />
          ) : (
            <ListEmptyMessage
              message={noCompaniesText}
              messages={[`App ID: ${companyInfo?.appId}`, `User email: ${emailFromToken}`]}
            />
          )
        }
        refreshing={Boolean(isFetching && data?.length)}
        onRefresh={refetch}
        data={companies}
        onEndReached={fetchMore}
        onEndReachedThreshold={0.5}
        renderItem={({ item }) => (
          <Common.TouchableOpacity
            style={[styles.listItem, isDarkMode && { borderBottomColor: theme.foreground }]}
            activeOpacity={0.6}
            onPress={() => onSelectCompany(item)}
          >
            <Common.Row flexShrink={1}>
              <Common.Text size={16} weight="700" color={themeColors.text}>
                {item?.name ?? 'Unknown'}
              </Common.Text>
              {renderCompanyAndAppIds(item)}
            </Common.Row>
            <Ionicons name="ios-arrow-forward" size={25} color={colors.grey3} />
          </Common.TouchableOpacity>
        )}
        keyExtractor={(item) => item.id?.toString()}
        ListFooterComponent={isFetchingMore && <Common.Spinner containerProps={{ marginTop: 10, marginBottom: 15 }} />}
      />
    </Common.View>
  );
};

SelectCompany.propTypes = {
  navigation: PropTypes.object.isRequired,
};

export default SelectCompany;
