import { zonedTimeToUtc } from 'date-fns-tz';

const TIME_15_DAYS = 1000 * 60 * 60 * 24 * 15; // 15 days in milliseconds

export const getTicketExpirationState = (existingTicket) => {
  const { updatedAt, statusObject, timezone = 'America/New_York' } = existingTicket || {};
  const currentTime = Date.now();
  const ticketCreationTime = zonedTimeToUtc(updatedAt || new Date(), timezone).getTime();
  const isTimeExpired = currentTime - ticketCreationTime > TIME_15_DAYS;
  const isTicketClosed = Boolean(statusObject?.closed_status);

  return isTimeExpired && isTicketClosed;
};
