import React, { useEffect, useMemo, useState, useRef } from 'react';
import { useTwilioClient } from '@Thread-Magic/chat-utils';
import TwilioChatInstance from '@Thread-Magic/chat-utils/lib/useTwilio/twilioChatHelper';
import {
  reactiveVarIsReconnected,
  reactiveVarStatus,
  reactiveVarClient,
  useReactiveVar,
} from '@Thread-Magic/thread-service-utils';
import { captureException } from '@sentry/react-native';
import { AlertIcon, SuccessIcon } from 'src/assets/icons';
import Common from '../Common';
import styles from './style';
import useTranslation from 'src/hooks/useTranslation';
import { APOLLO_CLIENT_STATUSES } from 'src/graphql/constants';
import { useDispatchMapper } from 'src/hooks/actionHooks';
import { setApolloRetryStateAction } from 'src/redux/alerts/alertsActions';
import { useSelector } from 'react-redux';

export const Alerts = ({ title, onPress, retryTitle }) => (
  <Common.Row style={styles.warning}>
    <Common.Row>
      <AlertIcon />
      <Common.Text weight="600" style={styles.text}>
        {title || 'Can’t connect right now'}
      </Common.Text>
    </Common.Row>
    {onPress ? (
      <Common.TouchableOpacity onPress={onPress} testID="retry-btn">
        <Common.Text weight="600" style={styles.retry}>
          {retryTitle || 'Retry'}
        </Common.Text>
      </Common.TouchableOpacity>
    ) : null}
  </Common.Row>
);

const Success = () => {
  const { translate } = useTranslation();

  return (
    <Common.Row style={styles.success}>
      <SuccessIcon />
      <Common.Text weight="600" style={styles.text}>
        {translate('common.connection.reconnected')}
      </Common.Text>
    </Common.Row>
  );
};

const APOLLO_CLIENT_WARNING_STATUSES = [APOLLO_CLIENT_STATUSES.error];
const SUCCESS_TIMEOUT = 3000;
const SILENT_MODE_TIMEOUT = 15 * 60 * 1000; // 15min
const GRACE_PERIOD_TIMEOUT = 10_000; // 10sec

export const ApolloConnectionIndicator = () => {
  // translations
  const { translate } = useTranslation();
  const reconnectWarningMsg = translate('common.connection.warning');
  const retryTxt = translate('common.connection.text.retry');

  const status = useReactiveVar(reactiveVarStatus);
  const isReconnected = useReactiveVar(reactiveVarIsReconnected);
  const apolloClient = reactiveVarClient();
  const retryState = useSelector((store) => store.alerts.apolloRetryState || {});
  const setApolloConnectionIndicator = useDispatchMapper(setApolloRetryStateAction);
  const [showWarning, setShowWarning] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);
  // allow some time for Apollo to re-establish connection before showing warning
  const gracePeriodRef = useRef(null);

  // if connection breaks too often show warning once in 5 minutes
  // apollo will continue reconnecting silently
  const reconnectSilently = useMemo(() => {
    const retriedAt = retryState.retriedAt || 0;
    return Date.now() - retriedAt < SILENT_MODE_TIMEOUT;
  }, [retryState, status]);

  const warningMsg = useMemo(() => {
    return (
      <Common.View transparent style={styles.apolloWarningMsg}>
        <Common.Text style={{ marginRight: 5 }}>{reconnectWarningMsg}</Common.Text>
        <Common.Spinner indicatorProps={{ size: 'small', color: '#aa967a' }} />
      </Common.View>
    );
  }, []);

  const handleReconnect = async () => {
    try {
      // re-fetch stale queries after reconnection
      await apolloClient.reFetchObservableQueries();
      setShowWarning(false);
      setShowSuccess(true);
      // show successful reconnection state for 3s
      setTimeout(() => {
        setShowSuccess(false);
      }, SUCCESS_TIMEOUT);
    } catch (err) {
      captureException(new Error(`ApolloConnectionIndicator failed to reFetchObservableQueries: ${err.message}`), {
        extra: {
          retryState,
        },
      });
    } finally {
      setApolloConnectionIndicator({
        retryCount: retryState.retryCount + 1,
        retriedAt: Date.now(), // last time when retry occurred
      });
    }
  };

  useEffect(() => {
    const isWSConnectedToApollo = status === 'connected';

    if (APOLLO_CLIENT_WARNING_STATUSES.includes(status)) {
      setShowSuccess(false);
      gracePeriodRef.current = setTimeout(() => {
        setShowWarning(true);
      }, GRACE_PERIOD_TIMEOUT);
    } else if (showWarning && (isReconnected || isWSConnectedToApollo)) {
      clearTimeout(gracePeriodRef.current);
      gracePeriodRef.current = null;
      handleReconnect();
    }
  }, [status, showWarning, isReconnected]);

  if (showSuccess) return <Success />;

  if (showWarning && !reconnectSilently)
    return <Alerts title={warningMsg} onPress={handleReconnect} retryTitle={retryTxt} />;

  return null;
};

const TwilioConnectionIndicator = () => {
  const { status } = useTwilioClient();
  const [showWarning, setShowWarning] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);

  useEffect(() => {
    if (status === 'denied' && !showWarning) {
      setShowWarning(true);
      setShowSuccess(false);
    }
    if (!showSuccess && showWarning && status !== 'denied') {
      setShowWarning(false);
      setShowSuccess(true);
      setTimeout(() => {
        setShowSuccess(false);
      }, 3000);
    }
    return () => {};
  }, [status, showWarning]);

  if (showSuccess) return <Success />;

  if (showWarning) return <Alerts onPress={() => TwilioChatInstance?.reconnect()} />;

  return null;
};

export default TwilioConnectionIndicator;
