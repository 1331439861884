import React from 'react';
import PropTypes from 'prop-types';
import Common from 'src/components/Common';
import Hoverable from 'src/components/Hoverable';
import useTheme from 'src/hooks/useTheme';
import styles from './style';

const getStyles = ({ theme, isHovered, isDarkMode }) => [styles.replyAction, { borderColor: theme.primaryColor }, isHovered && { backgroundColor: theme.primaryOpacity }, isDarkMode && { backgroundColor: theme.foreground, borderColor: 'transparent' }];

const QuickReplies = ({ data, onQuickReply }) => {
  const { theme, isDarkMode } = useTheme();
  return (
    <Common.View style={styles.wrapper}>
      {data?.values?.map((reply) => (
        <Hoverable key={reply?.value}>
          {
          (isHovered) => (
            <Common.TouchableOpacity
              onPress={() => onQuickReply(data.id, reply)}
              testID="message-reply-action"
              activeOpacity={0.6}
              style={getStyles({ theme, isHovered, isDarkMode })}
            >
              <Common.Text weight="700">{reply.label}</Common.Text>
            </Common.TouchableOpacity>
          )
        }
        </Hoverable>
      ))}
    </Common.View>
  );
};
QuickReplies.propTypes = {
  data: PropTypes.shape({
    values: PropTypes.array.isRequired,
    id: PropTypes.string.isRequired,
  }).isRequired,
  onQuickReply: PropTypes.func.isRequired,
};

export default React.memo(QuickReplies);
