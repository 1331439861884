import { useEffect } from 'react';
import { chatgenieAPI } from 'src/lib/api/chatgenieApi';
import { quickReplyIds, quickReplyMessages } from 'src/constants/messages';
import { updateProfileAction } from 'src/redux/profile/profileActions';
import Auth0 from 'src/utils/auth';
import { validateEmail } from 'src/utils';
import { useDispatchMapper } from 'src/hooks/actionHooks';
import useProfile from 'src/hooks/useProfile';
import { userFlows } from 'src/constants';
import * as Sentry from '@sentry/react-native';
import { useUserFlowUtils } from './useUserFlowUtils';
import useTranslation from 'src/hooks/useTranslation';
import { smsEnabledAppIds } from 'src/config';

export const useExistingUserFlow = ({
  state,
  updateState,
  addBotMessage,
  addMessage,
  addMyMessage,
  clearChatMessages,
}) => {
  const {
    authed,
    email,
    fullname,
    flowViaLink,
    companyName,
    flow,
    flowStarted,
    connection,
    codeSent,
    isPortalIntegration,
    smsAuthenticationFailed,
  } = state;
  const saveProfile = useDispatchMapper(updateProfileAction);

  const { validateOTP, sendOTP, selectIfOneCompany } = useUserFlowUtils({ state, updateState, addBotMessage });
  const { companyInfo } = useProfile();
  const { translate, locale } = useTranslation();
  const isSmsEnabled = smsEnabledAppIds.includes(companyInfo?.appId);

  /**
   *
   * User auth for those who have both email and phone number is handled here
   * For more info, search for quickReplyIds.SELECT_VERIFY_METHOD
   */
  const handleVerificationMethod = (reply) => {
    if (reply.value === 'text') {
      updateState({ connection: 'sms' });
      sendOTP({ email, connection: 'sms' });
    } else if (reply.value === 'email') {
      updateState({ connection: 'email' });
      sendOTP({ email });
    }
  };

  const handleSmsAuthFailure = (reply) => {
    if (reply.value === 'email') {
      updateState({ connection: 'email' });
      sendOTP({ email });
    }
  };

  const handleAuthFlowRestart = () => {
    const flow = companyInfo.parentCompanySalesFlowEnabled ? null : userFlows.EXISTING_USER;

    clearChatMessages();
    updateState({
      email: null,
      connection: 'email',
      phoneNumberTip: null,
      otp: null,
      fullname: null,
      companyName: null,
      flow,
      codeSent: false,
      inputConfig: null,
      authed: false,
      flowViaLink: true,
      flowStarted: false,
    });
    if (!flow) {
      addMessage(quickReplyMessages(locale)[quickReplyIds.SELECT_USER_FLOW]);
    }
  };

  const handleCodeReenter = (reply) => {
    if (reply?.value === 'resend_code') {
      updateState({ connection: 'email' });
      sendOTP({ email });
    } else if (reply?.value === 'restart_auth') {
      handleAuthFlowRestart();
    }
  };

  /**
   *
   * Sends choice to user to choose phone or email verification method
   * if user has no phone number, email verification is used by default
   */
  const sendValidationMessage = ({ email, fullname, phoneNumberTip }) => {
    updateState({
      inputConfig: {
        keyboardType: 'number-pad',
      },
    });
    if (!phoneNumberTip) {
      return sendOTP({ email, fullname });
    }
    addMessage(quickReplyMessages(locale, phoneNumberTip)[quickReplyIds.SELECT_VERIFY_METHOD]);
  };

  const openBrowserSignIn = () => {
    Auth0.login()
      .then(async (res) => {
        if (res?.accessToken) {
          saveProfile({
            token: res?.accessToken,
            userInfo: {
              email,
            },
          });
        }
      })
      .catch((err) => {
        console.log('web auth err', err);
      });
  };

  const validateUser = (passedEmail) => {
    const userEmail = passedEmail?.trim();
    if (validateEmail(userEmail)) {
      updateState({ inputConfig: null });
      chatgenieAPI
        .searchUser(userEmail)
        .then((response) => {
          const { email, last_4_phone } = response?.data?.data;
          const phoneNumberTip = isSmsEnabled ? last_4_phone : null;
          if (response.data) {
            updateState({
              email,
              fullname: true,
              phoneNumberTip,
            });
            sendValidationMessage({ email: userEmail, phoneNumberTip });
          }
        })
        .catch((err) => {
          if (err?.response?.data?.status === 404) {
            if (companyInfo.childCompanyAllowNewContacts) {
              updateState({ email: userEmail });
              addBotMessage(translate('bot.askFullnameExistingCompany'));
            } else {
              addMessage(quickReplyMessages()[quickReplyIds.AUTHENTICATION_FLOW_RESTART]);
            }
          } else {
            addBotMessage(translate('bot.contactSupport'));
          }
        });
    } else {
      addBotMessage(translate('bot.invalidEmail'));
    }
  };

  const handleSelectCompany = (token, company) => {
    if (token && !company) {
      if (isPortalIntegration && companyName) {
        createCompanyAndSelect(companyName);
      } else {
        addBotMessage(translate('bot.companyNotFound'));
        Sentry.captureException(`User logged in, but no companies found for this ${email}`);
      }
    }
  };

  const handleOTPVerification = async (text) => {
    try {
      const { token, company } = await validateOTP(text);
      handleSelectCompany(token, company);
    } catch (err) {
      Sentry.captureException(err);
      addBotMessage(translate('bot.errorMessage'));
    }
  };

  const createCompanyAndSelect = async (name) => {
    try {
      const response = await chatgenieAPI.createCompany(name);
      if (response.status === 201 && response.data?.data) {
        selectIfOneCompany([response.data.data]);
      }
    } catch (error) {
      console.log('error:', error);
    }
  };

  /**
   * Receives message inputs from existing users
   * @param text - String
   */
  const handleOldUserMessage = (text) => {
    addMyMessage(text);
    if (!authed) {
      if (!email) {
        validateUser(text);
      } else if (!fullname) {
        updateState({ fullname: text });
        sendValidationMessage({ email, fullname: text });
      } else if (codeSent) {
        handleOTPVerification(text);
      }
    }
  };

  const handleSilentAuthOldUser = (userEmail) => {
    sendValidationMessage({ email: userEmail });
  };

  useEffect(() => {
    if (flow === userFlows.EXISTING_USER && !flowStarted) {
      if (!email) {
        if (flowViaLink) {
          addBotMessage(translate('bot.askEmailExistingUser2'));
        } else {
          addBotMessage(translate('bot.askEmailExistingUser'));
        }
      }
      updateState({ flowStarted: true });
    }
  }, [flow, flowStarted, isPortalIntegration, email]);

  useEffect(() => {
    // sms may fail if region is unsupported
    // in that case user is prompted back to email quickReply
    if (smsAuthenticationFailed) {
      // handleSmsAuthFailure handles SMS_AUTHENTICATION_FAILURE quickReply
      addMessage(quickReplyMessages()[quickReplyIds.SMS_AUTHENTICATION_FAILURE]);
    }
  }, [smsAuthenticationFailed]);

  return {
    handleOldUserMessage,
    handleVerificationMethod,
    handleSilentAuthOldUser,
    handleSmsAuthFailure,
    handleAuthFlowRestart,
    handleCodeReenter,
  };
};
