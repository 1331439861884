import React, { useEffect, useState } from 'react';
import { View, Text } from 'react-native';
import { TouchableOpacity } from '../Common';
import { useDispatchMapper } from 'src/hooks/actionHooks';
import { getNextButton, styles } from './style';
import useProfile from 'src/hooks/useProfile';
import CompanyImage from 'src/components/CompanyImage';
import useTranslation from 'src/hooks/useTranslation';
import useTheme from 'src/hooks/useTheme';
import { updateProfileAction } from 'src/redux/profile/profileActions';
import Spinner from '../Common/Spinner';
import { chatgenieAPI } from 'src/lib/api/chatgenieApi';
import { getCompanyDesign } from 'src/utils/normalizer';
import { NewThreadLogo } from 'src/assets/icons';
import errorAstronaut from 'src/assets/images/error-astronaut.png';

const ErrorPage = ({ callback }) => {
  const appId = localStorage.getItem('chatgenie.appId') || null;
  const { companyInfo } = useProfile();
  const { theme } = useTheme();
  const { translate } = useTranslation();
  const updateProfile = useDispatchMapper(updateProfileAction);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (!appId) return;

    setIsLoading(true);
    chatgenieAPI
      .getCompanyBasicInfo(appId)
      .then(({ data: { data } }) => {
        const normalized = {
          companyInfo: {
            ...companyInfo,
            ...getCompanyDesign(data),
            appId,
          },
          loggedIn: true,
        };
        updateProfile(normalized);
        setIsLoading(false);
      })
      .catch((err) => {
        console.error(err);
        setIsLoading(false);
      });
  }, []);

  if (isLoading) {
    return (
      <div className="support-bot-spinner-wrapper">
        <Spinner />
      </div>
    );
  }

  return (
    <View style={styles.container}>
      <View activeOpacity={1} style={styles.wrap}>
        <CompanyImage />
        <View style={styles.content}>
          <img src={errorAstronaut} alt="Messenger Error" style={styles.errorImage} />
          <Text style={styles.title}>{translate('errorPage.title')}</Text>
          <Text style={styles.commonTxt}>{translate('errorPage.desc')}</Text>
        </View>
        <View style={styles.content}>
          {callback && (
            <TouchableOpacity style={getNextButton({ theme })} onPress={() => callback()}>
              <Text style={styles.commonTxt}>{translate('errorPage.button.text')}</Text>
            </TouchableOpacity>
          )}
        </View>
      </View>
      <View style={styles.footer}>
        <Text style={styles.poweredBy}>Powered by</Text>
        <NewThreadLogo />
      </View>
    </View>
  );
};

export default ErrorPage;
