import { responsiveHeight } from 'src/utils/responsive';

export const styles = {
  onboardingWrap: {
    flexGrow: 1,
    backgroundColor: '#fff',
  },
  onboardingMediaArea: {
    padding: 30,
    paddingTop: 15,
    paddingBottom: 0,
    backgroundColor: '#EFEEEA',
    height: '60%',
  },
  btnSkip: {
    position: 'absolute',
    top: 20,
    right: 20,
    color: '#999',
  },
  commonTxt: {
    color: 'inherit',
  },
  companyLogoWrap: {
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: 30,
  },
  image: {
    resizeMode: 'contain',
    width: '100%',
    height: '100%',
  },
  onboardingStepImg: {
    margin: '0 auto',
  },
  onboardingTextArea: {
    flexGrow: 1,
    flexDirection: 'column',
    alignItems: 'center',
    paddingHorizontal: 30,
    paddingVertical: 30,
  },
  btnNextStep: {
    fontSize: 14,
    backgroundColor: '#EB5757',
    color: '#fff',
    textAlign: 'center',
    padding: 14,
    width: '80%',
    borderRadius: 4,
  },
  onboardingTextTitle: {
    fontWeight: 600,
    fontSize: 20,
    marginBottom: 10,
    textAlign: 'center',
  },
  onboardingTextDesc: {
    textAlign: 'center',
    marginBottom: 20,
  },
  stepsWrap: {
    display: 'flex',
    flexDirection: 'row',
    marginBottom: 20,
  },
  step: {
    width: 8,
    height: 8,
    borderRadius: '50%',
    backgroundColor: '#D9D9D9',
    marginRight: 7,
  },
};

export const getNextButton = ({ theme }) => [
  styles.btnNextStep,
  {
    backgroundColor: theme.primaryColor,
    color: theme.foreground,
  },
];

export const getStep = ({ theme, isActive }) => [
  styles.step,
  {
    backgroundColor: isActive ? theme.primaryColor : '#D9D9D9',
  },
];
