import { StyleSheet } from 'react-native';

const styles = StyleSheet.create({
  list: {
    margin: 0,
    padding: 0,
  },
  listContainer: {},
  listItem: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingHorizontal: 15,
    height: 77,
    borderBottomWidth: 1,
  },
  empty: { marginTop: 16 },
  emptyWrapper: { backgroundColor: 'transparent', height: 50, paddingHorizontal: 15 },
  iconWrapper: {
    marginRight: 10,
    width: 40,
    height: 40,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'transparent',
  },
  metaInfo: {
    justifyContent: 'center',
    backgroundColor: 'transparent',
    flexShrink: 1,
  },
});

export default styles;
