import actionTypes from 'src/constants/actionTypes';

const defaultState = {
  error: null,
  loading: false,
  success: false,
  chatType: null,
};

const integrationReducer = (state = defaultState, action) => {
  switch (action.type) {
    case actionTypes.UPDATE_INTEGRATION:
      return { ...state, ...action.payload };
    default:
      return state;
  }
};

export default integrationReducer;
