/**
 * This is a common utility push-notifications file for Widget and Desktop
 */
import { UITranslations } from 'src/translations';
import SoundNewMessage from '../../assets/sounds/sound-new-message.mp3';
import SoundNewThread from '../../assets/sounds/sound-new-thread.mp3';
import { hasMentionsTag, replaceMentionTags } from 'src/utils/mention.utils';

const newMessageAudio = new Audio(SoundNewMessage);
const newThreadAudio = new Audio(SoundNewThread);

export const playMessageNotificationAudio = () => {
  // Do not overlap sounds if there are multiple concurrent notifications
  if (newMessageAudio.paused) {
    // DOMException: play() failed because the user didn't interact with the document first. https://goo.gl/xX8pDD
    // eslint-disable-next-line no-console
    newMessageAudio.play().catch(console.warn);
  }
};

export const playTicketNotificationAudio = () => {
  // Do not overlap sounds if there are multiple concurrent notifications
  if (newThreadAudio.paused) {
    // DOMException: play() failed because the user didn't interact with the document first. https://goo.gl/xX8pDD
    // eslint-disable-next-line no-console
    newThreadAudio.play().catch(console.warn);
  }
};

/**
 *
 * @param {string} systemId
 * @param {object} ticket
 * @param {string} locale - en | fr
 * @returns {string} - New Ticket #${systemId} - ${contactName} from ${companyName}: ${summary}
 */
export const getThreadNotificationTxt = ({ systemId, ticket, locale = 'en' }) => {
  const translations = UITranslations[locale];
  const txtUnknown = translations['common.text.unknown'];
  const txtNewTicket = translations['common.text.newTicket'];
  const txtFrom = translations['common.text.from'];
  const contactName = ticket?.contact?.name || txtUnknown;
  const companyName = ticket?.contact?.company_name;
  const summary = ticket?.summary;

  return `${txtNewTicket} #${systemId} - ${contactName} ${txtFrom} ${companyName}: ${summary}`;
};

/**
 *
 * @param {string} author
 * @param {string} messageBody
 * @param {string} locale
 * @returns {string}
 */
export const getMessageNotificationTxt = ({ author, messageBody, locale = 'en' }) => {
  const txtAttachment = UITranslations[locale]['common.text.attachment'];
  let message = messageBody || txtAttachment;
  if (hasMentionsTag(message)) {
    message = replaceMentionTags(message);
  }
  return `${author || 'Unknown User'}: ${message}`;
};

/**
 * This function is used by our desktop electron app
 * to show an app badge indicator with unread messages count
 * @param unreadCount - number of unread messages
 */
export const updateDesktopNotificationCount = (unreadCount) => {
  window?.ipc?.invoke('notification-badge-count', unreadCount);
};
