import React, { useState, memo } from 'react';
import PropTypes from 'prop-types';
import { Platform, KeyboardAvoidingView } from 'react-native';
import { isIphoneX } from 'react-native-iphone-x-helper';
import useTheme from 'src/hooks/useTheme';
import { useTwilioClient } from '@Thread-Magic/chat-utils';
import { Alerts } from 'src/components/ConnectionIndicator';
import Textarea from './Textarea';
import useTranslation from 'src/hooks/useTranslation';

const MessageInput = ({
  disabled,
  onSubmit,
  scrollBottom,
  placeholderText,
  inputConfig,
  attachments,
  onChangeAttachments,
  isAuthChat,
  onInputValueChange,
}) => {
  const { colors: themeColors } = useTheme();
  const [message, setMessage] = useState('');
  const [fileSizeExceeded, setFileSizeExceeded] = useState(false);
  const { status } = useTwilioClient();
  const { translate } = useTranslation();

  const onComment = async () => {
    setMessage('');
    onSubmit(message.trim());
  };
  const handleShowWarning = () => {
    setFileSizeExceeded(true);
    setTimeout(() => {
      setFileSizeExceeded(false);
    }, 2000);
  };

  const isDisabled = disabled || status === 'denied';
  const isSendBtnDisabled = !(message.length || attachments.length);

  const handleChangeText = (text) => {
    setMessage(text);
    onInputValueChange(text);
  };

  const inputProps = {
    autoFocus: true,
    value: message,
    onChangeText: handleChangeText,
    blurOnSubmit: false,
    editable: !isDisabled,
    onSubmitEditing: onComment,
    onFocus: () => setTimeout(scrollBottom, 300),
    returnKeyType: 'send',
    testID: 'message-input',
    enablesReturnKeyAutomatically: true,
    autoCorrect: false,
    placeholder: placeholderText || translate('ticket.liveChat.messagePlaceholder'),
    ...inputConfig,
  };
  const canSendAttachment = !isAuthChat || inputConfig?.isTicketCreated;

  return (
    <>
      {fileSizeExceeded && <Alerts title="File size exceeds maximum limit (150MB)" />}
      <KeyboardAvoidingView
        behavior={Platform.OS === 'ios' ? 'padding' : null}
        keyboardVerticalOffset={isIphoneX() ? 65 : 75}
        style={{ backgroundColor: themeColors.foreground }}
      >
        <Textarea
          inputProps={inputProps}
          canSendAttachment={canSendAttachment}
          onChangeAttachments={onChangeAttachments}
          attachments={attachments}
          isDisabled={isDisabled}
          handleShowWarning={handleShowWarning}
          isSendBtnDisabled={isSendBtnDisabled}
          onComment={onComment}
          isAuthChat={isAuthChat}
        />
      </KeyboardAvoidingView>
    </>
  );
};

MessageInput.defaultProps = {
  disabled: false,
  onSubmit: () => {},
  scrollBottom: () => {},
  placeholderText: null,
  inputConfig: null,
  onInputValueChange: () => {},
};

MessageInput.propTypes = {
  disabled: PropTypes.bool,
  onSubmit: PropTypes.func,
  scrollBottom: PropTypes.func,
  placeholderText: PropTypes.string,
  inputConfig: PropTypes.object,
  onInputValueChange: PropTypes.func,
};

export default memo(MessageInput);
