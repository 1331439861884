import React, { memo, useMemo } from 'react';
import PropTypes from 'prop-types';
import { colors } from 'src/constants/theme';
import Common, { Text, Ionicons } from 'src/components/Common';
import { getDeviceIcon } from 'src/utils';
import useTheme from 'src/hooks/useTheme';
import styles from './style';
import useTranslation from 'src/hooks/useTranslation';

const DevicesListItem = ({ item, onPress }) => {
  const { isDarkMode } = useTheme();
  const iconColor = isDarkMode ? colors.grey500 : colors.darkblue;
  const borderColor = isDarkMode ? colors.grey800 : colors.grey5;
  const { translate } = useTranslation();

  const textUnknown = translate('common.text.unknown');

  return (
    <Common.TouchableOpacity
      style={[styles.listItem, { borderBottomColor: borderColor }]}
      activeOpacity={0.6}
      onPress={onPress}
    >
      <Common.Row flexShrink={1}>
        <Common.View style={styles.iconWrapper} color={colors.white}>
          {getDeviceIcon(item?.type?.name, iconColor)}
        </Common.View>
        <Common.View style={styles.metaInfo}>
          <Text bold size={19}>
            {item?.name ?? textUnknown}
          </Text>
          <Text>{item?.type?.name ?? textUnknown}</Text>
        </Common.View>
      </Common.Row>
      <Ionicons name="ios-arrow-forward" size={25} color={colors.grey3} />
    </Common.TouchableOpacity>
  );
};

DevicesListItem.propTypes = {
  item: PropTypes.object.isRequired,
  onPress: PropTypes.func.isRequired,
};

export default memo(DevicesListItem);
