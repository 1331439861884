import { colors } from 'src/constants/theme';

const styles = {
  wrapper: {
    position: 'relative',
    flexGrow: 1,
    backgroundColor: 'transparent',
  },
  titleWrapper: {
    marginTop: 40,
    backgroundColor: 'transparent',
  },
  text: {
    color: colors.grey8,
    fontSize: 16,
    textAlign: 'center',
    lineHeight: 24,
  },
  messageText: {
    fontSize: 16,
    lineHeight: 19,
  },
  messagesWrapper: {
    position: 'absolute',
    bottom: 0,
    width: '100%',
    backgroundColor: 'transparent',
  },
  messageBubble: {
    backgroundColor: colors.lightForeground,
    borderWidth: 1,
    borderColor: colors.grey20,
    borderRadius: 6,
    alignItems: 'center',
    justifyContent: 'center',
    paddingHorizontal: 10,
    maxWidth: 240,
    height: 62,
  },
  bubbleWrapper: {
    position: 'absolute',
    backgroundColor: 'transparent',
  },
  rightMessage: {
    right: 12,
    bottom: 110,
  },
  leftMessage: {
    left: 12,
    bottom: 40,
  },
  longArrow: {
    position: 'absolute',
    top: 70,
    right: 50,
    backgroundColor: 'transparent',
  },
  shortArrow: {
    position: 'absolute',
    top: 70,
    left: 100,
    backgroundColor: 'transparent',
  },
  centeredMessage: {
    position: 'static',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: 10

  },
  centeredArrow: {
    position: 'static',
    marginTop: 4
  }
};

export default styles;
