import React from 'react';
import { Row, Text } from './Common';

const ListEmptyMessage = ({ message, messages }) => (
  <Row flexDirection="column" justifyContent="center" alignItems="center" style={{ minHeight: 100 }}>
    <Text size={18} style={{ paddingBottom: 8 }}>
      {message}
    </Text>
    {messages.map((msg) => (
      <Text key={msg} size={14} style={{ paddingBottom: 4 }}>
        {msg}
      </Text>
    ))}
  </Row>
);

ListEmptyMessage.defaultProps = {
  message: 'No data',
  messages: [],
};

export default ListEmptyMessage;
