import React, { useEffect, useRef } from 'react';
import useProfile from '../../../../../../shared/src/hooks/useProfile';
import useIntegrationActions from '../../../../../../shared/src/hooks/useIntegrationActions';
import { IntegrationSuccessIcon } from '../../../../../../shared/src/assets/icons';
import Content from '../Content';
import Config from 'react-native-config';
import { clearSavedVariables } from '../..';

const SlackStepContent = () => (
  <>
    <li className="step">
      <div className="step-index">1</div>
      <div class="step-wrapper">
        <p className="step-label">{`Go to your Slack workspace and find the App (it may take few moments or you can simply search for it)`}</p>
      </div>
    </li>
    <li className="step">
      <div className="step-index">2</div>
      <div class="step-wrapper">
        <p className="step-label">{`Once in the App, under Messages tab type "help" to start (Home tab may take few moments to appear)`}</p>
      </div>
    </li>
  </>
);
const MSTeamsStepContent = ({ appId, teamsAppName, teamsAppId }) => {
  const downloadLink = `${Config.CHATGENIE_API_URL}/workspaces/${appId}/ms-teams-apps/download`;

  return (
    <>
      <li className="step">
        <span className="step-index">1</span>
        <div class="step-wrapper">
          <p className="step-label">Download {teamsAppName} Microsoft Teams App</p>
          <div className="step-description">
            <p>
              To download your custom app, click button below or go to the{' '}
              <a href="https://admin.getthread.com/" target="_blank" download={teamsAppName}>
                Admin section.
              </a>
            </p>
            <a href={downloadLink} className="step-button">
              <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M19.6002 14.8061V19.0198C19.6002 19.1037 19.5882 19.1817 19.5703 19.2536C19.4863 19.6671 19.1208 19.9788 18.6893 19.9788L1.46951 19.9787C1.00795 19.9787 0.624486 19.625 0.576494 19.1754C0.564453 19.1214 0.564453 19.0736 0.564453 19.0136V14.7999C0.564453 14.3024 0.972001 13.9009 1.4635 13.9009C1.70917 13.9009 1.93696 14.0027 2.09883 14.1646C2.2607 14.3265 2.36256 14.5543 2.36256 14.7999V18.1744H17.8082V14.7999C17.8082 14.3024 18.2158 13.9009 18.7073 13.9009C18.953 13.9009 19.1807 14.0027 19.3426 14.1646C19.4985 14.3327 19.6003 14.5605 19.6003 14.8061L19.6002 14.8061Z"
                  fill="#051275"
                />
                <path
                  d="M15.2662 10.5865L10.8489 15.0039C10.8428 15.0159 10.8308 15.0219 10.8249 15.0278C10.6631 15.1896 10.4534 15.2915 10.2436 15.3214C10.2256 15.3214 10.2077 15.3275 10.1896 15.3275C10.1537 15.3335 10.1177 15.3335 10.0818 15.3335L9.97992 15.3275C9.96186 15.3275 9.94396 15.3214 9.9259 15.3214C9.71017 15.2915 9.50631 15.1897 9.3446 15.0278C9.33858 15.0217 9.32654 15.0097 9.32069 15.0039L4.90336 10.5865C4.6995 10.3827 4.59766 10.1131 4.59766 9.84335C4.59766 9.5736 4.6995 9.30383 4.90336 9.10018C5.31091 8.69263 5.97618 8.69263 6.38974 9.10018L9.04494 11.7554L9.04477 2.02765C9.04477 1.45222 9.51823 0.97876 10.0937 0.97876C10.3813 0.97876 10.645 1.09867 10.8368 1.28446C11.0287 1.47628 11.1425 1.73399 11.1425 2.02763V11.7554L13.7977 9.10016C14.2053 8.69261 14.8706 8.69261 15.2841 9.10016C15.6738 9.51373 15.6738 10.179 15.2662 10.5865L15.2662 10.5865Z"
                  fill="#051275"
                />
              </svg>
              Download App
            </a>
          </div>
        </div>
      </li>
      <li className="step">
        <span className="step-index">2</span>
        <div class="step-wrapper">
          <p className="step-label">Upload the App in Microsoft Teams</p>
          <p className="step-description">
            Go to the Teams Admin center and set up the app.{' '}
            <a target="_blank" href="https://docs.getthread.com/article/ry9zssic5c-how-to-install-the-teams-bot-on-your-customers-tenant#upload_the_app_to_microsoft_teams">
              See how here
            </a>
          </p>
        </div>
      </li>
      <li className="step">
        <span className="step-index">3</span>
        <div class="step-wrapper">
          <p className="step-label">Deploy the App to all of the users with App Policy</p>
          <p className="step-description">
            In the Teams Admin center, there is an option to pin the app.{' '}
            <a target="_blank" href="https://docs.getthread.com/article/ry9zssic5c-how-to-install-the-teams-bot-on-your-customers-tenant#deploy_the_app_to_all_of_the_users_with_app_policy">
              See how here
            </a>
          </p>
        </div>
      </li>
      <li className="step">
        <span className="step-index">4</span>
        <div class="step-wrapper">
          <p className="step-label">The users should see the app within 2 - 24 hours</p>
          <p className="step-description">
            You can now close this window and give yourself a pat on the back. Nice work!
          </p>
        </div>
      </li>
    </>
  );
};

const stepsContent = {
  slack: SlackStepContent,
  msTeams: MSTeamsStepContent,
};

const SuccessContent = () => {
  const { companyInfo } = useProfile();
  const teamsAppId = useRef(localStorage.getItem('teamsAppId'));
  const teamsAppName = useRef(localStorage.getItem('teamsAppName'));
  const {
    state: { chatType },
  } = useIntegrationActions();
  const StepContent = stepsContent[chatType];

  useEffect(() => {
    // removing locally stored variables which may affect next onboardings
    clearSavedVariables();
  }, []);

  return (
    <Content
      header={
        <>
          <img
            className="logo"
            src={companyInfo?.image || require('src/assets/images/new-logo.png')}
            alt="company logo"
          />
          <div className="message">
            <p className="message-title">Great Success!</p>
            <p className="message-description">
              {chatType === 'slack'
                ? 'We’re setting up your brand new account. This can take a few minutes, so kick back and relax.'
                : `You’ve linked the ${chatType === 'msTeams' ? 'Microsoft' : 'Slack'} account with the ${
                    companyInfo?.name || 'Thread'
                  }. You’re almost there!`}
            </p>
          </div>
        </>
      }
      mainContent={
        <>
          <p className="steps-label">What’s next?</p>
          <ul className="steps">
            {StepContent && (
              <StepContent
                teamsAppName={teamsAppName.current}
                teamsAppId={teamsAppId.current}
                appId={companyInfo?.appId}
              />
            )}
          </ul>
        </>
      }
      sideContent={<IntegrationSuccessIcon width="463px" height="378px" className="success-illustration" />}
    />
  );
};

export default React.memo(SuccessContent);
