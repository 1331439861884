import { StyleSheet } from 'react-native';
import { colors, fonts } from 'src/constants/theme';

const styles = StyleSheet.create({
  toggleContainer: {
    marginRight: 20,
    display: 'flex',
    flexDirection: 'row',
  },
  toggleButton: {
    minWidth: 63,
    height: 29,
    fontSize: 14,
    fontWeight: '500',
    color: colors.darkblue2,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    paddingHorizontal: 10,
    paddingVertical: 4,
    borderRadius: 4.64,
    fontFamily: fonts.semiBold,
  },
  activeButton: {
    backgroundColor: colors.lightBlue,
  },
});
export default styles;
