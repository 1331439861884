import React, { useState, memo, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import Common from 'src/components/Common';
import { StatusBar } from 'react-native';
import useTheme from 'src/hooks/useTheme';
import { IS_ANDROID } from 'src/utils';
import { responsiveWidth } from 'src/utils/responsive';
import { useSelector } from 'react-redux';
import { setOpenAlertAction } from 'src/redux/alerts/alertsActions';
import { useDispatchMapper } from 'src/hooks/actionHooks';

import ListEmptyMessage from 'src/components/ListEmptyMessage';
import styles from './styles';
import AlertCard from '../AlertCard';
import AlertModal from '../AlertModal';
import AlertListArrows from '../AlertListArrows';
import useTranslation from 'src/hooks/useTranslation';

const defaultModalState = {
  visible: false,
  alert: null,
};

const Alerts = ({ alerts, alertLoading }) => {
  const [modalState, setModalState] = useState(defaultModalState);
  const { theme, colors: themeColors } = useTheme();
  const unreadAlerts = useSelector((store) => store?.alerts?.unreadAlerts);
  const openAlert = useSelector((store) => store?.alerts?.openAlert);
  const setOpenAlert = useDispatchMapper(setOpenAlertAction);
  const { translate } = useTranslation();

  const onExpandAlert = useCallback((alert) => {
    setOpenAlert(alert);
  }, []);

  const onCloseAlertModal = useCallback(() => {
    setOpenAlert(null);
  }, []);

  useEffect(() => {
    if (IS_ANDROID) {
      if (modalState.visible) {
        StatusBar.setBackgroundColor('rgba(0,0,0,0.7)', true);
      } else {
        StatusBar.setBackgroundColor(theme.primaryColor, true);
      }
    }
  }, [modalState]);

  useEffect(() => {
    if (openAlert && !modalState.visible) {
      setModalState({
        visible: true,
        alert: openAlert,
      });
    } else if (!openAlert && modalState.visible) {
      setModalState(defaultModalState);
    }
  }, [openAlert, modalState]);

  const noAlertText = translate('common.text.noAlert');

  return (
    <>
      <Common.View
        style={[
          styles.wrapper,
          {
            backgroundColor: themeColors.background,
          },
        ]}
      >
        <Common.Text style={styles.sectionTitle} size={21} bold color={themeColors.text}>
          Alerts
        </Common.Text>
        {alertLoading && !alerts && <Common.Spinner />}
        {!alerts?.length && !alertLoading ? <ListEmptyMessage message={noAlertText} /> : null}
        {alerts?.length ? (
          <AlertListArrows showRightArrow={alerts?.length >= 2}>
            {({ onScroll, listRef }) => (
              <Common.ScrollView
                horizontal
                ref={listRef}
                onScroll={onScroll}
                style={styles.scrollview}
                decelerationRate={0}
                snapToInterval={responsiveWidth(70)}
                snapToAlignment="start"
                showsHorizontalScrollIndicator={false}
                contentContainerStyle={[styles.scrollviewContainer, { minWidth: responsiveWidth(70) * alerts.length }]}
                testID="alerts-list"
              >
                {alerts?.map((item, index) => (
                  <AlertCard
                    isLast={alerts?.length - 1 === index}
                    item={item}
                    key={item?.id}
                    isUnread={!!unreadAlerts?.[item?.id]}
                    index={index}
                    onOpen={() => onExpandAlert(item)}
                  />
                ))}
              </Common.ScrollView>
            )}
          </AlertListArrows>
        ) : null}
      </Common.View>
      <AlertModal alert={modalState.alert} visible={modalState.visible} onClose={onCloseAlertModal} />
    </>
  );
};

Alerts.propTypes = {
  alertLoading: PropTypes.bool,
  alerts: PropTypes.any,
};

Alerts.defaultProps = {
  alertLoading: false,
  alerts: null,
};

export default memo(Alerts);
