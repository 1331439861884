import React from 'react';
import { SafeAreaProvider } from 'react-native-safe-area-context';
import { Provider } from 'react-redux';
import { ReactQueryConfigProvider } from 'react-query';
import { ThemeProvider } from 'styled-components/native';
import PostHogProvider from './utils/analytics/postHog';
import AppNavigator from './navigation/AppNavigator';
import { store } from './redux/store';
import useTheme from './hooks/useTheme';
import reactQueryConfig from './config/reactQuery';
import { useInitializers } from './hooks/useInitializers';
import { Auth0Provider } from './utils/auth0';
import ConfirmModal from './components/ConfirmModal';
import PhotoViewerModal from './components/PhotoViewerModal';
import DesktopOnboardingModal from 'src/components/DesktopOnboardingModal';
import SignInModal from './components/SignInModal';

const App = () => {
  const { theme, colors: themeColors, isDarkMode } = useTheme();
  useInitializers();

  return (
    <Auth0Provider>
      <SafeAreaProvider>
        <ThemeProvider theme={{ ...theme, ...themeColors, isDark: isDarkMode }}>
          <ReactQueryConfigProvider config={reactQueryConfig}>
            <PostHogProvider>
              <AppNavigator />
              <ConfirmModal />
              <PhotoViewerModal />
              <DesktopOnboardingModal />
              <SignInModal />
            </PostHogProvider>
          </ReactQueryConfigProvider>
        </ThemeProvider>
      </SafeAreaProvider>
    </Auth0Provider>
  );
};
export const ReduxProvider = ({ children }) => <Provider store={store}>{children}</Provider>;

export default () => (
  // <ErrorBoundary>
  <ReduxProvider>
    <App />
  </ReduxProvider>
  // </ErrorBoundary>
);
