export const styles = {
  wrap: {
    flexGrow: 1,
    backgroundColor: '#fff',
  },
  mediaArea: {
    padding: 30,
    paddingTop: 15,
    paddingBottom: 0,
    backgroundColor: '#EFEEEA',
    height: '60%',
    alignItems: 'center',
  },
  btnSkip: {
    position: 'absolute',
    top: 20,
    right: 20,
    color: '#999',
  },
  commonTxt: {
    color: 'inherit',
  },
  companyLogoWrap: {
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: 30,
  },
  image: {
    resizeMode: 'contain',
    width: '100%',
    height: '100%',
  },
  lockerIconWrap: {
    alignItems: 'center',
    background: '#E0DED8',
    borderRadius: 100,
    width: 170,
    padding: 30,
    marginTop: 50,
  },
  textArea: {
    flexGrow: 1,
    flexDirection: 'column',
    alignItems: 'center',
    paddingHorizontal: 30,
    paddingVertical: 30,
  },
  buttonContainer: {
    display: 'flex',
    gap: 10,
    fontSize: 16,
    alignItems: 'center',
    fontWeight: 400,
  },
  btnSignInMicrosoft: {
    color: '#868686',
    border: '2px solid #bababa',
    paddingTop: 10,
    paddingBottom: 10,
    paddingLeft: 30,
    paddingRight: 30,
    marginTop: 20,
  },
  microsoftIcon: {
    width: 25,
    height: 25,
  },
  textTitle: {
    fontWeight: 600,
    fontSize: 20,
    marginBottom: 10,
    textAlign: 'center',
  },
  textDesc: {
    textAlign: 'center',
    marginBottom: 20,
  },
  loginEmail: {
    color: '#EB5757',
    fontWeight: 600,
  },
};
