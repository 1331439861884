import React, { memo } from 'react';
import styled, { css } from 'styled-components/native';
import { colors } from 'src/constants/theme';
import { Text, Ionicons, Row } from 'src/components/Common';
import useTheme from 'src/hooks/useTheme';
import { isWeb } from 'src/utils';
import useTranslation from 'src/hooks/useTranslation';

const UserListItem = ({ item, isLast, onPress, disabled, plain }) => {
  const { isDarkMode } = useTheme();
  const boderBottom = isDarkMode ? colors.grey800 : colors.grey5;
  const { translate } = useTranslation();

  return (
    <ListItem
      hasBottomBorder={!isLast}
      activeOpacity={0.6}
      onPress={onPress}
      disabled={disabled}
      boderBottom={boderBottom}
    >
      <Row>
        <MetaInfo>
          <Text bold size={19}>
            {item?.fullname || translate('common.text.unknown')}
          </Text>
        </MetaInfo>
      </Row>
      {!disabled && <Ionicons name="ios-arrow-forward" size={20} color={colors.grey3} />}
    </ListItem>
  );
};

UserListItem.defaultProps = {
  isLast: false,
  plain: false,
  disabled: false,
};

export default memo(UserListItem);

const ListItem = styled.TouchableOpacity`
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 0px 20px;
  height: 90px;
  ${({ hasBottomBorder, boderBottom }) =>
    hasBottomBorder &&
    css`
      border-bottom-color: ${boderBottom};
      border-bottom-width: 1px;
    `}
  ${isWeb() &&
  css`
    height: 80px;
  `}
`;

const MetaInfo = styled.View`
  justify-content: center;
  background-color: transparent;
`;
