import { createCacheKey, InMemoryCache, reactiveVarClient } from '@Thread-Magic/thread-service-utils';
import { THREAD_STATE_FILTERS, THREAD_STATES } from 'src/constants';

export const getMyThreadsCacheKey = (variables) => {
  const orderedVariables = [
    ['stateIn', variables.stateIn],
    ['search', variables.search],
    ['sortByCreatedAt', variables.sortByCreatedAt],
    ['sortByUpdatedAt', variables.sortByUpdatedAt],
  ];
  const cacheKeyFromVariables = createCacheKey(orderedVariables);
  return `${cacheKeyFromVariables}`;
};

export const cache = new InMemoryCache({
  typePolicies: {
    Query: {
      fields: {
        myThreads: {
          keyArgs: ({ filter, search, order }) => {
            const customCacheKey = getMyThreadsCacheKey({
              stateIn: filter.state_in,
              sortByCreatedAt: order?.created_at,
              sortByUpdatedAt: order?.updated_at,
              search,
            });
            return `myThreads:${customCacheKey}`;
          },
          merge: (existing = [], incoming, args) => {
            const cursor = args.args.cursor;
            const isFirstPage = !cursor;

            const isAlreadyMergedData = Array.isArray(incoming);
            if (isAlreadyMergedData) {
              return incoming;
            }

            if (isFirstPage) {
              return [incoming];
            }

            const pageIndex = existing.length;
            const copyExisting = existing.concat([]);
            copyExisting[pageIndex] = incoming;
            return copyExisting;
          },
        },
      },
    },
  },
});

export const modifyMyThreadsCache = ({ threadToAdd, threadToRemove }) => {
  const apolloClient = reactiveVarClient();

  apolloClient.cache.modify({
    fields: {
      myThreads(cache, { storeFieldName, readField, toReference }) {
        let updatedCache = cache;
        if (threadToAdd) {
          const stateIn =
            threadToAdd.state === THREAD_STATES.DONE ? THREAD_STATE_FILTERS.DONE : THREAD_STATE_FILTERS.ACTIVE;
          const threadReference = toReference(threadToAdd);
          const cacheKey = getMyThreadsCacheKey({ stateIn });
          const doesCacheKeyMatch = storeFieldName.includes(cacheKey);
          if (doesCacheKeyMatch) {
            const [firstPage, ...restPages] = cache;
            const isAlreadyInCache = !!cache.some((page) =>
              page.items.some((el) => {
                const threadId = readField('id', el);
                return threadId === threadToAdd.id;
              }),
            );
            if (!isAlreadyInCache) {
              const extendedFirstPage = {
                ...firstPage,
                items: [threadReference, ...firstPage.items],
              };
              updatedCache = [extendedFirstPage, ...restPages];
            }
          }
        }

        if (threadToRemove) {
          const stateIn =
            threadToRemove.state === THREAD_STATES.DONE ? THREAD_STATE_FILTERS.DONE : THREAD_STATE_FILTERS.ACTIVE;
          const cacheKey = getMyThreadsCacheKey({ stateIn });
          const doesCacheKeyMatch = storeFieldName.includes(cacheKey);
          if (doesCacheKeyMatch) {
            updatedCache = updatedCache.map((page) => ({
              ...page,
              items: page.items.filter((el) => readField('id', el) !== threadToRemove.id),
            }));
          }
        }

        return updatedCache;
      },
    },
  });
};
