import { isEmpty } from 'lodash';
import { useSelector } from 'react-redux';
import { updateAuthAction } from 'src/redux/auth/actions';
import { chatgenieAPI } from 'src/lib/api/chatgenieApi';
import { userFlows } from 'src/constants';
import { useDispatchMapper } from './actionHooks';
import Authentication from 'src/utils/auth';

const isValidParams = (params) => params?.email && params?.companyName && params?.fullname;

export const useAuth = () => {
  const state = useSelector((store) => store.auth);
  const updateState = useDispatchMapper(updateAuthAction);

  const isEmailFound = async (email) => {
    if (!email) throw Error('Email is missing');

    try {
      const result = await chatgenieAPI.searchUser(email);
      return !!result.data?.data?.email;
    } catch (err) {
      console.log(err);
      return false;
    }
  };

  const loginWithParams = async (params) => {
    let updatedState = {};

    if (params.flow === 'customer') {
      updatedState.flow = userFlows.EXISTING_USER;
      updatedState.flowViaLink = true;
    }
    if (isValidParams(params)) {
      const isFound = await isEmailFound(params.email);
      updatedState = {
        flow: isFound ? userFlows.EXISTING_USER : userFlows.NEW_USER,
        email: params.email,
        fullname: params.fullname,
        companyName: params.companyName,
        isPortalIntegration: true,
      };
    }
    if (!isEmpty(updatedState)) {
      updateState(updatedState);
    }
  };

  const logout = (callback) => {
    Authentication.logout(callback);
  };

  return {
    logout,
    state,
    updateState,
    loginWithParams,
    isEmailFound,
  };
};
