import actionTypes from 'src/constants/actionTypes';
import { isElectron } from 'src/utils';

const defaultState = {
  permission: isElectron() ? Notification.permission : null,
  tickets: {},
  enabled: true,
};

const notificationReducer = (state = defaultState, action) => {
  switch (action.type) {
    case actionTypes.UPDATE_TICKETS_NOTIFICATION:
      return { ...state, tickets: action.payload };
    case actionTypes.SAVE_NOTIFICATION_PERMISSION:
      return { ...state, permission: action.payload };
    case actionTypes.UPDATE_NOTIFICATION_ENABLED:
      return { ...state, enabled: action.payload };
    default:
      return state;
  }
};

export default notificationReducer;
