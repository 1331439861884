import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import useTheme from 'src/hooks/useTheme';
import Common, { FontAwesome } from '../Common';
import styles from './style';
import useTranslation from 'src/hooks/useTranslation';

const checkIfConnected = (integration, platform) =>
  integration?.chatConfiguration === platform && !integration.reintegrate;
const getButtonStyle = (theme, isConnected) => [
  styles.integrationBtn,
  isConnected && { borderWidth: 1, borderColor: theme.primaryColor },
];

const IntegrationButtons = ({ integration, loading, connectTeams, connectSlack }) => {
  const { theme } = useTheme();
  const isSlackConnected = checkIfConnected(integration, 'SLACK');
  const isTeamsConnected = checkIfConnected(integration, 'MS_TEAMS');
  const isButtonsDisabled = loading || isTeamsConnected || isSlackConnected;
  const { translate } = useTranslation();

  const getButtonText = useCallback(
    (isConnected) => translate('account.integration.connected', isConnected),
    [isSlackConnected, isTeamsConnected],
  );

  return (
    <>
      <Common.TouchableOpacity
        disabled={isButtonsDisabled}
        testID="integration-button"
        style={getButtonStyle(theme, isSlackConnected)}
        onPress={connectSlack}
      >
        <Common.Row flexGrow={1} alignItems="center" justifyContent="center">
          <Common.Text bold>{getButtonText(isSlackConnected)}</Common.Text>
          <Common.Image source={require('src/assets/images/slack.png')} style={styles.integrationImage} />
          {isSlackConnected && (
            <Common.Row style={styles.checkIcon} testID="check-icon-slack">
              <FontAwesome name="check" style={{ color: theme.primaryColor }} />
            </Common.Row>
          )}
        </Common.Row>
      </Common.TouchableOpacity>
      <Common.TouchableOpacity
        disabled={isButtonsDisabled}
        testID="integration-button"
        style={getButtonStyle(theme, isTeamsConnected)}
        onPress={connectTeams}
      >
        <Common.Row flexGrow={1} alignItems="center" justifyContent="center">
          <Common.Text bold>
            {loading ? translate('common.state.connecting') : getButtonText(isTeamsConnected)}
          </Common.Text>
          <Common.Image style={styles.integrationImage} source={require('src/assets/images/msteams.png')} />
          {isTeamsConnected && (
            <Common.Row style={styles.checkIcon} testID="check-icon-teams">
              <FontAwesome name="check" style={{ color: theme.primaryColor }} />
            </Common.Row>
          )}
        </Common.Row>
      </Common.TouchableOpacity>
    </>
  );
};
IntegrationButtons.defaultProps = {
  loading: false,
};
IntegrationButtons.propTypes = {
  integration: PropTypes.any,
  loading: PropTypes.bool,
  connectTeams: PropTypes.func.isRequired,
  connectSlack: PropTypes.func.isRequired,
};

export default IntegrationButtons;
