import React from 'react';
import Common from 'src/components/Common';
import { getAttachmentBtnStyles } from '../style';
import { Pressable } from 'react-native';
import { colors } from 'src/constants/theme';
import Svg, { Path } from 'react-native-svg';
import Hoverable from 'src/components/Hoverable';

export default function AttachmentButton({ isDisabled, onPress, mentionsEnabled }) {
  const attachmentBtnStyles = getAttachmentBtnStyles({ paddingRight: mentionsEnabled ? 5 : 18 });

  return (
    <Hoverable>
      {(isHovered) => (
        <Pressable style={attachmentBtnStyles} onPress={onPress} disabled={isDisabled}>
          <Common.Text>
            <Svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
              <Path
                d="M19.6534 10.129L11.2293 18.5532C10.1972 19.5852 8.79752 20.165 7.33802 20.165C5.87852 20.165 4.47879 19.5852 3.44677 18.5532C2.41475 17.5212 1.83496 16.1214 1.83496 14.6619C1.83496 13.2024 2.41475 11.8027 3.44677 10.7707L11.8709 2.34651C12.559 1.6585 13.4921 1.27197 14.4651 1.27197C15.4381 1.27197 16.3713 1.6585 17.0593 2.34651C17.7473 3.03453 18.1338 3.96768 18.1338 4.94068C18.1338 5.91368 17.7473 6.84683 17.0593 7.53485L8.62594 15.959C8.28193 16.303 7.81535 16.4963 7.32885 16.4963C6.84235 16.4963 6.37578 16.303 6.03177 15.959C5.68776 15.615 5.4945 15.1484 5.4945 14.6619C5.4945 14.1754 5.68776 13.7089 6.03177 13.3648L13.8143 5.59151"
                stroke={isHovered && !isDisabled ? colors.darkblue2 : colors.grey8}
                strokeWidth="1.84269"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </Svg>
          </Common.Text>
        </Pressable>
      )}
    </Hoverable>
  );
}
