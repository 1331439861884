import React, { useCallback } from 'react';
import { UITranslations } from 'src/translations';
import useProfile from 'src/hooks/useProfile';

const useTranslation = () => {
  const { companyInfo } = useProfile();
  const locale = companyInfo?.lang || 'en';

  const translate = useCallback(
    (translationText, param = '') => {
      const translationValue = UITranslations[locale][translationText];

      if (!translationValue) return '';

      if (typeof translationValue === 'function') {
        return translationValue(param);
      }

      return translationValue;
    },
    [locale],
  );

  return {
    translate,
    locale,
  };
};

export default useTranslation;
