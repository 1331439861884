import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import createAuth0Client from '@auth0/auth0-spa-js';
import { auth0Config } from 'src/config';

export const getUrlParam = (param) => window.location.search
  .replace('?', '')
  .split('&')
  .reduce((accumulator, currentValue) => {
    const a = currentValue.split('=');
    accumulator[decodeURIComponent(a[0])] = decodeURIComponent(a[1]);
    return accumulator;
  }, {})[param];

const DEFAULT_REDIRECT_CALLBACK = () => window.history.replaceState({}, document.title, window.location.pathname);

export const Auth0Context = React.createContext();

export const useAuth0 = () => useContext(Auth0Context);

export const Auth0Provider = ({
  children,
  onRedirectCallback = DEFAULT_REDIRECT_CALLBACK,
}) => {
  const [isAuthenticated, setIsAuthenticated] = useState();
  const [user, setUser] = useState();
  const [auth0Client, setAuth0] = useState();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const config = {
    domain: auth0Config.domain,
    client_id: auth0Config.clientId,
    audience: auth0Config.audience,
    redirect_uri: window.location.origin,
    screen_mode: 'logIn',
  };

  useEffect(() => {
    const initAuth0 = async () => {
      try {
        if (getUrlParam('appId')) {
          config.redirect_uri = `${config.redirect_uri}/auth-link?appId=${getUrlParam('appId')}&parentAppId=${getUrlParam('parentAppId')}&type=${getUrlParam('type')}`;
        }
        const auth0FromHook = await createAuth0Client(config);

        setAuth0(auth0FromHook);

        if (window.location.search.includes('code=')) {
          const { appState } = await auth0FromHook.handleRedirectCallback();
          onRedirectCallback(appState);
        }

        const authenticated = await auth0FromHook.isAuthenticated();

        setIsAuthenticated(authenticated);

        if (authenticated) {
          const userData = await auth0FromHook.getUser();
          setUser(userData);
        }
      } catch (err) {
        setError(err);
      } finally {
        setLoading(false);
      }
    };
    initAuth0();
    // eslint-disable-next-line
  }, []);

  const handleRedirectCallback = async () => {
    setLoading(true);
    await auth0Client.handleRedirectCallback();
    const userData = await auth0Client.getUser();
    setLoading(false);
    setIsAuthenticated(true);
    setUser(userData);
  };
  return (
    <Auth0Context.Provider
      value={{
        isAuthenticated,
        user,
        error,
        isLoading: loading,
        handleRedirectCallback,
        getIdTokenClaims: (...p) => auth0Client?.getIdTokenClaims(...p),
        loginWithRedirect: (...p) => auth0Client?.loginWithRedirect(...p),
        getTokenSilently: (...p) => auth0Client?.getTokenSilently(...p),
        logout: (...p) => auth0Client?.logout(...p),
      }}
    >
      {children}
    </Auth0Context.Provider>
  );
};

Auth0Provider.propTypes = {
  children: PropTypes.node,
  onRedirectCallback: PropTypes.func,
};

Auth0Provider.defaultProps = {
  children: '',
  onRedirectCallback: () => {},
};
