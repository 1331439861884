const reactQueryConfig = {
  suspense: false,
  useErrorBoundary: undefined,
  throwOnError: true,
  retry: false,
  refetchOnWindowFocus: false,
  queryFnParamsFilter: (args) => args.slice(1),
};

export default reactQueryConfig;
