import { store } from 'src/redux/store';

export const FEATURE_FLAGS = {
  // feature flags from Posthog: https://us.posthog.com/project/31914/feature_flags
  PROFILE_PICTURES: 'profile_pictures',
};

export const isFeatureFlagEnabled = (flag = '') => {
  const { profile } = store.getState();
  const { userInfo } = profile || {};

  const hasFeatureFlags = Array.isArray(userInfo?.featureFlags);
  if (!hasFeatureFlags) return false;

  return userInfo.featureFlags.includes(flag);
};
