import { StyleSheet } from 'react-native';
import { colors } from 'src/constants/theme';

const styles = StyleSheet.create({
  bottomMenu: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-around',
    height: 77,
    backgroundColor: colors.white,
    shadowColor: '#000',
    shadowOffset: {
      height: 0,
      width: 0,
    },
    shadowRadius: 5,
    shadowOpacity: 0.1,
  },
  actionBtn: {
    height: '100%',
    width: '50%',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    flex: 1,
  },
  accountBtn: {
    marginLeft: 15,
  },
  actionBtnBorder: {
    borderRightColor: '#DDDDDD',
    borderRightWidth: 1,
  },
  btnLabel: {
    marginLeft: 8,
    marginTop: 3,
  },
});

export default styles;
