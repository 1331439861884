import { createStore, compose, applyMiddleware } from 'redux';
import { persistStore, persistReducer, purgeStoredState } from 'redux-persist';
import thunk from 'redux-thunk';
import promise from 'redux-promise-middleware';
import AsyncStorage from '@react-native-community/async-storage';
import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2';
import parse from 'url-parse';
import { isElectron, isWeb } from 'src/utils';
import rootReducer from './rootReducer';
import { migratePeristedState } from './migrations';

const persistConfig = {
  key: 'cgm',
  storage: AsyncStorage,
  timeout: null,
  version: 1,
  whitelist: ['profile', 'notifications'],
  stateReconciler: autoMergeLevel2,
  migrate: (state) => migratePeristedState(state),
};

if (isWeb() && !isElectron()) {
  if (window?.location?.href) {
    const parsedUrl = parse(window?.location?.href, true);
    let appId;
    if (parsedUrl?.query?.appId) {
      appId = parsedUrl?.query?.appId;
    } else if (parsedUrl?.query?.state?.includes('appId')) {
      appId = JSON.parse(parsedUrl?.query?.state)?.appId;
    }
    if (appId) {
      persistConfig.key = `${appId}_chatgenie`;
    }
  }
}

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const resetReduxPersist = () => purgeStoredState(persistConfig);

// eslint-disable-next-line import/no-mutable-exports
export let reduxPersistor = null;

// eslint-disable-next-line no-undef
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const enhancers = [];
const middlewares = [thunk, promise];
const middlewareEnhancer = applyMiddleware(...middlewares);

enhancers.push(middlewareEnhancer);
export const createReduxStore = (initialState) => createStore(persistedReducer, initialState, composeEnhancers(...enhancers));
export const store = createReduxStore();

const persistor = persistStore(store);
reduxPersistor = persistor;
