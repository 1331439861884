const styles = {
  profilePicture: {
    width: 30,
    height: 30,
    borderRadius: 50,
    objectFit: 'cover',
  },
  avatarStyles: {
    borderRadius: 50,
  },
};

export const getUserPictureStyles = (size) => ({
  ...styles.profilePicture,
  width: size,
  height: size,
});

export const getAvatarStyles = () => ({
  ...styles.avatarStyles,
});

export default styles;
