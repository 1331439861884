import { combineReducers } from 'redux';
import actionTypes from 'src/constants/actionTypes';
import chatReducer from './chat/chatReducer';
import mfaReducer from './mfa/mfaReducer';
import profileReducer from './profile/profileReducer';
import popupReducer from './popup/popupReducer';
import alertsReducer from './alerts/alertsReducer';
import integrationReducer from './integration/reducer';
import authReducer from './auth/reducer';
import notificationReducer from './notifications/reducer';
import { defaultState as authDefaultState } from './auth/reducer';
import { isElectron } from 'src/utils';

export const appReducer = combineReducers({
  auth: authReducer,
  chat: chatReducer,
  mfa: mfaReducer,
  profile: profileReducer,
  popup: popupReducer,
  alerts: alertsReducer,
  integration: integrationReducer,
  notifications: notificationReducer,
});

const rootReducer = (state, action) => {
  if (action.type === actionTypes.RESET_STORE) {
    const { profile, notifications, auth } = state;

    const { email, companyName, fullname, flow, isPortalIntegration } = auth;
    let authState = { ...authDefaultState };

    authState.flow = flow ?? profile.flow;

    if (isPortalIntegration && email?.length && companyName?.length && fullname) {
      authState = {
        ...authState,
        email,
        companyName,
        fullname,
        isPortalIntegration,
        flowStarted: false,
      };
    }

    state = {
      profile,
      notifications: { permission: notifications.permission, enabled: true },
      auth: authState,
    };
  }
  return appReducer(state, action);
};

export default rootReducer;
