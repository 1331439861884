import React, { useMemo } from 'react';
import Common from 'src/components/Common';
import useProfile from 'src/hooks/useProfile';
import useTheme from 'src/hooks/useTheme';
import styles from './styles';
import Alerts from '../Alerts';
import { fetchAlerts } from 'src/lib/api/contentful';
import { useAlertStatusHandler } from 'src/hooks/useAlertStatusHandler';
import { isCompanyProtek, isWeb } from 'src/utils';
import { useFetch } from 'src/hooks/useFetch';
import Toggler from '../Toggler';
import AvailabilityBanner from '../AvailabilityBanner';
import LogoutButton from 'src/components/Logout';
import useTranslation from 'src/hooks/useTranslation';
import reactQueryKeys from 'src/constants/reactQueryKeys';

export default function Header({ hasTickets, setFilterStatus, filterStatus }) {
  const { colors: themeColors } = useTheme();
  const { translate } = useTranslation();

  const statuses = useMemo(
    () => [
      {
        label: translate('header.status.active'),
        value: 0,
      },
      {
        label: translate('header.status.done'),
        value: 1,
      },
    ],
    [],
  );

  const { data: alerts, isLoading: alertLoading } = useFetch(
    isCompanyProtek(companyInfo?.parentId) && [reactQueryKeys.ALERTS, { parentId: companyInfo?.parentId }],
    fetchAlerts,
    {
      refetchInterval: isWeb() ? 15000 : 60000,
      refetchIntervalInBackground: true,
    },
  );
  useAlertStatusHandler({ alerts, alertLoading });
  const { companyInfo, userInfo: { fullname } = {} } = useProfile();
  const firstName = (fullname && fullname.split(' ')[0]) || '';

  return (
    <Common.View>
      {isCompanyProtek(companyInfo?.parentId) && <Alerts alerts={alerts} alertLoading={alertLoading} />}
      <AvailabilityBanner />
      {hasTickets && (
        <>
          <Common.Row style={styles.headerWrapper}>
            <Common.Text style={styles.title} bold size={21} color={themeColors.text}>
              {translate('header.greet', firstName)}
            </Common.Text>
            <LogoutButton>
              <Common.Text color={themeColors.primaryColor} size={15} weight="400">
                {translate('header.notYou')}
              </Common.Text>
            </LogoutButton>
          </Common.Row>
          <Common.Row style={[styles.headerWrapper, styles.toggleContainer]}>
            <Toggler toggles={statuses} onChange={setFilterStatus} selected={filterStatus} />
          </Common.Row>
        </>
      )}
    </Common.View>
  );
}
