// import * as opentracing from 'opentracing';
// import lightstep from 'lightstep-tracer';
// import { lightStepConfig } from 'src/config/monitoring';
import * as Sentry from '@sentry/react-native';

export const initTracers = () => {
  // if (__DEV__) {
  //   const lightstepTracer = new lightstep.Tracer({
  //     component_name: lightStepConfig.componentName,
  //     access_token: lightStepConfig.accessToken,
  //     xhr_instrumentation: true,
  //     fetch_instrumentation: true,
  //     xhr_url_exclusion_patterns: [
  //       /cdn.contentful.com/,
  //       /us1.locationiq.com/,
  //       /settings.crashlytics.com/,
  //       /codepush.appcenter.ms/,
  //       /chatgenie.auth0.com/,
  //     ],
  //     xhr_url_header_exclusion_patterns: [
  //       /us1.locationiq.com/,
  //     ],
  //   });
  //   opentracing.initGlobalTracer(lightstepTracer);
  //   const span = opentracing.globalTracer().startSpan('test_span');
  //   span.setTag('kind', 'client');
  //   span.log({ event: 'what a lovely day' });
  //   span.finish();

  //   // tracer.flush() will ensure that your span is sent
  //   lightstepTracer.flush();
  // }
};

// export const openTracingGlobal = opentracing.globalTracer();
// // const span = opentracing.globalTracer().startSpan('test_span');
// // span.setTag('kind', 'client');
// // span.log({ event: 'what a lovely day' });
// // span.finish();

// // // tracer.flush() will ensure that your span is sent
// // tracer.flush();

export const reportError = (message) => {
  if (typeof message === 'string') {
    Sentry.captureMessage(message);
  }
};

export const reportException = (error, options) => {
  Sentry.captureException(error, options);
};
