import React from 'react';
import chatgenieLogo from '../../../../../../shared/src/assets/images/new-logo.png';

const Content = ({
  header, wrapperStyle, mainContent, sideContent,
}) => {
  return (
    <div className="support-bot-page" style={wrapperStyle}>
      <div className="main">
        <div className="content">
          {header}
          {mainContent}
          <div className="footer">
            <a className="footer-link" href="https://www.getthread.com" target="_blank" rel="noreferrer">
              <span className="footer-text">Powered by</span>
              <img className="footer-logo" src={chatgenieLogo} alt="company logo" />
            </a>
          </div>
        </div>

      </div>
      <div className="side-image">
        {sideContent}
      </div>
    </div>
  );
};

Content.defaultProps = {
  wrapperStyle: null,
  header: null,
};



export default Content;
