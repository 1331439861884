import React from 'react';
import { Image } from 'react-native';
import PropTypes from 'prop-types';

const style = {
  height: 37,
  width: 37,
  borderRadius: 1000,
};

const Avatar = ({ source, ...props }) => (
  <Image style={style} source={source} {...props} />
);

Avatar.defaultProps = {
  source: require('src/assets/images/avatar.png'),
};

Avatar.propTypes = {
  source: PropTypes.any,
};

export default Avatar;
