import React, { memo, useEffect, useMemo, useState } from 'react';
import uniq from 'lodash/uniqBy';
import Common from 'src/components/Common';
import reactQueryKeys from 'src/constants/reactQueryKeys';
import EmptyState from '../EmptyState';
import styles from './styles';
import { queryCache } from 'react-query';
import useChatState from 'src/screens/Chat/hooks/useChatState';
import { normalizeTicket } from 'src/utils/normalizer';
import Header from './Header';
import useTranslation from 'src/hooks/useTranslation';
import { GET_MY_THREADS } from 'src/graphql/queries';
import { useGraphQLQuery } from '@Thread-Magic/thread-service-utils';
import ThreadServiceCard from './components/ThreadServiceCard';
import { THREAD_STATE_FILTERS } from 'src/constants';

const ThreadServiceList = () => {
  const { translate } = useTranslation();
  const [filterStatus, setFilterStatus] = useState({
    label: translate('header.status.active'),
    value: 0,
  });
  const isFilterDone = filterStatus.value === 1;
  const { chatState, saveTicket } = useChatState();

  const {
    data: threads,
    fetchNextPage,
    isLoading: threadsLoading,
    isFetchingNextPage: nextThreadsFetching,
    hasNextPage: hasNextThreads,
  } = useGraphQLQuery(GET_MY_THREADS(), {
    variables: {
      limit: 20,
      stateIn: isFilterDone ? THREAD_STATE_FILTERS.DONE : THREAD_STATE_FILTERS.ACTIVE,
      sortByCreatedAt: 'DESC',
    },
    dataKey: 'myThreads',
    paginationType: 'cursor',
  });

  const sortedThreads = useMemo(() => {
    if (threads?.length) {
      return threads.sort((a, b) => new Date(b.created_at) - new Date(a.created_at));
    }
    return threads;
  }, [threads]);

  useEffect(() => {
    if (threads?.length && !threadsLoading) {
      const queryThreads = queryCache.getQueryData(reactQueryKeys.TICKETS) || [];
      const uniqueThreads = uniq([...threads, ...queryThreads], (i) => i.id);
      queryCache.setQueryData(reactQueryKeys.TICKETS, uniqueThreads);
    }

    const currentLiveThread = threads?.find((el) => el.id === chatState.ticket?.id);

    if (currentLiveThread) {
      saveTicket(normalizeTicket(currentLiveThread));
    }
  }, [threads, threadsLoading]);

  const showFooterSpinner = () => !threadsLoading && nextThreadsFetching && hasNextThreads;

  const ListEmptyComponent = threadsLoading ? <Common.Spinner size="large" /> : <EmptyState />;

  const handleEndReached = () => {
    if (!nextThreadsFetching && hasNextThreads) fetchNextPage({ limit: 20 });
  };

  return (
    <Common.View flexGrow={1} transparent>
      <Common.FlatList
        ListHeaderComponent={<Header setFilterStatus={setFilterStatus} filterStatus={filterStatus} hasTickets={true} />}
        data={sortedThreads}
        ListEmptyComponent={ListEmptyComponent}
        scrollEventThrottle={16}
        style={styles.list}
        contentContainerStyle={[styles.contentContainer, threads?.length && { paddingBottom: 60 }]}
        onEndReachedThreshold={0.5}
        onEndReached={handleEndReached}
        keyExtractor={(item) => item.id?.toString()}
        renderItem={({ item }) => <ThreadServiceCard item={item} />}
        ListFooterComponent={showFooterSpinner() && <Common.Spinner />}
      />
    </Common.View>
  );
};

export default memo(ThreadServiceList);
