import { useEffect, useRef, useState } from 'react';
import { Keyboard } from 'react-native';
import { scrollBottom } from '../utils';
import useChatState from './useChatState';
import useExistingTicketFlow from './useExistingTicketFlow';
import useNewTicketFlow from './useNewTicketFlow';

const useChatFlow = ({ existingTicket }) => {
  const {
    chatState: { ticket },
  } = useChatState();
  const [inputDisabled, setInputDisabled] = useState(false);

  const { loading, error, messages, onQuickReply, onSubmit, hasMore, isFetchingMore, loadMore } =
    existingTicket?.id === ticket?.id || !existingTicket
      ? useNewTicketFlow({ setInputDisabled }) // self created messages with replies
      : useExistingTicketFlow({ existingTicket }); // messages from Twilio/Thread Service

  useEffect(() => {
    scrollBottom();
    const keyboardDidShowListener = Keyboard.addListener('keyboardDidShow', scrollBottom);
    return () => {
      keyboardDidShowListener.remove();
    };
  }, []);

  return {
    loading,
    error,
    messages,
    onSubmit,
    onQuickReply,
    inputDisabled,
    isFetchingMore,
    hasMore,
    loadMore,
  };
};

export default useChatFlow;
