import actionTypes from 'src/constants/actionTypes';

export const alertsDefaultState = {
  unreadAlerts: {},
  readAlerts: {},
  notificationsOrder: [],
  openAlert: null,
  currentStatus: 'default',
  apolloRetryState: {
    retryCount: 0,
  },
};

const alertsReducer = (state = alertsDefaultState, action) => {
  switch (action.type) {
    case actionTypes.SET_UNREAD_ALERTS:
      return { ...state, ...action.payload };
    case actionTypes.SET_READ_ALERTS:
      return { ...state, readAlerts: action.payload };
    case actionTypes.SET_OPEN_ALERT:
      return action.payload;
    case actionTypes.RESET_UNREAD_ALERTS:
      return alertsDefaultState;
    case actionTypes.SET_APOLLO_CONNECTION_INDICATOR:
      return { ...state, apolloRetryState: { ...state.apolloRetryState, ...action.payload } };
    default:
      return state;
  }
};

export default alertsReducer;
