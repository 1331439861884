import React from 'react';
import Common from 'src/components/Common';
import styles from './style';

export default function Toggler({ toggles, selected, onChange }) {
  return (
    <Common.View style={styles.toggleContainer}>
      {toggles.map((toggle) => (
        <Common.TouchableOpacity
          key={toggle.value}
          onPress={() => onChange(toggle)}
          style={[styles.toggleButton, selected.value === toggle.value && styles.activeButton]}
        >
          <Common.Text>{toggle.label}</Common.Text>
        </Common.TouchableOpacity>
      ))}
    </Common.View>
  );
}
