import actionTypes from 'src/constants/actionTypes';
import { defaultWorkspaceColors } from 'src/lib/theme';
import { getCompanyColors } from 'src/utils';
import webchatIframe from 'src/utils/webchatIframe';
import { APP_NAVIGATOR_STATES } from 'src/constants';

export const defaultProfileState = {
  token: null,
  refreshToken: null,
  loggedIn: false,
  flow: null,
  userInfo: null,
  integration: {
    reintegrate: false,
    chatConfiguration: null,
    botInstalled: false,
  },
  parentCompanyInfo: {
    ...defaultWorkspaceColors,
  },
  companyInfo: {
    ...defaultWorkspaceColors,
  },
  soundPreferences: {
    playSoundOnNewMessage: true,
    playSoundOnNewThread: true,
  },
  companyBasicInfoLoading: false,
  appNavigatorState: APP_NAVIGATOR_STATES.onload,
  onboarding: {
    onboardingStep: 1,
    onboardingState: ''
  },
};

const checkIfColorsExist = (data) => ['primaryColor'].every((key) => !!data?.[key]);

const profileReducer = (state = defaultProfileState, action) => {
  switch (action.type) {
    case actionTypes.RESET_STORE:
      webchatIframe.updateTheme(state?.parentCompanyInfo?.primaryColor || defaultWorkspaceColors.primaryColor);
      return {
        ...defaultProfileState,
        companyInfo: state.parentCompanyInfo,
        parentCompanyInfo: state.parentCompanyInfo,
        onboarding: state.onboarding,
        uuid: state.uuid,
      };

    case actionTypes.UPDATE_PROFILE:
      if (action.payload.companyInfo) {
        const newCompanyInfo = { ...state.companyInfo, ...action.payload.companyInfo };
        if (!checkIfColorsExist(newCompanyInfo)) {
          const colors = getCompanyColors(state.parentCompanyInfo);
          newCompanyInfo.primaryColor = colors.primaryColor;
          newCompanyInfo.secondaryColor = colors.secondaryColor;
        }
        if (action.payload.companyInfo.primaryColor) {
          webchatIframe.updateTheme(newCompanyInfo.primaryColor);
        }
        // update tray icon for the widget
        if (action.payload.companyInfo.trayIcon) {
          webchatIframe.updateCompanyInfo({
            trayIcon: action.payload.companyInfo.trayIcon,
          });
        }
        return { ...state, ...action.payload, companyInfo: newCompanyInfo };
      }
      return { ...state, ...action.payload };

    case actionTypes.UPDATE_PROFILE_SOUND_PREFERENCE:
      return { ...state, soundPreferences: { ...state.soundPreferences, ...action.payload } };

    case actionTypes.UPDATE_PROFILE_ONBOARDING:
      return { ...state, onboarding: action.payload };

    default:
      return state;
  }
};

export default profileReducer;
