import { queryCache } from 'react-query';
import * as Sentry from '@sentry/react-native';
import reactQueryKeys from 'src/constants/reactQueryKeys';
import routeNames from 'src/constants/routeNames';
import { setActiveTicketAction } from 'src/redux/chat/chatActions';
import { store } from 'src/redux/store';
import { isElectron, isWeb } from 'src/utils';
import navigationService from 'src/utils/navigation';
import { normalizeTicket } from 'src/utils/normalizer';
import {
  NOTIFICATION_ANALYTICS_ACTIONS,
  notifyDesktop,
  trackDesktopNotification,
} from 'src/utils/desktop-notification';
import webchatIframe from 'src/utils/webchatIframe';
import useTranslation from 'src/hooks/useTranslation';
import {
  getMessageNotificationTxt,
  getThreadNotificationTxt,
  playMessageNotificationAudio,
  playTicketNotificationAudio,
} from 'src/hooks/utils/pushNotification.utils';
import { useDispatchMapper } from './actionHooks';
import { useNotification } from './useNotification';
import useProfile from './useProfile';

export default function useTwilioPushNotification() {
  const { uuid } = useProfile();
  const { addNotification, removeNotification } = useNotification();
  const saveActiveTicket = useDispatchMapper(setActiveTicketAction);
  const { locale } = useTranslation();

  const handleDesktopNotificationClick = (ticketId) => {
    trackDesktopNotification({ action: NOTIFICATION_ANALYTICS_ACTIONS.click });

    window?.ipc?.sendSync('focus-app');
    const tickets = queryCache.getQueryData(reactQueryKeys.TICKETS) || [];
    const notificationTicket = tickets.find((el) => el.id?.toString() === ticketId);
    removeNotification(ticketId);

    if (notificationTicket) {
      const normalized = normalizeTicket(notificationTicket);
      saveActiveTicket(normalized);
      const { messages } = store.getState().chat;
      if (messages.length) {
        navigationService.navigateWithReplace(routeNames.CHAT, { ticket: normalized });
      } else {
        navigationService.navigate(routeNames.CHAT, { ticket: normalized });
      }
    }
  };

  const handleMessageNotification = (newMessage) => {
    const { profile, chat, notifications } = store.getState();
    const { playSoundOnNewMessage } = profile.soundPreferences || {};

    const { conversation: channel, attributes } = newMessage;
    const activeTicket = chat.activeTicket;
    const ticketId = channel.attributes?.ticketId?.toString();
    const isAlreadyOpen = activeTicket?.id?.toString() === ticketId;
    const isAppFocused = document?.hasFocus();

    // Ignore if notifications are disabled
    if (!isWeb() || !notifications.enabled) return;
    // Ignore if ticket chat is open and window has focus
    if (isAlreadyOpen && isAppFocused) return;
    // Ignore if sender is the author of message
    if (profile.userInfo?.contactId === attributes.contactId) return;
    // Ignore internal notes from messageNotifications
    if (attributes.is_internal) return;
    // Ignore if message coming is from another workspace. This can
    // happen is because we use email as Twilio identity which is the
    // same across Thread workspaces.
    if (profile.companyInfo.parentId !== attributes.companyId) return;

    if (playSoundOnNewMessage && !isAppFocused) {
      playMessageNotificationAudio();
    }

    const notificationBody = getMessageNotificationTxt({
      author: attributes.author,
      messageBody: newMessage.body,
      locale,
    });

    createPushNotification({
      ticketId,
      title: channel.friendlyName,
      body: notificationBody,
    });
  };

  const handleThreadNotification = (newMessage) => {
    const { profile, notifications } = store.getState();
    const { playSoundOnNewThread } = profile.soundPreferences || {};

    const { conversation: channel, attributes } = newMessage;
    const ticket = attributes.ticket || {};
    const ticketId = ticket.id?.toString();
    const systemId = ticket.system_id?.toString();

    // Ignore if notifications are disabled
    if (!isWeb() || !notifications.enabled) return;
    // Ignore if sender is the author of message
    if (profile.userInfo?.contactId === ticket.contact?.id) return;
    // Ignore if message coming is from another workspace. This can
    // happen is because we use email as Twilio identity which is the
    // same across Thread workspaces.
    if (profile.companyInfo.parentId !== ticket.company_id) return;

    if (playSoundOnNewThread) {
      playTicketNotificationAudio();
    }

    const notificationBody = getThreadNotificationTxt({ systemId, ticket, locale });

    createPushNotification({
      ticketId,
      title: channel.friendlyName,
      body: notificationBody,
    });
  };

  const createPushNotification = async ({ ticketId, title, body }) => {
    const notificationData = { title, options: { body } };

    if (isElectron()) {
      const notification = await notifyDesktop(notificationData);
      if (notification) {
        trackDesktopNotification({ action: NOTIFICATION_ANALYTICS_ACTIONS.create });

        notification.onclick = () => {
          handleDesktopNotificationClick(ticketId);
        };
      }
    } else {
      notificationData.options.data = { ticketId, channelName: title, uuid };
      webchatIframe.sendNotification(notificationData);
    }
    addNotification(ticketId);
  };

  const subscribePushNotification = async (twilioClient) => {
    // registering event listener on new message from firebase to pass it to the Chat SDK for parsing
    twilioClient?.on('messageAdded', async (newMessage) => {
      try {
        const { ticket: threadNotification = {} } = newMessage.attributes;
        const isThreadNotification = threadNotification?.id;

        if (isThreadNotification) {
          handleThreadNotification(newMessage);
        } else {
          handleMessageNotification(newMessage);
        }
      } catch (err) {
        Sentry.captureException(new Error(`useTwilioPushNotification failed: ${err.message}`));
      }
    });
  };
  return { subscribePushNotification };
}
