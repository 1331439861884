/* eslint-disable no-nested-ternary */
import React from 'react';
import Common from 'src/components/Common';
import { colors } from 'src/constants/theme';
import PropTypes from 'prop-types';
import InfiniteScroll from 'react-infinite-scroll-component';
import { stylesWeb } from './style';

const MessageList = ({
  messages,
  renderListItem,
  loading,
  error,
  messagesRef,
  hasMore,
  renderEmptyMessage,
  loadMore,
  isFetchingMore,
}) => {
  const isLoading = isFetchingMore || loading;
  return (
    <div
      id="scrollableDiv"
      ref={messagesRef}
      style={stylesWeb.scrollerWrapperWeb}
    >
      <InfiniteScroll
        dataLength={messages.length}
        next={loadMore}
        style={stylesWeb.scrollerWeb}
        inverse
        hasMore={hasMore}
        scrollableTarget="scrollableDiv"
      >
        {!messages?.length ? (
          <Common.Row flexGrow={1}>
            {isLoading && (
            <Common.Spinner
              containerProps={isFetchingMore ? {
                style: stylesWeb.spinnerWeb,
              } : null}
            />
            ) }
            {!isLoading && error && (
            <Common.Row flexGrow={1} alignItems="center" justifyContent="center">
              <Common.Text color={colors.grey}>{error}</Common.Text>
            </Common.Row>
            )}
            {!isLoading && !error && renderEmptyMessage()}
          </Common.Row>
        ) : messages.map((item, index) => renderListItem({ item, index }))}
      </InfiniteScroll>
    </div>
  );
};

MessageList.defaultProps = {
  error: null,
  renderEmptyMessage: () => null,
};

MessageList.propTypes = {
  messages: PropTypes.arrayOf(PropTypes.object).isRequired,
  messagesRef: PropTypes.any.isRequired,
  renderListItem: PropTypes.func.isRequired,
  error: PropTypes.string,
  renderEmptyMessage: PropTypes.func,
  loading: PropTypes.bool.isRequired,
  hasMore: PropTypes.bool.isRequired,
  isFetchingMore: PropTypes.bool.isRequired,
  loadMore: PropTypes.func.isRequired,
};

export default MessageList;
