import { StyleSheet } from 'react-native';
import { colors } from 'src/constants/theme';

const styles = StyleSheet.create({
  closeBtn: {
    width: 40,
    height: 40,
    marginRight: 10,
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 4,
  },
  ticketInfo: {
    marginLeft: 10,
    backgroundColor: 'transparent',
  },
  emptyChatWrapper: {
    alignItems: 'center',
    marginTop: 90,
    width: '100%',
  },
  emptyChatText: {
    fontSize: 16,
    color: colors.grey8,
    textAlign: 'center',
    marginHorizontal: 10,
    marginTop: 10,
    maxWidth: 264,
  },
});

export default styles;
