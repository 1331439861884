import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { View } from 'src/components/Common';
import UserList from 'src/components/UserList';
import useTheme from 'src/hooks/useTheme';

const SelectUser = ({ route }) => {
  const onClickListItem = route.params?.onClickListItem;
  const { colors } = useTheme();
  return (
    <View flexGrow={1} color={colors.background}>
      <UserList onClickListItem={onClickListItem} />
    </View>
  );
};
SelectUser.propTypes = {
  route: PropTypes.object.isRequired,
};
export default memo(SelectUser);
