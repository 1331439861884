import React, { memo, useMemo } from 'react';
import PropTypes from 'prop-types';
import Common from 'src/components/Common';
import { getMessageStyle, getReplySpacerStyle, getTimestampStyle } from './style';
import Attachment from './Attachment';
import Text from './Text';
import styles, { getAvatar } from './style';
import { colors } from 'src/constants/theme';
import { formatDate } from 'src/utils/date';
import useProfile from 'src/hooks/useProfile';
import useTheme from 'src/hooks/useTheme';
import UserProfilePicture from 'src/components/Common/UserProfilePicture';

const renderSystemMessage = (message) => (
  <Common.Row justifyContent="center" style={styles.systemMessage}>
    <Common.Text color={colors.grey3} size={14}>
      {message?.message}
    </Common.Text>
  </Common.Row>
);

const Message = ({ index, message, messagesLength, participants }) => {
  if (message.attributes?.is_internal || !message.message) return null;
  if (message.type === 'system') return renderSystemMessage(message);

  const { companyInfo } = useProfile();
  const { colors: themeColors, theme, isDarkMode } = useTheme();
  const isMine = message.isMine;
  const author = message.attributes?.author;
  const user_type = message.attributes?.user_type;
  const isTextMessage = message.type === 'text';
  const { memberId, contactId, senderImageURL: twilioSenderImageURL } = message.attributes || {};
  const attachment = message.attachment;
  const isImage = !isTextMessage && (attachment?.file.type || message.message?.contentType).includes('image');

  const messageTimeLabel = useMemo(
    () => (
      <Common.Text
        style={getTimestampStyle({
          isImage,
        })}
        size={11}
        transform="uppercase"
        color={colors.grey}
      >
        {formatDate(message.timestamp || new Date(), 'hh:mm a')}
      </Common.Text>
    ),
    [message.timestamp],
  );

  const currentSender = useMemo(
    () =>
      participants.find((p) => {
        if (user_type === 'member') return p.id === memberId;
        return p.id === contactId;
      }),
    [participants, user_type, memberId, contactId],
  );

  // currentSender comes from threads query (contacts[],members[]) - realtime latest image URL
  // twilioSenderImageURL comes from twilio message attributes
  // participant could be removed from chat, in this case we can fall back to the image URL from twilio message payload
  const currentParticipantImageURL = currentSender?.profile_image?.path || twilioSenderImageURL;

  return (
    <Common.View style={[styles.wrapper, index === 0 && { marginTop: 0 }, isMine && styles.mineWrapper]}>
      {!isMine && (
        <>
          {!author || user_type === 'system' ? (
            <Common.Avatar source={getAvatar(companyInfo)} />
          ) : (
            <UserProfilePicture size={37} url={currentParticipantImageURL} alt={author} />
          )}
        </>
      )}
      <Common.View
        style={getMessageStyle({
          theme,
          themeColors,
          isDarkMode,
          isMine,
          isTextMessage,
        })}
      >
        {isTextMessage ? (
          <>
            <Text message={message} />
            {messageTimeLabel}
          </>
        ) : (
          <Attachment
            message={message.message || {}}
            attachment={attachment}
            isMine={isMine}
            isImage={isImage}
            author={author}
            messageTimeLabel={messageTimeLabel}
          />
        )}
      </Common.View>
      {index === messagesLength && <Common.View style={getReplySpacerStyle(message.quickReplies?.values?.length)} />}
    </Common.View>
  );
};

Message.propTypes = {
  message: PropTypes.object.isRequired,
  index: PropTypes.number.isRequired,
  messagesLength: PropTypes.number.isRequired,
};

export default memo(Message);
