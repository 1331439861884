import { StyleSheet } from 'react-native';

const styles = StyleSheet.create({
  wrapper: {
    width: '100%',
    paddingVertical: 11,
    paddingHorizontal: 20,
    backgroundColor: "#E1ECFE"
  },
  text: {
    fontSize: 14,
    textAlign: 'center'
  },
  bold: {
    fontWeight: '700',
  },
});

export default styles;
