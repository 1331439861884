import styled, { css } from 'styled-components/native';

export const StyledView = styled.View`
  background-color: ${({ color }) => color};
  ${({ height }) => height && css`
    height: ${height};
  `}
  ${({ flexGrow }) => flexGrow && css`
    flex-grow: 1;
  `}
`;
