import { useEffect } from 'react';
import { isElectron, sendHtmlNotification } from 'src/utils';
import { keyBy } from 'lodash';
import { reportException } from 'src/utils/monitoring';
import ConfirmModal from 'src/components/ConfirmModal';
import useProfile from './useProfile';
import { useAlert } from './useAlert';

const statusPriority = {
  default: 0,
  success: 1,
  info: 2,
  warning: 3,
  danger: 4,
};

export const useAlertStatusHandler = ({
  alerts,
  alertLoading,
}) => {
  const { companyInfo, token } = useProfile();
  const {
    unreadAlerts,
    readAlerts,
    notificationsOrder,
    currentStatus,
    saveReadAlerts,
    saveUnreadAlerts,
    setOpenAlert,
  } = useAlert();

  useEffect(() => {
    try {
      if (!alertLoading && Array.isArray(alerts) && alerts?.length) {
        if (Object.keys(readAlerts).length && Object.keys(unreadAlerts).length !== alerts?.length) {
          let statusSeverity = 'default';
          const newUnreadAlerts = { ...unreadAlerts };
          const newNotificationsOrder = notificationsOrder;
        alerts?.forEach((alert, idx) => {
          if (!readAlerts?.[alert?.id] && !unreadAlerts?.[alert?.id]) {
            if (statusPriority[alert?.type] > statusPriority[statusSeverity]) {
              statusSeverity = alert?.type;
            }
            if (isElectron()) {
              if (statusPriority[statusSeverity] > 2) {
                setTimeout(() => {
                  sendHtmlNotification({
                    title: alert?.title,
                    body: alert?.description,
                    data: alert,
                    icon: companyInfo?.trayIcon || require('src/assets/images/avatar.png'),
                    onClick: (event) => {
                      const focused = window?.ipc?.sendSync('focus-app');
                      if (focused) {
                        if (token) {
                          setOpenAlert(event?.target?.data);
                        } else {
                          ConfirmModal.alert('Please login to see the alert!');
                        }
                      }
                    },
                  });
                }, idx * 1000);
              }
            }
            newUnreadAlerts[alert.id] = alert;
            newNotificationsOrder.push(alert);
          }
        });
        // eslint-disable-next-line max-len
        const hasNewAlertHigherPriority = statusPriority[statusSeverity] > statusPriority[currentStatus];
        if (isElectron()) {
          // update alert status icon only if new alert has higher priority
          if (hasNewAlertHigherPriority) {
            window?.ipc?.send(`show-${statusSeverity}-icon`);
          }
        }
        saveUnreadAlerts({
          unreadAlerts: newUnreadAlerts,
          notificationsOrder: newNotificationsOrder,
          currentStatus: hasNewAlertHigherPriority ? statusSeverity : currentStatus,
        });
        } else {
          window?.ipc?.send('show-default-icon');
          // first alerts api response, save all alerts as read
          saveReadAlerts(keyBy(alerts, 'id'));
        }
      }
    } catch (err) {
      console.log(err);
      reportException(err);
    }
  }, [alerts, alertLoading]);
};
