import React, { memo } from 'react';
import PropTypes from 'prop-types';
import Common from 'src/components/Common';
import useTheme from 'src/hooks/useTheme';
import { getParticipantStyles, getParticipantListStyles, headerStyles } from './style';
import useChatState from 'src/screens/Chat/hooks/useChatState';
import { Participant, ParticipantUnassigned } from 'src/components/MentionsComponents/Participants';
import { getTicketParticipants } from 'src/utils/mention.utils';
import useTranslation from 'src/hooks/useTranslation';
import Summary from './Summary';

const ChatHeader = ({ ticket: ticketProps, isNewThread }) => {
  const { theme, isDarkMode } = useTheme();
  const { translate } = useTranslation();
  const { chatState } = useChatState();
  const peopleText = translate('header.text.people');
  const ticket = chatState.activeTicket ?? ticketProps;
  let ticketParticipants = getTicketParticipants(ticket);
  const participantsCount = ticketParticipants.length;
  const shouldShowParticipantsGroup = participantsCount > 1;
  // in UI, we show only first 3 participants
  ticketParticipants = ticketParticipants.slice(0, 3);

  const getHeaderProgressInfo = () => {
    if (shouldShowParticipantsGroup) {
      return `#${ticket.number} - ${participantsCount} ${peopleText} - ${ticket.status}`;
    }
    return `#${ticket.number} - ${ticket.status}`;
  };

  const renderTicketParticipants = () => {
    if (shouldShowParticipantsGroup) {
      return ticketParticipants.map((participant, index) => (
        <Participant
          key={participant.id}
          url={participant.profile_image?.path}
          size={37}
          participantName={participant.full_name || participant.name}
          style={getParticipantStyles({ offset: index, zIndex: participantsCount - index })}
        />
      ));
    }

    if (ticket.assigneeId) {
      return (
        <Participant
          size={37}
          participantName={ticket.assignee}
          style={getParticipantStyles({ offset: 0, zIndex: 1 })}
        />
      );
    }

    return <ParticipantUnassigned size={40} />;
  };

  return (
    <Common.View transparent style={headerStyles.wrapper}>
      <Common.View transparent style={getParticipantListStyles({ participantsCount })}>
        {renderTicketParticipants()}
      </Common.View>

      <Common.View transparent style={headerStyles.details}>
        <Summary threadSummary={ticket.summary} isNewThread={isNewThread} />
        <Common.Text size={12} numberOfLines={1}>
          {getHeaderProgressInfo()}
        </Common.Text>
      </Common.View>
    </Common.View>
  );
};

ChatHeader.propTypes = {
  ticket: PropTypes.object.isRequired,
  isNewThread: PropTypes.bool,
};
ChatHeader.defaultProps = {
  isNewThread: false,
}

export default memo(ChatHeader);
