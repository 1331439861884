import { colors } from 'src/constants/theme';

export const defaultWorkspaceColors = {
  primaryColor: colors.darkblue,
  secondaryColor: colors.yellow,
};

export const appTheme = {
  dark: {
    background: colors.darkBackground,
    foreground: colors.darkForeground,
    text: colors.grey100,
    placeholder: colors.grey500,
  },
  light: {
    background: colors.lightBackground,
    foreground: colors.lightForeground,
    text: colors.black,
    placeholder: colors.grey3,
  },
};
