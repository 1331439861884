export const screenCommonStyles = {
  headerTitleContainerStyle: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '65%',
    height: '100%',
    '-webkit-app-region': 'drag',
  },
};
