import React from 'react';
import { View, Modal } from 'react-native';
import { store } from 'src/redux/store';
import { openPopupAction, closePopupAction, resetPopupAction } from 'src/redux/popup/popupActions';
import { useSelector } from 'react-redux';
import useTheme from 'src/hooks/useTheme';
import { TouchableOpacity, Text, Row } from '../Common';
import useTranslation from 'src/hooks/useTranslation';

const ConfirmModal = () => {
  const { title, backdropClickEnabled, description, onOk, onCancel, visible, type, okText, data } = useSelector(
    (state) => state.popup,
  );
  const { translate } = useTranslation();

  const resetPopupState = () => {
    setTimeout(() => {
      store?.dispatch(resetPopupAction());
    }, 300);
  };

  const cancelHandle = () => {
    store?.dispatch(closePopupAction());
    onCancel();
    resetPopupState();
  };

  const okHandle = () => {
    store?.dispatch(closePopupAction());
    onOk();
    resetPopupState();
  };

  const { colors: themeColors } = useTheme();

  return (
    <Modal visible={visible && !data} animationType="fade" transparent>
      <TouchableOpacity
        style={{
          flexGrow: 1,
          justifyContent: 'center',
          alignItems: 'center',
          backgroundColor: 'rgba(0,0,0,0.3)',
          margin: 0,
          padding: 0,
          height: '100%',
          borderRadius: 10,
          overflow: 'hidden',
        }}
        activeOpacity={1}
        onPress={() => (backdropClickEnabled ? cancelHandle() : null)}
      >
        <View
          style={{
            minHeight: 100,
            width: 250,
            backgroundColor: themeColors.foreground,
            borderRadius: 10,
          }}
          activeOpacity={1}
        >
          <View
            style={{ backgroundColor: 'transparent', padding: 20, flexGrow: 1 }}
            onStartShouldSetResponder={() => true}
          >
            <Text style={{ marginBottom: 10 }} marginBottom={10} size={18} bold>
              {title}
            </Text>
            {description && <Text size={16}>{description}</Text>}
          </View>
          {type === 'confirm' ? (
            <Row justifyContent="space-between" alignItems="center">
              <TouchableOpacity
                style={{
                  marginBottom: 10,
                  padding: 10,
                  flex: 1,
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
                onPress={cancelHandle}
              >
                <Text bold size={17}>
                  {translate('common.text.cancel')}
                </Text>
              </TouchableOpacity>
              <TouchableOpacity
                style={{
                  marginBottom: 10,
                  padding: 10,
                  flex: 1,
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
                onPress={okHandle}
              >
                <Text bold size={17}>
                  {okText}
                </Text>
              </TouchableOpacity>
            </Row>
          ) : (
            <Row alignItems="center" justifyContent="center">
              <TouchableOpacity
                style={{
                  marginBottom: 10,
                  padding: 10,
                  flex: 1,
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
                onPress={okHandle}
              >
                <Text bold size={17}>
                  {okText || 'Ok'}
                </Text>
              </TouchableOpacity>
            </Row>
          )}
        </View>
      </TouchableOpacity>
    </Modal>
  );
};

ConfirmModal.alert = (payload) => {
  if (typeof payload === 'string') {
    store?.dispatch(
      openPopupAction({
        title: payload,
        type: 'alert',
      }),
    );
    return;
  }
  if (typeof payload === 'object') {
    store?.dispatch(openPopupAction(payload));
    return;
  }
  throw Error('ConfirmModal invalid payload');
};

export default ConfirmModal;
