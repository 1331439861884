import React from 'react';
import PropTypes from 'prop-types';
import Common from 'src/components/Common';
import { styles } from './style';
import UserProfilePicture from 'src/components/Common/UserProfilePicture';

export const Participant = ({ participantName = '', size = 25, url = '', style }) => {
  return (
    <Common.View transparent style={[styles.participantAvatar, style]}>
      <UserProfilePicture size={size} alt={participantName} url={url} />
    </Common.View>
  );
};

Participant.propTypes = {
  participantName: PropTypes.string,
  size: PropTypes.number,
};
