import React, { useCallback, useMemo, useEffect } from 'react';
import { View, Modal, Text } from 'react-native';
import { TouchableOpacity } from '../Common';
import { useDispatchMapper } from 'src/hooks/actionHooks';
import { getNextButton, getStep, styles } from './style';
import useProfile from 'src/hooks/useProfile';
import { isElectron } from 'src/utils';
import { USER_PROFILE_ONBOARDING_STATES as ONBOARDING_STATES } from 'src/redux/profile/profileStateConstants';
import CompanyImage from 'src/components/CompanyImage';
import { DesktopOnboardingStep1, DesktopOnboardingStep2, DesktopOnboardingStep3 } from 'src/assets/icons';
import { updateProfileOnboardingAction } from 'src/redux/profile/profileActions';
import useTranslation from 'src/hooks/useTranslation';
import useTheme from 'src/hooks/useTheme';

const ONBOARDING_DATA = {
  title: {
    1: 'onboarding.title.step1',
    2: 'onboarding.title.step2',
    3: 'onboarding.title.step3',
  },
  desc: {
    1: 'onboarding.desc.step1',
    2: 'onboarding.desc.step2',
    3: 'onboarding.desc.step3',
  },
  img: {
    1: <DesktopOnboardingStep1 style={styles.onboardingStepImg} />,
    2: <DesktopOnboardingStep2 style={styles.onboardingStepImg} />,
    3: <DesktopOnboardingStep3 style={styles.onboardingStepImg} />,
  },
};

const DesktopOnboardingModal = () => {
  const { onboarding, loggedIn, companyInfo } = useProfile();
  const { theme } = useTheme();
  const { onboardingStep, onboardingState } = onboarding || {};
  const { translate } = useTranslation();
  const updateProfileOnboarding = useDispatchMapper(updateProfileOnboardingAction);

  // NB: to collect the OS name on Electron's browser
  // navigator?.userAgent?.includes('Windows') - for Windows 'Mac' for Mac

  const isModalVisible = useMemo(() => {
    if (!companyInfo?.name) return false;

    const canShowOnboarding = !onboardingState || onboardingState === ONBOARDING_STATES.DESKTOP_ONBOARDING;
    return isElectron() && canShowOnboarding && !loggedIn;
  }, [onboardingState, loggedIn, companyInfo]);

  const renderSteps = useCallback(
    () =>
      [1, 2, 3].map((idx) => {
        const isActive = idx === onboardingStep;

        return <View key={idx} style={getStep({ isActive, theme })} />;
      }),
    [onboardingStep, theme],
  );

  const handleSkip = () => {
    updateProfileOnboarding({
      onboardingState: ONBOARDING_STATES.DESKTOP_ONBOARDING_SKIPPED,
      onboardingStep: 3,
    });
  };

  const handleNextStep = () => {
    if (onboardingStep === 3) {
      updateProfileOnboarding({
        onboardingState: ONBOARDING_STATES.DESKTOP_ONBOARDING_COMPLETED,
        onboardingStep: 3,
      });
      return;
    }

    updateProfileOnboarding({
      onboardingState: ONBOARDING_STATES.DESKTOP_ONBOARDING,
      onboardingStep: onboardingStep + 1,
    });
  };

  useEffect(() => {
    if (loggedIn) {
      updateProfileOnboarding({
        onboardingState: ONBOARDING_STATES.DESKTOP_ONBOARDING_COMPLETED,
        onboardingStep: 3,
      });
    }
  }, []);

  const onboardingTitleTxt = translate(ONBOARDING_DATA.title[onboardingStep], companyInfo?.name);
  const onboardingDescTxt = translate(ONBOARDING_DATA.desc[onboardingStep]);
  const txtContinue = translate('common.text.continue');
  const txtSkip = translate('common.text.skip');

  return (
    <Modal visible={isModalVisible} animationType="fade" transparent>
      <View activeOpacity={1} style={styles.onboardingWrap}>
        <View style={styles.onboardingMediaArea}>
          <View style={styles.companyLogoWrap}>
            <CompanyImage />
          </View>
          {ONBOARDING_DATA.img[onboardingStep]}
        </View>
        <View style={styles.onboardingTextArea}>
          <Text style={styles.onboardingTextTitle}>{onboardingTitleTxt}</Text>
          <Text style={styles.onboardingTextDesc}>{onboardingDescTxt}</Text>
          <View style={styles.stepsWrap}>{renderSteps()}</View>
          <TouchableOpacity style={getNextButton({ theme })} onPress={handleNextStep}>
            <Text style={styles.commonTxt}>{txtContinue}</Text>
          </TouchableOpacity>
        </View>
        <TouchableOpacity style={styles.btnSkip} onPress={handleSkip}>
          <Text style={styles.commonTxt}>{txtSkip}</Text>
        </TouchableOpacity>
      </View>
    </Modal>
  );
};

export default DesktopOnboardingModal;
