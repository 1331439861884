import { StyleSheet } from 'react-native';

const styles = StyleSheet.create({
  // ThreadServiceList styles
  contentContainer: {
    flexGrow: 1,
  },
  list: {
    flexGrow: 1,
    flexBasis: 1,
  },
  // Header styles
  headerWrapper: {
    justifyContent: 'space-between',
    alignItems: 'center',
    marginHorizontal: 20,
    marginTop: 20,
  },
  title: {
    fontWeight: '800',
  },
  toggleContainer: {
    marginTop: 10,
    marginBottom: 20,
  },
});

export default styles;
