import React from 'react';
import PropTypes from 'prop-types';
import useTheme from 'src/hooks/useTheme';
import { fonts } from 'src/constants/theme';
import { Text as RNText } from 'react-native';

const Text = ({ children, bold, transform, weight, size, color, textDecoration, style, ...rest }) => {
  const { colors: themeColors } = useTheme();
  return (
    <RNText
      style={[
        { fontFamily: fonts.regular, color: 'inherit' },
        bold && { fontFamily: fonts.bold },
        color && { color },
        weight && { fontWeight: weight },
        transform && { textTransform: transform },
        size && { fontSize: size },
        textDecoration && { textDecorationLine: textDecoration },
        style && style,
      ]}
      {...rest}
    >
      {children}
    </RNText>
  );
};

Text.defaultProps = {
  children: null,
  size: 14,
  bold: false,
  weight: null,
  transform: 'none',
  style: null,
  color: null,
};

Text.propTypes = {
  children: PropTypes.node,
  size: PropTypes.number,
  weight: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  color: PropTypes.string,
  transform: PropTypes.oneOf(['none', 'uppercase', 'capitalize', 'lowercase']),
  bold: PropTypes.bool,
  style: PropTypes.any,
};

export default Text;
