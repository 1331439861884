import { useTwilioChannel } from '@Thread-Magic/chat-utils';
import { chatgenieAPI } from 'src/lib/api/chatgenieApi';
import useProfile from './useProfile';
import * as Sentry from '@sentry/react-native';

export default function useConnectTwilioChannel() {
  const { userInfo } = useProfile();
  const { connectChannel: connectTwilioChannel } = useTwilioChannel();

  const connectChannel = (channelUniqueName, channelId) =>
    new Promise((resolve, reject) => {
      connectTwilioChannel(channelUniqueName)
        .then((channel) => {
          if (channel?.status === 'joined') {
            resolve(channel);
          } else {
            channel
              .join()
              .then((joinedChannel) => resolve(joinedChannel))
              .catch((err) => {
                Sentry.captureException(err);
                reject(err);
              });
          }
        })
        .catch((err) => {
          if (err?.message === 'Forbidden' && channelId) {
            chatgenieAPI
              .joinTwilioConversation({
                id: channelId,
                data: {
                  contact: {
                    id: userInfo.contactId,
                  },
                },
              })
              .then(() => {
                connectTwilioChannel(channelUniqueName)
                  .then((channel) => {
                    resolve(channel);
                  })
                  .catch((err) => {
                    Sentry.captureException(err);
                    reject(err);
                  });
              })
              .catch((err) => {
                Sentry.captureException(err?.response?.data?.message);
                reject(err?.response?.data?.message);
              });
          } else {
            Sentry.captureException(err);
            reject(err);
          }
        });
    });
  return { connectChannel };
}
