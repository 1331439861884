import actionTypes from 'src/constants/actionTypes';


const defaultState = [];

const mfaReducer = (state = defaultState, action) => {
  switch (action.type) {
    case actionTypes.MFA_ADD:
      return [...state, action.payload];
    case actionTypes.MFA_DELETE:
      return state.filter((el) => el.id !== action.payload);
    case actionTypes.MFA_UPDATE: {
      const { index, value } = action.payload;
      const clone = [...state];
      if (clone[index]) {
        clone[index].totp.account = value;
      }
      return clone;
    }
    case actionTypes.MFA_LIST_UPDATE: {
      if (action.payload) {
        return action.payload;
      }
      return state;
    }
    default:
      return state;
  }
};


export default mfaReducer;
