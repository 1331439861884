import { userFlows } from 'src/constants';
import { v4 as uuidv4 } from 'uuid';

const updateProfileState = (profile) => {
  if (profile?.token && !profile?.loggedIn) {
    profile = {
      ...profile,
      loggedIn: true,
      flow: userFlows.EXISTING_USER,
    };
  }
  return profile;
};

export const migratePeristedState = (state) => {
  if (state?._persist?.version === -1) {
    state.profile = updateProfileState(state.profile);
  }
  if (!state?.profile) {
    state = {
      ...state,
      profile: {
        userInfo: {},
      },
    };
  }

  if (!state.profile?.uuid) {
    state.profile.uuid = uuidv4();
  }

  state.notifications = {
    enabled: state.notifications?.enabled ?? true,
  };
  return Promise.resolve(state);
};
