import { useSelector } from 'react-redux';
import {
  removeTicketNotificationAction,
  addTicketNotificationAction,
  resetTicketNotificationAction,
  updateNotificationEnabledAction,
} from 'src/redux/notifications/actions';
import { useDispatchMapper } from './actionHooks';

export const useNotification = () => {
  const notificationState = useSelector((store) => store.notifications);
  const addNotification = useDispatchMapper(addTicketNotificationAction);
  const removeNotification = useDispatchMapper(removeTicketNotificationAction);
  const resetNotification = useDispatchMapper(resetTicketNotificationAction);
  const updateNotificationEnabled = useDispatchMapper(updateNotificationEnabledAction);

  return {
    ticketNotifications: notificationState.tickets,
    permission: notificationState.permission,
    enabled: notificationState.enabled && notificationState.permission === 'granted',
    updateNotificationEnabled,
    addNotification,
    removeNotification,
    resetNotification,
  };
};
