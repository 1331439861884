import React from 'react';
import Common from 'src/components/Common';
import useTranslation from 'src/hooks/useTranslation';
import { WorkspaceDisabledIcon } from 'src/assets/icons';
import { styles } from './style';

const WorkspaceDisabledState = () => {
  const { translate } = useTranslation();

  return (
    <Common.View style={styles.wrapper}>
      <WorkspaceDisabledIcon />
      <Common.Text style={styles.disabledText}>{translate('bot.workspace.disabled')}</Common.Text>
    </Common.View>
  );
};

export default WorkspaceDisabledState;
