import uniqBy from 'lodash/uniqBy';

export const replaceMentionTags = (text) => {
  if (!text) return '';
  return text.replace(/<!([^>]+)>/g, '@$1').replace(/<@[^|]+\|([^>]+)>/g, '@$1');
};

export const hasMentionsTag = (text) => {
  if (!text) return '';
  return text.includes('<!') || text.includes('<@');
};

export const MENTIONS_REGEX = {
  CONTACT: /<@C[^|]+\|([^>]+)>/g,
  MEMBER: /<@M[^|]+\|([^>]+)>/g,
  SPECIAL: /<!(\w+)\|(.+?)>/g,
  // combined matches contact | member | special
  // first part matches contacts and members: @[^|]+\|([^>]+)
  // second part matches special mentions: !([^>]+)
  COMBINED: /<(?:@[^|]+\|([^>]+)|!([^>]+))>/g,
}

/**
 *
 * @param str  - user full name
 * @param separator - by default separates by spaces
 * @returns {string}
 */
export const formatToFirstName = (str = '', separator = ' ') => {
  if (!str?.trim().length) return '';

  const names = str.trim().split(separator);
  if (!names.length) return '';

  return `${names[0]}:`;
};

export const getTicketParticipants = (ticket) => {
  const members = ticket.members || [];
  const contacts = ticket.contacts || [];
  let participants = [...members, ...contacts];

  participants = uniqBy(participants, 'id');

  return participants;
};
