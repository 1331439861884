import actionTypes from 'src/constants/actionTypes';

export const openPopupAction = (payload) => ({
  type: actionTypes.OPEN_POPUP,
  payload,
});

export const closePopupAction = () => ({
  type: actionTypes.CLOSE_POPUP,
});

export const resetPopupAction = () => ({
  type: actionTypes.RESET_POPUP,
});
