import React from 'react';
import { View, Modal, Image, Text } from 'react-native';
import { resetPopupAction } from 'src/redux/popup/popupActions';
import { useSelector } from 'react-redux';
import { TouchableOpacity } from '../Common';
import { useDispatchMapper } from 'src/hooks/actionHooks';
import { styles } from './style';

const PhotoViewerModal = () => {
  const { data, visible } = useSelector((state) => state.popup);
  const resetPopup = useDispatchMapper(resetPopupAction);
  const isVisible = Boolean(visible && data);

  return (
    <Modal visible={isVisible} animationType="fade" transparent>
      <TouchableOpacity style={styles.modalWrapper} activeOpacity={1} onPress={resetPopup}>
        <View activeOpacity={1} style={styles.imageWrapper}>
          <Image source={{ uri: data?.imageUrl }} style={styles.image} />
          <TouchableOpacity style={styles.btnClose} onPress={resetPopup}>
            <Text style={styles.btnCloseTxt}>&times;</Text>
          </TouchableOpacity>
        </View>
      </TouchableOpacity>
    </Modal>
  );
};

export default PhotoViewerModal;
