import { format, parseISO } from 'date-fns';

export const getTimeZone = () => {
  const { 1: tz } = new Date().toString().match(/\((.+)\)/);

  if (tz?.includes(' ')) {
    return tz
      ?.split(' ')
      ?.map(([first]) => first)
      ?.join('');
  }
  return tz;
};
export const formatDate = (date, dateFormat) => {
  if (!date) throw Error('date is required');
  if (!dateFormat) throw Error('dateFormat is required');
  let parsedDate = date;
  if (typeof date === 'string') {
    parsedDate = parseISO(date);
  }
  return format(parsedDate, dateFormat);
};
