import React, { useEffect, useLayoutEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Chatbox from 'src/components/Chatbox';
import Common from 'src/components/Common';
import { trackScreenView } from 'src/utils/analytics/GAtracker';
import routeNames from 'src/constants/routeNames';
import useTheme from 'src/hooks/useTheme';
import { useDispatchMapper } from 'src/hooks/actionHooks';
import { setActiveTicketAction } from 'src/redux/chat/chatActions';
import { EmptyChatIcon } from 'src/assets/icons';
import styles from './style';
import ChatHeader from './components/ChatHeader';
import useChatFlow from './hooks/useChatFlow';
import useChatState from './hooks/useChatState';
import { chatboxRef } from './utils';
import { responsiveWidth } from 'src/utils/responsive';
import useTranslation from 'src/hooks/useTranslation';
import { screenCommonStyles } from 'src/screens/common.styles';
import { LIVE_CHAT_PREFIX, OUTDATED_CHAT_PREFIX } from 'src/utils';
import { ApolloConnectionIndicator } from 'src/components/ConnectionIndicator';

const renderEmptyMessage = () => (
  <Common.View style={styles.emptyChatWrapper}>
    <EmptyChatIcon />
    <Common.Text style={styles.emptyChatText}>Looks like there are no messages here yet!</Common.Text>
  </Common.View>
);

const calculateHeaderTitleWidth = () => responsiveWidth(100) - 37 - 45 - 10; // 37 - back icon, 45 - avatar, 10 - padding

const Chat = ({ route, navigation }) => {
  const { ticket: existingTicket, outdatedTicket, isNewRequest } = route.params || {};
  const { theme } = useTheme();
  const {
    messages,
    inputDisabled,
    onSubmit,
    onQuickReply,
    error,
    loading,
    isFetchingMore,
    hasMore,
    loadMore,
  } = useChatFlow({ existingTicket });
  const {
    chatState: { ticket },
  } = useChatState();
  const saveActiveTicket = useDispatchMapper(setActiveTicketAction);
  const [updated, setUpdated] = useState(false);
  const { translate } = useTranslation();
  const isNewTicket = existingTicket?.id === ticket?.id || !existingTicket;
  const isNew = Boolean(isNewTicket || isNewRequest);

  useLayoutEffect(() => {
    const options = {};
    if (existingTicket) {
      // header for already existing thread
      options.headerTitle = () => <ChatHeader ticket={existingTicket} />;
      options.headerTitleAlign = 'left';
      const headerTitleWidth = calculateHeaderTitleWidth();
      options.headerTitleContainerStyle = {
        left: 35,
        marginBottom: 5,
        width: headerTitleWidth,
        '-webkit-app-region': 'drag',
      };
    } else {
      // at stage of creating new live thread or request thread
      const newRequestTitle = translate('ticket.newRequest.text');
      const newChatTitle = translate('common.text.newChat');

      options.headerTitle = isNewRequest ? newRequestTitle : newChatTitle;
      options.headerTitleContainerStyle = screenCommonStyles.headerTitleContainerStyle;
    }
    navigation.setOptions(options);
    trackScreenView(routeNames.CHAT);
  }, [existingTicket]);

  useEffect(() => {
    // header of newly created thread
    if (ticket?.systemId && !updated && !existingTicket) {
      navigation.setOptions({
        headerTitle: () => <ChatHeader ticket={ticket} isNewThread={isNew} />,
        headerTitleAlign: 'left',
        headerTitleContainerStyle: {
          left: 35,
          marginBottom: 5,
          width: calculateHeaderTitleWidth(),
          '-webkit-app-region': 'drag',
        },
      });
      if (ticket?.systemId && ticket?.assignee) {
        setUpdated(true);
      }
      saveActiveTicket(ticket);
    }
  }, [ticket, isNew]);

  useEffect(() => {
    if (!outdatedTicket || !outdatedTicket.summary?.length || messages?.length > 1) return;

    let summaryOfOutdatedTicket = outdatedTicket.summary;
    if (summaryOfOutdatedTicket.includes(LIVE_CHAT_PREFIX)) {
      summaryOfOutdatedTicket = summaryOfOutdatedTicket.split(`${LIVE_CHAT_PREFIX} `)[1];
      onSubmit(`${OUTDATED_CHAT_PREFIX} ${summaryOfOutdatedTicket}`);
    }
  }, [outdatedTicket]);

  return (
    <>
      <ApolloConnectionIndicator />
      <Chatbox
        loading={loading}
        error={error}
        renderEmptyMessage={renderEmptyMessage}
        inputDisabled={Boolean(inputDisabled || error || loading)}
        messages={messages}
        messagesRef={chatboxRef}
        onSendMessage={onSubmit}
        onQuickReply={onQuickReply}
        isFetchingMore={isFetchingMore}
        loadMore={loadMore}
        hasMore={hasMore}
        isNewTicket={isNewTicket} // live thread
        isNewRequest={isNewRequest} // request thread
      />
    </>
  );
};

Chat.propTypes = {
  navigation: PropTypes.object.isRequired,
  route: PropTypes.object.isRequired,
};

export default Chat;
