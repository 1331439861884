import React from 'react';
/* eslint-disable react/prop-types */
import EV from 'react-native-vector-icons/dist/EvilIcons';
import IO from 'react-native-vector-icons/dist/Ionicons';
import FO from 'react-native-vector-icons/dist/FontAwesome';
import FE from 'react-native-vector-icons/dist/Feather';
import MA from 'react-native-vector-icons/dist/MaterialCommunityIcons';
import { Platform } from 'react-native';

const getPlatformFontSize = (size) => (Platform.OS === 'web' ? size - 5 : size);

export const EvilIcons = ({ size, name, ...rest }) => (
  <EV size={getPlatformFontSize(size)} name={name} {...rest} />
);
export const Feather = ({ size, name, ...rest }) => (
  <FE size={getPlatformFontSize(size)} name={name} {...rest} />
);
export const MaterialCommunityIcons = ({ size, name, ...rest }) => (
  <MA size={getPlatformFontSize(size)} name={name} {...rest} />
);
export const Ionicons = ({ size, name, ...rest }) => (
  <IO size={getPlatformFontSize(size)} name={name} {...rest} />
);
export const FontAwesome = ({ size, name, ...rest }) => (
  <FO size={getPlatformFontSize(size)} name={name} {...rest} />
);
