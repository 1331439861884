import React, { useEffect, useRef } from 'react';
import { Animated, StyleSheet } from 'react-native';
import Svg, { Path } from 'react-native-svg';

const AnimatedPath = Animated.createAnimatedComponent(Path);

const Sparkles = () => {
  const sparkleOneScale = useRef(new Animated.Value(1)).current;
  const sparkleTwoScale = useRef(new Animated.Value(1)).current;
  const sparkleThreeOpacity = useRef(new Animated.Value(0)).current;
  const sparkleThreeScale = useRef(new Animated.Value(1)).current;
  const sparkleFourOpacity = useRef(new Animated.Value(0)).current;
  const sparkleFourScale = useRef(new Animated.Value(1)).current;

  useEffect(() => {
    Animated.loop(
      Animated.sequence([
        Animated.timing(sparkleOneScale, { toValue: 0.15, duration: 600, useNativeDriver: true }),
        Animated.timing(sparkleOneScale, { toValue: 0.15, duration: 800, useNativeDriver: true }),
        Animated.timing(sparkleOneScale, { toValue: 1, duration: 600, useNativeDriver: true }),
      ]),
    ).start();

    Animated.loop(
      Animated.sequence([
        Animated.timing(sparkleTwoScale, { toValue: 0.32, duration: 600, useNativeDriver: true }),
        Animated.timing(sparkleTwoScale, { toValue: 0.32, duration: 800, useNativeDriver: true }),
        Animated.timing(sparkleTwoScale, { toValue: 1, duration: 600, useNativeDriver: true }),
      ]),
    ).start();

    Animated.loop(
      Animated.sequence([
        Animated.timing(sparkleThreeOpacity, { toValue: 0, duration: 100, useNativeDriver: true }),
        Animated.timing(sparkleThreeOpacity, { toValue: 1, duration: 100, useNativeDriver: true }),
        Animated.timing(sparkleThreeOpacity, { toValue: 1, duration: 1500, useNativeDriver: true }),
        Animated.timing(sparkleThreeOpacity, { toValue: 0, duration: 300, useNativeDriver: true }),
      ]),
    ).start();

    Animated.loop(
      Animated.sequence([
        Animated.timing(sparkleThreeScale, { toValue: 1, duration: 100, useNativeDriver: true }),
        Animated.timing(sparkleThreeScale, { toValue: 1.5, duration: 1500, useNativeDriver: true }),
        Animated.timing(sparkleThreeScale, { toValue: 1, duration: 300, useNativeDriver: true }),
      ]),
    ).start();

    Animated.loop(
      Animated.sequence([
        Animated.timing(sparkleFourOpacity, { toValue: 0, duration: 100, useNativeDriver: true }),
        Animated.timing(sparkleFourOpacity, { toValue: 1, duration: 100, useNativeDriver: true }),
        Animated.timing(sparkleFourOpacity, { toValue: 1, duration: 1500, useNativeDriver: true }),
        Animated.timing(sparkleFourOpacity, { toValue: 0, duration: 300, useNativeDriver: true }),
      ]),
    ).start();

    Animated.loop(
      Animated.sequence([
        Animated.timing(sparkleFourScale, { toValue: 1, duration: 100, useNativeDriver: true }),
        Animated.timing(sparkleFourScale, { toValue: 1.5, duration: 1500, useNativeDriver: true }),
        Animated.timing(sparkleFourScale, { toValue: 1, duration: 300, useNativeDriver: true }),
      ]),
    ).start();
  }, []);

  return (
    <Svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" style={styles.svg}>
      <AnimatedPath
        d="M5.35123 9.15488C5.36724 9.10592 5.4365 9.10592 5.45251 9.15488L5.63958 9.72698C5.71603 9.96079 5.89589 10.1464 6.1272 10.2301L6.66533 10.4249C6.71219 10.4419 6.71219 10.5081 6.66533 10.5251L6.1272 10.7199C5.89589 10.8036 5.71603 10.9892 5.63958 11.223L5.45251 11.7951C5.4365 11.8441 5.36724 11.8441 5.35123 11.7951L5.16416 11.223C5.08771 10.9892 4.90785 10.8036 4.67654 10.7199L4.13841 10.5251C4.09155 10.5081 4.09155 10.4419 4.13841 10.4249L4.67654 10.2301C4.90785 10.1464 5.08771 9.96079 5.16416 9.72698L5.35123 9.15488Z"
        fill="#9AE5D7"
        stroke="black"
        strokeWidth="0.123737"
        style={{ transform: [{ scale: sparkleThreeScale }], opacity: sparkleThreeOpacity, transformOrigin: 'center' }}
      />
      <AnimatedPath
        d="M9.53303 4.7546C9.58906 4.58322 9.8315 4.58322 9.88753 4.7546L10.5423 6.75693C10.8098 7.57528 11.4394 8.22481 12.2489 8.51787L14.1324 9.19965C14.2964 9.25902 14.2964 9.49098 14.1324 9.55036L12.2489 10.2321C11.4394 10.5252 10.8098 11.1747 10.5423 11.9931L9.88753 13.9954C9.8315 14.1668 9.58906 14.1668 9.53303 13.9954L8.87831 11.9931C8.61072 11.1747 7.9812 10.5252 7.17162 10.2321L5.28816 9.55036C5.12414 9.49098 5.12415 9.25902 5.28816 9.19965L7.17162 8.51787C7.9812 8.22481 8.61072 7.57528 8.87831 6.75693L9.53303 4.7546Z"
        fill="#9AE5D7"
        stroke="black"
        strokeWidth="0.433079"
        style={{ transform: [{ scale: sparkleOneScale }], transformOrigin: 'center' }}
      />
      <AnimatedPath
        d="M4.70245 2.30977C4.73447 2.21184 4.87301 2.21184 4.90503 2.30977L5.27915 3.45396C5.43206 3.92159 5.79178 4.29275 6.2544 4.46021L7.33066 4.8498C7.42439 4.88372 7.42439 5.01628 7.33066 5.0502L6.2544 5.43979C5.79179 5.60725 5.43206 5.97841 5.27915 6.44604L4.90503 7.59023C4.87301 7.68816 4.73447 7.68816 4.70245 7.59023L4.32832 6.44604C4.17542 5.97841 3.81569 5.60725 3.35308 5.43979L2.27682 5.0502C2.18309 5.01628 2.18309 4.88372 2.27681 4.8498L3.35308 4.46021C3.81569 4.29275 4.17542 3.92159 4.32832 3.45396L4.70245 2.30977Z"
        fill="#F6F6AF"
        stroke="black"
        strokeWidth="0.247474"
        style={{ transform: [{ scale: sparkleTwoScale }], transformOrigin: 'center' }}
      />
      <AnimatedPath
        d="M10.3606 3.62989C10.3766 3.58092 10.4458 3.58092 10.4619 3.62989L10.6489 4.20198C10.7254 4.43579 10.9052 4.62138 11.1365 4.70511L11.6747 4.8999C11.7215 4.91686 11.7215 4.98314 11.6747 5.0001L11.1365 5.1949C10.9052 5.27863 10.7254 5.46421 10.6489 5.69802L10.4619 6.27012C10.4458 6.31908 10.3766 6.31908 10.3606 6.27012L10.1735 5.69802C10.0971 5.46421 9.91719 5.27863 9.68588 5.1949L9.14775 5.0001C9.10089 4.98314 9.10089 4.91686 9.14775 4.8999L9.68588 4.70511C9.91719 4.62138 10.0971 4.43579 10.1735 4.20198L10.3606 3.62989Z"
        fill="#F6F6AF"
        stroke="black"
        strokeWidth="0.123737"
        style={{ transform: [{ scale: sparkleFourScale }], opacity: sparkleFourOpacity, transformOrigin: 'center' }}
      />
    </Svg>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
  svg: {
    marginRight: 6,
  },
});

export default Sparkles;
