import { reactiveVarClient } from '@Thread-Magic/thread-service-utils';
import { createRef } from 'react';
import { CORE_THREAD_FIELDS } from 'src/graphql/constants';
import { isWeb } from 'src/utils';
import { normalizeToGraphqlFragment } from 'src/utils/normalizer';

export const chatboxRef = createRef();

export const scrollBottom = () => {
  if (!chatboxRef.current) return;
  if (isWeb()) {
    chatboxRef.current?.scrollTo({
      top: chatboxRef.current.scrollHeight || 0,
      left: 0,
      behavior: 'smooth',
    });
  } else {
    chatboxRef.current?.scrollToEnd({ duration: 100, animated: true });
  }
};

export const createThreadFragment = (ticket) => {
  const apolloClient = reactiveVarClient();
  const normalizedFragmentData = normalizeToGraphqlFragment(ticket);
  
  const reference = apolloClient.writeFragment({
    fragment: CORE_THREAD_FIELDS(),
    data: normalizedFragmentData,
  });

  return reference;
};
