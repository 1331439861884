/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import { NavigationContainer, useLinking } from '@react-navigation/native';
import { navigationRef } from 'src/utils/navigation';
import SplashScreen from 'react-native-splash-screen';
import useTheme from 'src/hooks/useTheme';
import useProfile from 'src/hooks/useProfile';
import { chatgenieAPI } from 'src/lib/api/chatgenieApi';
import { getCompanyColors, isElectron } from 'src/utils';
import { useDispatchMapper } from 'src/hooks/actionHooks';
import { getCompanyDesign } from 'src/utils/normalizer';
import useInitialUrl from 'src/hooks/useInitialUrl';
import { updateProfileAction } from 'src/redux/profile/profileActions';
import { saveNotificationPermissionAction } from 'src/redux/notifications/actions';
import { StatusBar } from 'react-native';
import { routesConfig } from './routes';
import MainStack from './MainStack';
import AuthStack from './AuthStack';
import { updateAuthAction } from 'src/redux/auth/actions';
import { APP_NAVIGATOR_STATES, userFlows } from 'src/constants';
import { useQueryValues as getParsedUrlQuery } from 'src/hooks/useQueryValues';

const useCustomLinking = (...args) => {
  if (isElectron()) {
    return {
      getInitialState: async () => null,
    };
  }
  return useLinking(...args);
};

const AppNavigator = () => {
  const { theme, isDarkMode } = useTheme();
  const { url, processing } = useInitialUrl();
  const [fetchedInfo, setFetchedInfo] = useState(false);
  const saveProfile = useDispatchMapper(updateProfileAction);
  const updateAuthState = useDispatchMapper(updateAuthAction);
  const saveNotificationPermission = useDispatchMapper(saveNotificationPermissionAction);
  const [show, setShow] = useState(false);
  const { companyInfo, loggedIn } = useProfile();

  const getAppId = async () => {
    let appId;

    if (isElectron()) {
      appId = await window?.ipc?.invoke('get-appid');
    }
    if (url || window?.location?.href) {
      const query = getParsedUrlQuery(url);

      if (query.appId) {
        appId = query.appId;
      } else if (query.state?.includes('appId')) {
        appId = JSON.parse(query.state)?.appId;
      }
    }

    // TODO: remove in the next release
    // This check is needed for Mac installs
    // bcs in the past we used to clear everything from electron-store on logout
    // store.get('APP_ID') may be null, but redux-persist can have companyInfo.appId
    if (!appId && companyInfo?.appId) {
      appId = companyInfo.appId;

      window?.ipc?.send('store-data', {
        key: 'APP_ID',
        value: companyInfo.appId,
      });
    }

    if (!appId) {
      // if appId isn't provided <NoAppId> screen will be shown
      saveProfile({ appNavigatorState: APP_NAVIGATOR_STATES.hasNoAppId });
    }

    return appId;
  };

  const handleAppId = async () => {
    const appId = await getAppId();
    const { notificationPermission } = getParsedUrlQuery(url);

    if (notificationPermission) {
      saveNotificationPermission(notificationPermission);
    }

    let info;

    if (appId && !companyInfo?.id) {
      try {
        saveProfile({
          companyBasicInfoLoading: true,
          companyInfo: { appId },
          appNavigatorState: APP_NAVIGATOR_STATES.hasAppId,
        });

        const res = await chatgenieAPI.getCompanyBasicInfo(appId);
        const data = res.data?.data || {};
        const normalized = getCompanyDesign(data);
        info = { ...companyInfo, ...normalized, appId, availability: data.availability };

        if (!data.sales_flow_enabled) {
          updateAuthState({
            flow: userFlows.EXISTING_USER,
            flowViaLink: true,
          });
        }
      } catch (err) {
        console.log(err);
      } finally {
        if (!info) {
          info = { ...companyInfo, ...getCompanyColors(), appId };
        }
        saveProfile({
          parentCompanyInfo: info,
          companyInfo: info,
          companyBasicInfoLoading: false,
        });
        if (isElectron()) {
          window?.ipc?.send('store-data', {
            key: 'companyInfo',
            value: info,
          });
        }
      }
    }
  };

  useEffect(() => {
    if (!processing && !fetchedInfo) {
      handleAppId();
      setFetchedInfo(true);
    }
  }, [url, companyInfo, processing, fetchedInfo]);

  useEffect(() => {
    if (theme.primaryColor && theme.secondaryColor && processing && !show) {
      SplashScreen?.hide();
      setShow(true);
    }
  }, [theme, processing]);

  const { getInitialState } = useCustomLinking(navigationRef, {
    // prefixes: ['https://chatgenie.netlify.app'],
    config: routesConfig,
  });

  const [isReady, setIsReady] = React.useState(false);
  const [initialState, setInitialState] = React.useState();

  React.useEffect(() => {
    getInitialState()
      .catch(() => {})
      .then((state) => {
        if (state !== undefined) {
          setInitialState(state);
        }

        setIsReady(true);
      });
  }, [getInitialState]);

  if (!show || !isReady) return null;

  return (
    <NavigationContainer initialState={initialState} ref={navigationRef}>
      <StatusBar backgroundColor={theme.foreground} barStyle={isDarkMode ? 'light-content' : 'dark-content'} />
      {loggedIn ? <MainStack /> : <AuthStack />}
    </NavigationContainer>
  );
};

export default AppNavigator;
