import { useCallback, useRef } from 'react';


const sleep = (timeout) =>
  new Promise(resolve => {
    setTimeout(resolve, timeout);
  });

const useTypewriter = (options) => {
  const shouldStop = useRef(false);

  const start = useCallback(
    async (data) => {
      shouldStop.current = false;
      const prefix = options.prefix || '';
      const suffix = options.suffix || '';
      let onTypeMeta = null;
      const { throttledCharCount = 10 } = options;
      const firstThrottledCharCount = Math.min(data.length, throttledCharCount);

      // inserting first k chars with animation (k = throttledCharCount)
      for (let i = 0; i < firstThrottledCharCount; i++) {
        // eslint-disable-next-line no-await-in-loop
        await sleep(options.timeout);
        if (shouldStop.current) {
          options.onFinish(onTypeMeta);
          return;
        }
        const subset = data.substring(0, i + 1);
        onTypeMeta = options.onType(`${prefix}${subset}${suffix}`);
      }

      // inserting n chars in the middle without animation - k-chars....n-chars...k-chars
      const leftOverCount = data.length - firstThrottledCharCount;
      if (leftOverCount > throttledCharCount) {
        const subset = data.slice(0, data.length - throttledCharCount);
        onTypeMeta = options.onType(`${prefix}${subset}${suffix}`);
      }

      const insertStartIndex =
        data.length - firstThrottledCharCount > throttledCharCount
          ? data.length - throttledCharCount
          : firstThrottledCharCount + 1;
      for (let i = insertStartIndex; i < data.length; i++) {
        await sleep(options.timeout);
        if (shouldStop.current) {
          options.onFinish(onTypeMeta);
          return;
        }
        const subset = data.slice(0, i + 1);
        onTypeMeta = options.onType(`${prefix}${subset}${suffix}`);
      }
      onTypeMeta = options.onType(`${prefix}${data}${suffix}\n`);
      options.onFinish(onTypeMeta);
    },
    [options]
  );

  const stop = useCallback(() => {
    shouldStop.current = true;
  }, []);
  return {
    start,
    stop
  };
};

export default useTypewriter;
