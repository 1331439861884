import { StyleSheet } from 'react-native';

const styles = StyleSheet.create({
  wrapper: {
    height: 170,
    width: '100%',
  },
  sectionTitle: {
    marginLeft: 15,
    fontWeight: '800',
    marginTop: 15,
  },
  scrollview: {
    flexGrow: 1,
    paddingVertical: 5,
  },
  scrollviewContainer: {
    flexGrow: 1,
  },
});

export default styles;
