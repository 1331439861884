import React from 'react';
import { ApolloClientProvider } from '@Thread-Magic/thread-service-utils';
import { captureException } from '@sentry/react-native';
import useProfile from 'src/hooks/useProfile';
import Config from 'react-native-config';
import { refreshAccessToken } from 'src/lib/api/chatgenieApi';
import { cache } from './cache';

export const withApolloProvider = (Component) => (props) => {
  const { companyInfo, token } = useProfile();
  const appId = companyInfo.appId;

  const handleGraphQLError = ({ message, ...rest }) => {
    captureException(new Error(message), { extra: { ...rest } });
  };

  const handleNetworkError = ({ message, operation, authParams }) => {
    // cannot access status code directly, so checking message
    const unAuthorized = message?.includes('status code 401');

    // authParams contains app-id and authentication token length
    // e.g. authParams: {appId: "appId", token: 128}
    // can't use prefix "auth" since Sentry scrubber cleans it up
    if (unAuthorized || !authParams?.token) {
      refreshAccessToken();
      return;
    }

    captureException(new Error(message), { extra: { operation, userParams: authParams } });
  };

  const handleRetryError = ({ message, operationName, variables }) => {
    captureException(new Error(message), {
      extra: { operationName, variables },
    });
  };

  const handleLazyError = ({ message, tags }) => {
    captureException(new Error(message), { tags });
  };

  return (
    <ApolloClientProvider
      uri={Config.REACT_APP_GRAPHQL_QUERY_ENDPOINT}
      subscriptionUri={Config.REACT_APP_GRAPHQL_SUBSCRIPTION_ENDPOINT}
      enabled={true}
      appId={appId}
      cache={cache}
      token={token}
      children={<Component {...props} />}
      connectToDevTools={true}
      isMessenger={true}
      onGraphQLError={handleGraphQLError}
      onNetworkError={handleNetworkError}
      onRetryError={handleRetryError}
      onNonLazyError={handleLazyError}
    />
  );
};
