import { colors, fonts } from 'src/constants/theme';
import { isWeb } from 'src/utils';
import { responsiveWidth } from 'src/utils/responsive';

export const headerStyles = {
  wrapper: {
    display: 'flex',
    flexDirection: 'row',
  },
  participantsList: {
    width: 50,
  },
  participant: {
    position: 'absolute',
    borderRadius: '50%',
    border: '1px solid #fff',
  },
  details: {
    maxWidth: '95%',
  },
  summary: {
    fontFamily: fonts.bold,
    marginBottom: 3,
  },
  generatePlaceholder: {
    display: "flex",
    background: 'transparent',
    height: 17,
    marginBottom: 3,
    flexDirection: "row",
    alignItems: "center",
  },
  generatePlaceholderText: {
    color: "#999",
    fontSize: 12,
    fontStyle: "italic",
  }
};

export const getParticipantListStyles = ({ participantsCount }) => {
  let width = 50;
  if (participantsCount > 1) width = 55;
  if (participantsCount > 2) width = 65;

  return { ...headerStyles.participantsList, width };
};

export const getParticipantStyles = ({ offset, zIndex }) => {
  return { ...headerStyles.participant, left: offset * 8, zIndex };
};
