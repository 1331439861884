import { colors } from 'src/constants/theme';

export const styles = {
  container: {
    position: 'relative',
    backgroundColor: colors.white,
  },
  stepIndicator: {
    marginVertical: 10,
    backgroundColor: colors.white,
  },
  avatarContainer: {
    display: 'flex',
    alignItems: 'center',
    position: 'absolute',
    top: 0,
    left: '50%',
    marginLeft: -32,
    marginTop: -32,
  },
  details: {
    flex: 1,
    display: 'flex',
    justifyContent: 'center',
  },
  title: {
    fontSize: 16,
    fontWeight: '600',
    textAlign: 'center',
  },
  desc: {
    fontSize: 14,
    fontWeight: '400',
    marginTop: 7,
    textAlign: 'center',
  },
  indicatorContainer: {
    marginHorizontal: 16,
    overflow: 'hidden',
  },
  stepIndicatorContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    position: 'relative',
  },
  step: {
    alignItems: 'center',
    justifyContent: 'center',
    zIndex: 2,
  },
  stepContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    outlineColor: colors.white,
    outlineStyle: 'solid',
    outlineWidth: 3,
  },
};
