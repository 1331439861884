import Ionicons from 'react-native-vector-icons/Fonts/Ionicons.ttf';
import Feather from 'react-native-vector-icons/Fonts/Feather.ttf';
import FontAwesome from 'react-native-vector-icons/Fonts/FontAwesome.ttf';
import MaterialCommunityIcons from 'react-native-vector-icons/Fonts/MaterialCommunityIcons.ttf';
import EvilIcons from 'react-native-vector-icons/Fonts/EvilIcons.ttf';

export const injectStyles = (str) => {
  const style = document.createElement('style');
  style.type = 'text/css';
  if (style.styleSheet) {
    style.styleSheet.cssText = str;
  } else {
    style.appendChild(document.createTextNode(str));
  }

  // Inject stylesheet
  document.head.appendChild(style);
};

export const injectDefaultStyles = () => {
  const iconFontStyles = `
  @font-face {
    src: url(${Ionicons});
    font-family: Ionicons;
  }
  @font-face {
    src: url(${Feather});
    font-family: Feather;
  }
  @font-face {
    src: url(${FontAwesome});
    font-family: FontAwesome;
  }
  @font-face {
    src: url(${MaterialCommunityIcons});
    font-family: MaterialCommunityIcons;
  }
  @font-face {
    src: url(${EvilIcons});
    font-family: EvilIcons;
  }
  @font-face {
    src: url('fonts/Inter-Regular.woff2') format('woff2'),
      url('fonts/Inter-Regular.woff') format('woff'),
      url('fonts/Inter-Regular.ttf') format('truetype');
    font-family: Inter-Regular;
    font-display: swap;
  }
  @font-face {
    src: url('fonts/Inter-Bold.woff2') format('woff2'),
      url('fonts/Inter-Bold.woff') format('woff'),
      url('fonts/Inter-Bold.ttf') format('truetype');
    font-family: Inter-Bold;
    font-display: swap;
  }
  @font-face {
    src: url('fonts/Inter-SemiBold.woff2') format('woff2'),
      url('fonts/Inter-SemiBold.woff') format('woff'),
      url('fonts/Inter-SemiBold.ttf') format('truetype');
    font-family: Inter-SemiBold;
    font-display: swap;
  }
  
  body {
    font-family: 'Inter-Regular', Arial, Helvetica, sans-serif;
  }
`;

  injectStyles(iconFontStyles);
};

export const isElectron = () => window?.navigator?.userAgent?.toLowerCase()?.includes('electron');

export const isProduction = () => process.env.REACT_APP_ENV === 'production';
export const isDevelopment = () => process.env.REACT_APP_ENV === 'development';

export const isPWAApp = () => process.env.REACT_APP_TYPE === 'pwa';

export function parseJwt(token) {
  const base64Url = token.split('.')[1];
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  const jsonPayload = decodeURIComponent(
    window
      .atob(base64)
      .split('')
      .map((c) => {
        return `%${`00${c.charCodeAt(0).toString(16)}`.slice(-2)}`;
      })
      .join(''),
  );

  return JSON.parse(jsonPayload);
}
