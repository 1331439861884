import actionTypes from 'src/constants/actionTypes';

export const updateProfileAction = (payload) => ({
  type: actionTypes.UPDATE_PROFILE,
  payload,
});

export const updateSoundPreferenceAction = (payload) => ({
  type: actionTypes.UPDATE_PROFILE_SOUND_PREFERENCE,
  payload,
});

export const updateProfileOnboardingAction = (payload) => ({
  type: actionTypes.UPDATE_PROFILE_ONBOARDING,
  payload,
});
