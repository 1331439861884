import React from 'react';
import PropTypes from 'prop-types';
import { TouchableOpacity } from 'react-native';
import navigationService from 'src/utils/navigation';
import routeNames from 'src/constants/routeNames';
import useChatState from 'src/screens/Chat/hooks/useChatState';
import { CHAT_NEW_TICKET_STATES } from 'src/redux/chat/chatStateConstants';

const getBackButtonStyles = ({ isDisabled }) => ({
  height: 50,
  width: 40,
  alignItems: 'center',
  justifyContent: 'center',
  marginLeft: 0,
  cursor: isDisabled ? 'not-allowed' : 'pointer',
  opacity: isDisabled ? 0.5 : 1,
});

const GoBackButton = ({ canGoBack, onPress, backImage }) => {
  const pathName = window.location.pathname;
  const { chatState } = useChatState();
  const isNewTicketBeingCreated = chatState.newTicketCreationState === CHAT_NEW_TICKET_STATES.CREATING;
  const defaultProps = {
    style: getBackButtonStyles({ isDisabled: isNewTicketBeingCreated }),
    testID: 'go-back-button',
  };

  if (!canGoBack) {
    if (pathName === '/chat') {
      return (
        <TouchableOpacity {...defaultProps} onPress={() => navigationService.navigate(routeNames.NOW)}>
          {backImage()}
        </TouchableOpacity>
      );
    }

    return null;
  }

  return (
    <TouchableOpacity {...defaultProps} onPress={onPress} disabled={isNewTicketBeingCreated}>
      {backImage()}
    </TouchableOpacity>
  );
};

GoBackButton.defaultProps = {
  canGoBack: false,
  backImage: () => {},
  onPress: () => {},
};

GoBackButton.propTypes = {
  canGoBack: PropTypes.bool,
  onPress: PropTypes.func,
  backImage: PropTypes.func,
};

export default GoBackButton;
