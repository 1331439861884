import React, { useState } from 'react';
import { app } from '@microsoft/teams-js';
import * as Sentry from '@sentry/react-native';
import Config from 'react-native-config';
import Axios from 'axios';
import Spinner from './Spinner';

const MicrosoftIcon = () => (
  <svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="19" height="19" fill="#F3F3F3" />
    <path d="M9.07795 9.07795H1V1H9.07795V9.07795Z" fill="#F1511B" />
    <path d="M17.9967 9.07795H9.91895V1H17.9967V9.07795Z" fill="#80CC28" />
    <path d="M9.07764 17.9999H1V9.92212H9.07764V17.9999Z" fill="#00ADEF" />
    <path d="M17.9967 17.9999H9.91895V9.92212H17.9967V17.9999Z" fill="#FBBC09" />
  </svg>
);

const WarningIcon = () => (
  <svg width="67" height="67" viewBox="0 0 67 67" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="33.5" cy="33.5" r="33.5" fill="#FCECD6" />
    <path
      d="M31.0064 19.6295L18.6543 40.2503C18.3996 40.6913 18.2649 41.1914 18.2634 41.7006C18.262 42.2099 18.394 42.7107 18.6462 43.1531C18.8984 43.5956 19.262 43.9643 19.7009 44.2226C20.1399 44.4809 20.6388 44.6197 21.148 44.6253H45.8522C46.3615 44.6197 46.8604 44.4809 47.2993 44.2226C47.7382 43.9643 48.1019 43.5956 48.3541 43.1531C48.6063 42.7107 48.7382 42.2099 48.7368 41.7006C48.7354 41.1914 48.6006 40.6913 48.346 40.2503L35.9939 19.6295C35.7339 19.2009 35.3678 18.8465 34.931 18.6006C34.4942 18.3547 34.0014 18.2255 33.5001 18.2255C32.9988 18.2255 32.506 18.3547 32.0692 18.6006C31.6324 18.8465 31.2664 19.2009 31.0064 19.6295V19.6295Z"
      stroke="#AA967A"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path d="M33.5 27.125V32.9583" stroke="#AA967A" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
    <path d="M33.5 38.7917H33.5146" stroke="#AA967A" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
  </svg>
);

const FallbackError = ({ onClickSignin }) => {
  const [fetchState, setFetchState] = useState('initial');
  const [error, setError] = useState(null);

  const createMsTeamsIssue = () => {
    setFetchState('fetching');

    app.getContext().then((context) => {
      Axios({
        method: 'POST',
        url: `${Config.MS_TEAMS_BASE_URL}/ms-teams-issue`,
        headers: { 'Content-Type': 'application/json' },
        data: {
          teamsUserEmail: context.user.loginHint,
          teamsTenantId: context.user.tenant.id,
        },
      })
        .then(() => {
          setFetchState('success');
        })
        .catch((error) => {
          setFetchState('error');
          setError(error);
          Sentry.captureException(error, {
            tags: {
              feature: 'landing',
              teamsUserEmail: context.user.loginHint,
              teamsTenantId: context.user.tenant.id,
            },
          });
        });
    });
  };

  return (
    <div className="fallback-error">
      <WarningIcon />
      <h5>Seems like we’re having trouble logging you in...</h5>
      <div className="fallback-error-column">
        <div>
          <img src="/images/teams-auth-failure3.png" alt="Signin popup" />
          <div>
            <p>Please try again by clicking the button below. This will launch a pop-up, please do not close it.</p>
            <button onClick={onClickSignin}>
              <MicrosoftIcon />
              Retry Sign In
            </button>
          </div>
        </div>
        <div>
          <img src="/images/teams-auth-failure1.png" alt="Pop out app" />
          <div>
            <p>You can also try right clicking the app icon in the navigation and click Pop out app to try again.</p>
          </div>
        </div>
      </div>
      {fetchState === 'fetching' && <Spinner />}
      {fetchState === 'success' && (
        <span className="teams-issue-succes-message">
          We've let your IT provider know you are having an issue. They will reach out to you for next steps.
        </span>
      )}
      {fetchState === 'initial' && (
        <button className="simple-button" onClick={createMsTeamsIssue}>
          Still having problems?
        </button>
      )}
      {fetchState === 'error' && <span className="teams-issue-error-message">{error?.message}</span>}
    </div>
  );
};
const errorContents = {
  CancelledByUser: {
    img: '/images/teams-auth-failure2.png',
    title: 'Oops, couldn’t log you in.',
    desc: 'Looks like we couldn’t log you in. Please try again by clicking the button below. This will launch a pop-up, please do not close it.',
  },
  FailedToOpenWindow: {
    img: '/images/teams-auth-failure1.png',
    title: 'Oops, couldn’t log you in.',
    desc: (
      <>
        We weren’t able to log you in. Please right click the app and click <b>Pop out app</b> to try again.
      </>
    ),
  },
};

export default function AuthError({ message, onClickSignin }) {
  const errorContent = errorContents[message];

  return (
    <div className="auth-error">
      <div className="manual-signin">
        {errorContent ? (
          <>
            <img src={errorContent.img} alt="Error" />
            <h5>{errorContent.title}</h5>
            <p>{errorContent.desc}</p>
            <button onClick={onClickSignin}>
              <MicrosoftIcon />
              Sign in with Microsoft
            </button>
          </>
        ) : (
          <FallbackError onClickSignin={onClickSignin} />
        )}
      </div>
    </div>
  );
}
