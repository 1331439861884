import { StyleSheet } from 'react-native';

const styles = StyleSheet.create({
  list: {
    flexGrow: 1,
    backgroundColor: 'transparent',
  },
  listContainer: {
    flexGrow: 1,
    paddingVertical: 20,
    paddingBottom: 30,
  },
});

export const stylesWeb = {
  scrollerWrapperWeb: {
    flexGrow: 1,
    flexShrink: 1,
    overflow: 'auto',
    display: 'flex',
    flexDirection: 'column-reverse',
    paddingBottom: 24,
  },
  scrollerWeb: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    paddingTop: 20,
  },
  spinnerWeb: {
    marginBottom: 10,
  },
};

export default styles;
