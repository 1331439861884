import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import Common from 'src/components/Common';
import { normalizeTicket } from 'src/utils/normalizer';
import routeNames from 'src/constants/routeNames';
import navigationService from 'src/utils/navigation';
import { formatDate } from 'src/utils/date';
import { useDispatchMapper } from 'src/hooks/actionHooks';
import { setActiveTicketAction } from 'src/redux/chat/chatActions';
import { useSelector } from 'react-redux';
import { removeTicketNotificationAction } from 'src/redux/notifications/actions';
import styles, { getCardStyles } from './style';
import useTranslation from 'src/hooks/useTranslation';
import { formatToFirstName, getTicketParticipants, replaceMentionTags } from 'src/utils/mention.utils';
import ParticipantsGroup from 'src/screens/Now/components/ServicesList/components/ThreadServiceCard/ParticipantsGroup';
import { THREAD_STATES } from 'src/constants';
import UserProfilePicture from 'src/components/Common/UserProfilePicture';

const ThreadServiceCard = ({ item }) => {
  const ticketData = useMemo(() => normalizeTicket(item), [item]);
  const saveActiveTicket = useDispatchMapper(setActiveTicketAction);
  const ticketNotifications = useSelector((store) => store.notifications.tickets);
  const removeTicketNotification = useDispatchMapper(removeTicketNotificationAction);
  const { translate } = useTranslation();

  const onClickTicket = () => {
    saveActiveTicket(ticketData);
    removeTicketNotification(ticketData.id);
    navigationService.navigate(routeNames.CHAT, { ticket: ticketData });
  };

  const lastNoteTxt = useMemo(() => {
    const txt = ticketData.lastNote?.text || '';

    const imageFilePattern = /\[.*?\]\(https?:\/\/[^\s)]+/g;
    const attachmentTxt = translate('common.text.attachment');
    if (imageFilePattern.test(txt)) {
      return attachmentTxt;
    }

    return replaceMentionTags(txt);
  }, [ticketData.lastNote]);

  const lastNoteSender = formatToFirstName(ticketData.lastNote?.sender_fullname);
  const isLiveThread = ticketData.state === THREAD_STATES.LIVE;
  const ticketParticipants = getTicketParticipants(ticketData);

  return (
    <Common.Card style={getCardStyles(ticketData.priority?.color)}>
      <Common.TouchableOpacity activeOpacity={0.7} testID="service-card" onPress={onClickTicket}>
        <Common.View transparent style={styles.cardDetailsWrapper}>
          <Common.View transparent style={styles.cardDetailsContact}>
            <UserProfilePicture size={35} url={ticketData.contact?.profile_image?.path} alt={ticketData.contactName} />
            {isLiveThread && <Common.Text style={styles.cardUserLiveStatus}>LIVE</Common.Text>}
          </Common.View>

          <Common.View transparent style={styles.cardBodyWrapper}>
            <Common.View style={styles.cardBody} transparent>
              <Common.View style={styles.cardBodyLeft} transparent>
                <Common.View transparent>
                  <Common.Text style={styles.cardContact} numberOfLines={1} size={15}>
                    {ticketData.contactName}
                  </Common.Text>
                </Common.View>
                <Common.View transparent>
                  <Common.Text style={styles.cardSummary} numberOfLines={1} size={14}>
                    {ticketData.summary}
                  </Common.Text>
                </Common.View>
                <Common.View transparent style={styles.cardLastNote}>
                  {ticketNotifications?.[ticketData.id] && <Common.View style={styles.unreadIndicator} />}
                  <Common.Text numberOfLines={1} size={13}>
                    {lastNoteSender} {lastNoteTxt}
                  </Common.Text>
                </Common.View>
              </Common.View>

              <Common.View style={styles.cardBodyRight} transparent>
                <Common.Text style={styles.cardDate} size={13}>
                  {formatDate(ticketData.createdAt, 'MM/dd/yyyy')}
                </Common.Text>
                <Common.Text style={styles.cardSystemId} size={13}>
                  #{ticketData.number}
                </Common.Text>
              </Common.View>
            </Common.View>

            <Common.View style={styles.cardFooter} transparent>
              <Common.View transparent style={styles.cardStatusWrapper}>
                <Common.Text style={styles.cardStatus} numberOfLines={1}>
                  {ticketData.statusObject?.name || ticketData.status}
                </Common.Text>
              </Common.View>
              <ParticipantsGroup hasTicketAssignee={!!ticketData.assigneeId} ticketParticipants={ticketParticipants} />
            </Common.View>
          </Common.View>
        </Common.View>
      </Common.TouchableOpacity>
    </Common.Card>
  );
};

ThreadServiceCard.propTypes = {
  item: PropTypes.object.isRequired,
};

export default React.memo(ThreadServiceCard);
