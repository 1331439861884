import React from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'react-native';
import Common from 'src/components/Common';
import { alertCardColors, colors } from 'src/constants/theme';
import styles from './style';

const AlertModal = ({ visible, alert, onClose }) => (
  <Modal
    visible={visible}
    animationType="fade"
    transparent
    onRequestClose={onClose}
    testID="alert-modal"
  >
    <Common.TouchableOpacity
      activeOpacity={1}
      style={styles.cardDimOverlay}
      onPressIn={onClose}
      testID="alert-modal-overlay"
    >
      <Common.View
        style={[
          styles.card,
          {
            backgroundColor: alertCardColors?.[alert?.type],
          },
        ]}
      >
        <Common.ScrollView
          style={styles.scrollview}
          contentContainerStyle={styles.scrollviewContainer}
          showsVerticalScrollIndicator={false}
        >
          <Common.View style={styles.content} onStartShouldSetResponder={() => true}>
            <Common.Text
              style={styles.title}
              bold
              testID="alert-modal-title"
            >
              {alert?.title}
            </Common.Text>
            <Common.Text color={colors.white} testID="alert-modal-description">
              {alert?.description}
            </Common.Text>
          </Common.View>
        </Common.ScrollView>
        <Common.Row justifyContent="center" alignItems="center">
          <Common.TouchableOpacity
            style={styles.closeBtn}
            onPress={onClose}
            testID="alert-modal-close"
          >
            <Common.Text color={colors.white} bold size={15}>CLOSE</Common.Text>
          </Common.TouchableOpacity>
        </Common.Row>
      </Common.View>
    </Common.TouchableOpacity>
  </Modal>
);

AlertModal.defaultProps = {
  visible: false,
  alert: null,
};

AlertModal.propTypes = {
  visible: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  alert: PropTypes.object,
};

export default React.memo(AlertModal);
