import actionTypes from 'src/constants/actionTypes';

export const defaultState = {
  email: null,
  connection: 'email',
  phoneNumberTip: null,
  otp: null,
  fullname: null,
  companyName: null,
  flow: null,
  codeSent: false,
  inputConfig: null,
  authed: false,
};

const authReducer = (state = defaultState, action) => {
  switch (action.type) {
    case actionTypes.AUTH_UPDATE:
      return { ...state, ...action.payload };
    default:
      return state;
  }
};

export default authReducer;
