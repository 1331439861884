import { useCallback, useRef } from 'react';
import useProfile from 'src/hooks/useProfile';
import { v4 as uuidv4 } from 'uuid';
import { normalizeTwilioMessage } from 'src/utils/normalizer';
import useChatState from './useChatState';
import { scrollBottom } from '../utils';

const useMessageHandlers = () => {
  const { chatState, saveMessage } = useChatState();
  const chatStateRef = useRef(null);
  chatStateRef.current = chatState;
  const { userInfo } = useProfile();

  const addMessage = useCallback((message) => {
    // eslint-disable-next-line no-param-reassign
    if (!message.timestamp) {
      message.timestamp = new Date();
    }
    if (!message.id) {
      message.id = uuidv4();
    }
    saveMessage(message);
  }, []);

  const addBotMessage = (message) => {
    if (!message) return;
    addMessage({ type: 'text', isMine: false, message });
  };

  const addMyMessage = (message, extra) => {
    if (!message) return;
    const { type, ...rest } = extra || {};
    addMessage({ isMine: true, message, type: type || 'text', ...rest });
  };

  const onTwilioMessage = useCallback(async (newMessage) => {
    const { ticket, chatHistoryUnread } = chatStateRef.current;
    const messageChannelName = newMessage?.conversation?.uniqueName;
    if (messageChannelName !== ticket?.channel) {
      return;
    }

    const convertedMessage = normalizeTwilioMessage(newMessage, userInfo?.contactId);
    if (convertedMessage) {
      addMessage(convertedMessage);
      scrollBottom();
      if (!convertedMessage.isMine && !chatHistoryUnread?.[convertedMessage?.id]) {
        if (ticket?.channel !== messageChannelName) {
          chatHistoryUnread[convertedMessage?.id] = convertedMessage;
        }
      }
    }
  }, []);

  return {
    addBotMessage,
    addMyMessage,
    addMessage,
    onTwilioMessage,
  };
};

export default useMessageHandlers;
