import actionTypes from 'src/constants/actionTypes';

const defaultState = {
  title: 'Title',
  description: null,
  visible: false,
  onOk: () => {},
  onCancel: () => {},
  okText: 'Ok',
  cancelText: 'Cancel',
  type: 'alert',
  backdropClickEnabled: true,
  data: null,
};

const popupReducer = (state = defaultState, action) => {
  switch (action.type) {
    case actionTypes.OPEN_POPUP:
      return { ...state, visible: true, ...action.payload };
    case actionTypes.CLOSE_POPUP:
      return { ...state, visible: false };
    case actionTypes.RESET_POPUP:
      return defaultState;
    default:
      return state;
  }
};

export default popupReducer;
