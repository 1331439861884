import { StyleSheet } from 'react-native';
import { colors } from 'src/constants/theme';

const styles = StyleSheet.create({
  warning: {
    backgroundColor: colors.warning,
    paddingHorizontal: 15,
    minHeight: 42,
    paddingVertical: 10,
    flexWrap: 'wrap',
    alignItems: 'center',
    justifyContent: 'center',
  },
  retry: {
    marginLeft: 10,
    color: colors.darkblue2,
  },
  success: {
    backgroundColor: colors.success,
    height: 42,
    alignItems: 'center',
    justifyContent: 'center',
  },
  text: {
    marginLeft: 10,
  },
  apolloWarningMsg: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
});

export default styles;
