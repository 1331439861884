import React, { memo } from 'react';
import Common, { FlatList } from 'src/components/Common';
import styled from 'styled-components/native';
import reactQueryKeys from 'src/constants/reactQueryKeys';
import { getCompanyContacts } from 'src/lib/api/connectWise';
import useProfile from 'src/hooks/useProfile';
import { useFetch } from 'src/hooks/useFetch';
import UserListItem from './UserListItem';

const UserList = ({ onClickListItem }) => {
  const { companyInfo } = useProfile();
  const {
    data, isLoading, refetch, isFetching,
  } = useFetch([reactQueryKeys.CONTACTS, { companyId: companyInfo?.id }], getCompanyContacts);
  return (
    <StyledFlatList
      contentContainerStyle={{
        paddingBottom: 60,
        flexGrow: 1,
      }}
      ListEmptyComponent={isLoading && (
        <Common.Spinner />
      )}
      refreshing={isFetching}
      onRefresh={refetch}
      data={data || []}
      renderItem={({ item, index }) => (
        <UserListItem
          item={item}
          index={index}
          onPress={() => onClickListItem(item)}
        />
      )}
      keyExtractor={(item) => item.id?.toString()}
    />
  );
};

UserList.defaultProps = {
  listHeader: null,
  onClickListItem: () => {},
};

export default memo(UserList);

const StyledFlatList = styled(FlatList)`
  flex-grow: 1;
  flex-basis: 1;
  margin: 0px;
  padding: 0px;
`;
