import { StyleSheet } from 'react-native';
import { responsiveHeight } from 'src/utils/responsive';

export const styles = StyleSheet.create({
  modalWrapper: {
    flexGrow: 1,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'rgba(0,0,0,0.6)',
    margin: 0,
    padding: 0,
    height: '100%',
    overflow: 'hidden',
  },
  imageWrapper: {
    width: '70%',
    height: responsiveHeight(40),
  },
  image: {
    resizeMode: 'contain',
    width: '100%',
    height: '100%',
  },
  btnClose: {
    position: 'absolute',
    top: -30,
    right: -30,
  },
  btnCloseTxt: { color: '#fff', fontSize: 32 },
  downloadWrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'relative',
    bottom: 20
  },
  btnDownload: {
    color: '#fff',
    backgroundColor: 'rgba(0, 0, 0, 0.2)',
    borderRadius: 30,
  },
});
