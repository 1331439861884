const { StyleSheet } = require('react-native');

const styles = StyleSheet.create({
  listItem: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingHorizontal: 30,
    height: 77,
    borderBottomColor: '#C4C4C4',
    borderBottomWidth: 1,
  },
  list: {
    flexGrow: 1,
    flexBasis: 1,
  },
  listContainer: {
    paddingBottom: 60,
    flexGrow: 1,
  },
  logoutBtn: {
    paddingVertical: 10,
    paddingHorizontal: 15,
  },
});

export default styles;
