const routeNames = {
  AUTH: 'Auth',
  TABS: 'Tabs',
  NOW: 'Now',
  ACCOUNT: 'Account',
  SCAN_QR: 'ScanQR',
  MAIN: 'Main',
  APPLOADING: 'AppLoading',
  SELECT_DEVICE: 'Devices',
  SELECT_COMPANY: 'Select company',
  SELECT_USER: 'Contacts',
  DEVICES: 'Devices',
  SINGLE_DEVICE: 'Device Details',
  NEW_REQUEST: 'New request',
  MFA_LIST: 'MFA list',
  CHAT: 'New chat',
};

export default routeNames;
