import {
  ScrollView, TouchableOpacity, FlatList, Image, Pressable,
} from 'react-native';
import View from './View';
import Text from './Text';
import Row from './Row';
import Card from './Card';
import Avatar from './Avatar';
import Divider from './Divider';
import Input from './Input';
import Spinner from './Spinner';

export * from './Icon';

export {
  View,
  Text,
  Row,
  ScrollView,
  Avatar,
  TouchableOpacity,
  FlatList,
  Card,
  Divider,
};

const Common = {
  Text,
  Row,
  Card,
  Divider,
  Input,
  Spinner,
  Avatar,
  Pressable,
  View,
  FlatList,
  ScrollView,
  TouchableOpacity,
  Image,
};

export default Common;
