import { getTimeText } from 'src/utils';

/**
 *
 * To ensure consistency, translations must be in the same order
 * This way we can make sure that no translation is missed
 */
export const UITranslations = {
  en: {
    'header.status.active': 'Active',
    'header.status.done': 'Done',
    'header.greet': (firstName) => `Hi ${firstName}`,
    'header.notYou': 'Not you?',
    'header.text.people': 'People',

    'liveStep.inactive.default.title': 'Request received',
    'liveStep.inactive.default.desc': 'Looking for someone to assign (Est. 5 min)',
    'liveStep.active.getAssignee.title': (assignee) => `${assignee} has been assigned`,
    'liveStep.active.getAssignee.desc': (assignee) => `${assignee} will be with you in about 3 min`,
    'liveStep.active.getAssignee.isHere': (assignee) => `${assignee} is here for you!`,
    'liveStep.active.getAssignee.request.desc': 'And will be with you soon.',

    'ticket.liveChat.devices.none': 'None of the above',
    'ticket.liveChat.text': 'New Chat',
    'ticket.liveChat.messagePlaceholder': 'Type your message...',
    'ticket.chatBox.optionPlaceholder': 'Choose one of the options',
    'ticket.newRequest.text': 'New Request',

    'account.title': 'Account',
    'account.integration.title': 'Integrations',
    'account.integration.connected': (isConnected) => `Connect${isConnected ? 'ed' : ''} with`,
    'account.notification.title': 'Notifications',
    'account.notification.permission': 'Please enable via the browser settings.',
    'account.notification.enable': 'Enable notifications to receive updates on your threads.',
    'account.sound.title': 'Sounds',
    'account.sound.playSoundOnNewMessage': 'Play sound when there’s a new message',
    'account.sound.playSoundOnNewThread': 'Play sound when there’s a new thread',
    'account.device.title': 'Devices',
    'account.device.notFound': 'No devices registered. Good things take time.',
    'account.auth.logOut': 'Log Out',
    'account.auth.logOut.loading': 'Logging Out...',
    'account.info.email': 'You are logged in with:',
    'account.info.workspace': 'Into the workspace:',
    'account.app.title': 'App id',
    'account.profilePic.remove': 'Remove photo',

    'common.connection.reconnected': 'Reconnected',
    'common.connection.warning': 'Network connection lost or errored',
    'common.connection.text.retry': 'Retry',
    'common.text.send': 'Send',
    'common.text.confirm': 'Confirm',
    'common.text.cancel': 'Cancel',
    'common.text.unknown': 'Unknown',
    'common.text.noAlert': 'No alerts here.',
    'common.text.noCompanies': 'No companies found',
    'common.text.yes': 'Yes',
    'common.text.no': 'No',
    'common.text.email': 'Email',
    'common.text': 'Text',
    'common.text.create': 'Create',
    'common.text.creating': 'Creating...',
    'common.text.status': 'Status',
    'common.text.newChat': 'New chat',
    'common.text.selectCompany': 'Select company',
    'common.text.greeting': 'Hi',
    'common.text.download': 'Download',
    'common.text.newTicket': 'New Ticket',
    'common.text.from': 'from',
    'common.text.attachment': 'Attachment',
    'common.text.continue': 'Continue',
    'common.text.skip': 'Skip',
    'common.files.drag': 'Drag file here',
    'common.state.connecting': 'Connecting...',
    'common.alert.wrong': 'Something went wrong while initializing client',

    'empty.state.title': 'No threads created yet.',
    'empty.state.description': "If you're waiting for a sign, this is it.",
    'empty.state.chatDescription': 'Chat with your support team in real-time.',
    'empty.state.requestDescription': 'Create a new request and follow along with progress.',

    'time.morning': 'Good morning',
    'time.afternoon': 'Good afternoon',
    'time.evening': 'Good evening',

    'newRequest.device': 'Device',
    'newRequest.device.select': 'Select Device (Optional)',
    'newRequest.contact': 'Contact',
    'newRequest.contact.select': 'Select Contact',
    'newRequest.placeholder.summary': 'Summary',
    'newRequest.placeholder.info': 'Some information about this service request',
    'newRequest.error.fillDescription': 'Fill in the description, please!',

    'quickReply.newUser.chat': 'Chat with the team now',
    'quickReply.newUser.reach': 'Have someone reach out later',
    'quickReply.user.flow.new': "I'm new and I have a question",
    'quickReply.user.flow.existing': "I'm already a customer",
    'quickReply.expiredThread.createNew': "No, It's something else",
    'quickReply.expiredThread.continueCurrent': 'Yes, continue this thread',
    'quickReply.authenticationFlow.restart': 'Restart from the beginning',
    'quickReply.authenticationFlow.resendCode': 'Send a new verification code',

    'bot.getGreetingMessage': (locale) =>
      `${getTimeText(locale)}. Can you tell me in a few words what we can help with?`,
    'bot.getOfflineMessage': (locale) => `${getTimeText(locale)}, our support team is currently offline.`,
    'bot.getOfflineMessage2': `You can still create a thread by describing your issue and someone from the team will reach out.`,
    'bot.authGreeting': 'Hi there! Welcome to our chat support! How can we help you today?',
    'bot.askEmailExistingUser': 'Great! What is your **email address**?',
    'bot.askEmailExistingUser2': 'Hi there! Enter your **email address** to get started.',
    'bot.askEmailNewUser': "Let's get started! What is your **email address**?",
    'bot.askFullnameNewUser': 'Great! What is your **full name**?',
    'bot.askCompanyNewUser': "What is your **company's name**?",
    'bot.askFullnameExistingCompany': 'Looks like its your first time here! What’s your first & last name?',
    'bot.silentAuthGreeting': (companyName) => `Hi there! Welcome to ${companyName}!`,
    'bot.getEmailConfirmation': (email) => `We found your email ${email}, is that correct?`,
    'bot.askEmail': 'What is your email address?',
    'bot.invalidEmail': "Let's try that again. Please enter a **valid email address** (ex: name@company.com).",
    'bot.invalidFullName': 'Please enter a valid full name! Example: "John Doe"',
    'bot.invalidCode': 'Please enter the code we have sent!',
    'bot.expiredCode':
      'Unfortunately, that doesn’t match the verification code we have on file.\n' +
      '\n' +
      'You can re-enter the verification code or follow the options below.',
    'bot.emailFound': 'We found you in our system. Is email or text better for you?',
    'bot.contactCreated': 'Great. What are you interested in learning about?',
    'bot.codeSent': 'Great! Next, please enter the **verification code** sent to your email address.',
    'bot.codeSent.sms': (phoneNumberTip) =>
      `Great. Please enter your verification code. It was sent to your number ending on ${phoneNumberTip}`,
    'bot.text': (phoneNumberTip) => `Text to ${phoneNumberTip}`,
    'bot.authed': 'You are authenticated. How can we help?',
    'bot.devices': 'Got it. Is it one of these devices?',
    'bot.notFoundTicketMessage': 'Thank you! I’m connecting you with someone now.',
    'bot.getTicketMessage': (ticketNumber) =>
      `I’ve created Thread #${ticketNumber} for your reference. Looking for someone to assist, please wait…`,
    'bot.getAssigneeMessage': (assignee) => `Ok! ${assignee} is assigned and ready to assist.`,
    'bot.getAssigneeSystem': (assignee) => `You are now chatting with ${assignee}.`,
    'bot.getDeviceMessage': (deviceName) => `The problem is with ${deviceName}`,
    'bot.contactSupport': 'Request failed, please contact your admin.',
    'bot.contactSupport.newTicket': 'Failed to create a new thread. Please contact support!',
    'bot.reachOutLater': "Ok, we'll have someone reach out to you soon!",
    'bot.connectingWithAgent': 'Thank you! I’m connecting you with someone now.',
    'bot.errorMessage': 'Something went wrong, please try again!',
    'bot.newUserAction': 'Thanks! What would you like to do next?',
    'bot.companyNotFound': 'We could not find any companies with this email.',
    'bot.contactNotFound':
      "My apologies, I'm unable to find that email address in our records.\n" +
      '\n' +
      'You can retry entering a valid email address or click "Restart from the beginning" to begin the process again.”',
    'bot.notificationPermission': 'Thanks! To receive notifications on your threads, click allow.',
    'bot.notificationPermission2': 'Don’t miss any updates! To receive notifications on your threads, click allow.',
    'bot.ticket.expired':
      'We’ve archived this thread, since it’s older than 15 days. Do you need further assistance with this request?',
    'bot.workspace.disabled':
      'It looks like your chat has been disabled. To continue using chat, please request it from your IT administrator.',
    'bot.offline.message': "We're currently offline and will be back",

    'mentions.header.title': 'Select an option ↑↓',
    'mentions.status.inThread': 'In thread',
    'mentions.status.notInThread': 'Not in thread',
    'mentions.status.memberText': 'Support',

    'onboarding.title.step1': (companyName) => `Welcome to ${companyName ? companyName + "'s" : 'the'} Service Desk.`,
    'onboarding.title.step2': 'Quick and easy',
    'onboarding.title.step3': 'Monitor progress in real-time.',
    'onboarding.desc.step1': 'Starting today you will be able to request service/support through this chat app.',
    'onboarding.desc.step2':
      'More convenient than traditional phone and email methods. Faster access to real people for immediate assistance.',
    'onboarding.desc.step3':
      'Simply start a “New Chat” to quickly get live support. From there, you can track updates and responses in real-time.',

    'signin.title': 'We need a bit more info',
    'signin.desc': 'Log in with the following options.',
    'signin.m365.button': 'Sign in with Microsoft',
    'signin.email.button': 'Log in with email',

    'successPage.title': 'Connection successful!',
    'successPage.desc': "We'll now open the app and log you in.",
    'successPage.button.auxiliar': 'Not working?',
    'successPage.button.text': 'Try again',

    'errorPage.title': 'Connection error',
    'errorPage.desc': 'Looks like we lost you in the vastness of space and time...',
    'errorPage.button.text': 'Try again',
  },
  fr: {
    'header.status.active': 'Actif',
    'header.status.done': 'Fini',
    'header.greet': (firstName) => `Bonjour ${firstName}`,
    'header.notYou': "Vous n'êtes pas?",
    'header.text.people': 'Personnes',

    'liveStep.inactive.default.title': 'Demande reçue',
    'liveStep.inactive.default.desc': "Nous cherchons quelqu'un pour vous aider",
    'liveStep.active.getAssignee.title': (assignee) => `${assignee} est assigné`,
    'liveStep.active.getAssignee.desc': (assignee) => `${assignee} sera avec vous dans un instant`,
    'liveStep.active.getAssignee.isHere': (assignee) => `${assignee} est là pour vous!`,
    'liveStep.active.getAssignee.request.desc': 'Et sera bientôt avec vous.',

    'ticket.liveChat.devices.none': 'Aucune de ces options',
    'ticket.liveChat.text': 'Chat en direct',
    'ticket.liveChat.messagePlaceholder': 'Ecrivez votre message ici',
    'ticket.chatBox.optionPlaceholder': 'Choisissez une option',
    'ticket.newRequest.text': 'Nouvelle requête',

    'account.title': 'Profil',
    'account.integration.title': 'Intégrations',
    'account.integration.connected': (isConnected) => `Connect${isConnected ? 'ed' : ''} avec`,
    'account.notification.title': 'Notifications',
    'account.notification.permission': 'Veuillez activer via les paramètres du navigateur.',
    'account.notification.enable': 'Activez les notifications pour recevoir des nouvelles de vos demandes de service.',
    'account.sound.title': 'Sons',
    'account.sound.playSoundOnNewMessage': "Émettre un son lorsqu'il y a un nouveau message",
    'account.sound.playSoundOnNewThread': "Émettre un son lorsqu'il y a une nouvelle conversation",
    'account.device.title': 'Périphériques',
    'account.device.notFound': 'Pas de appareils ici.',
    'account.auth.logOut': 'Déconnecter',
    'account.auth.logOut.loading': 'Déconnexion en cours',
    'account.info.email': 'Vous êtes connecté avec:',
    'account.info.workspace': "Dans l'espace de travail:",
    'account.app.title': 'App id',
    'account.profilePic.remove': 'Retirer photo',

    'common.connection.reconnected': 'Reconnecté',
    'common.connection.warning': 'Connexion réseau perdue ou erronée',
    'common.connection.text.retry': 'Recommencez',
    'common.text.send': 'Envoyer',
    'common.text.confirm': 'Confirmer',
    'common.text.cancel': 'Annuler',
    'common.text.unknown': 'Inconnu',
    'common.text.noAlert': "Pas d'alertes ici.",
    'common.text.noCompanies': 'Aucune compagnie trouvée',
    'common.text.yes': 'Oui',
    'common.text.no': 'Non',
    'common.text.email': 'Email',
    'common.text': 'Texte',
    'common.text.create': 'Créer',
    'common.text.creating': 'Création...',
    'common.text.status': 'Statut',
    'common.text.newChat': 'Nouvelle conversation',
    'common.text.selectCompany': 'Choisir une compagnie',
    'common.text.greeting': 'Bonjour',
    'common.text.download': 'Télécharger',
    'common.text.newTicket': 'Nouveau billet',
    'common.text.from': 'de',
    'common.text.attachment': 'Pièce jointe',
    'common.text.continue': 'Continuer',
    'common.text.skip': 'Sauter',
    'common.files.drag': 'Déposez votre fichier ici',
    'common.state.connecting': 'Connexion en cours',
    'common.alert.wrong': 'Nous avons rencontré une erreur. Veuillez essayer à nouveau.',

    'empty.state.title': 'Pas de Threads créé',
    'empty.state.description': "Si vous attendez un signe, c'est ça.",
    'empty.state.chatDescription': 'Discutez en temps réel avec votre équipe de support.',
    'empty.state.requestDescription': 'Créez une nouvelle demande et suivez son avancement.',

    'time.morning': 'Bon matin',
    'time.afternoon': 'Bon après-midi',
    'time.evening': 'Bonne soirée',

    'newRequest.device': 'Appareils',
    'newRequest.device.select': 'Choisir un appareil (facultatif)',
    'newRequest.contact': 'Personne',
    'newRequest.contact.select': 'Choisissez une personne',
    'newRequest.placeholder.summary': 'Résumé',
    'newRequest.placeholder.info': 'Expliquez plus votre demande',
    'newRequest.error.fillDescription': "Remplissez la description, s'il vous plait.",

    'quickReply.newUser.chat': 'Contacter notre équipe',
    'quickReply.newUser.reach': 'Me contacter plus tard',
    'quickReply.user.flow.new': "Je ne suis pas client et j'ai une question",
    'quickReply.user.flow.existing': 'Je suis un client',
    'quickReply.expiredThread.createNew': "Non, c'est autre chose",
    'quickReply.expiredThread.continueCurrent': 'Oui, continuer ce fil',
    'quickReply.authenticationFlow.restart': 'Recommencer depuis le début',
    'quickReply.authenticationFlow.resendCode': 'Envoyer un nouveau code de vérification',

    'bot.getGreetingMessage': (locale) => `${getTimeText(locale)}, comment pouvons-nous vous aider?`,
    'bot.getOfflineMessage': (locale) => `${getTimeText(locale)}, notre équipe est actuellement hors ligne`,
    'bot.getOfflineMessage2': `vous pouvez créer un billet et quelqu'un vous contactera`,
    'bot.authGreeting': "Bonjour et bienvenue!  Que peut-on faire pour vous aider aujourd'hui?",
    'bot.askEmailExistingUser': 'Chouette! Quelle est votre adresse courriel?',
    'bot.askEmailExistingUser2': 'Salut ! Entrez votre **adresse e-mail** pour commenter.',
    'bot.askEmailNewUser': 'Commençons! Quelle est ton **adresse email**?',
    'bot.askFullnameNewUser': 'Parfait! Quel est ton **nom**?',
    'bot.askCompanyNewUser': 'Dernière question : quel est le nom de votre **entreprise**?',
    'bot.askFullnameExistingCompany': "Il semble que vous n'êtes pas client. Quel est votre nom et prénom?",
    'bot.silentAuthGreeting': (companyName) => `Bonjour! Bienvenue chez ${companyName}!`,
    'bot.getEmailConfirmation': (email) => `Nous avons trouvé cette adresse courriel ${email}, est-ce exact?`,
    'bot.askEmail': 'Quelle est votre adresse courriel?',
    'bot.invalidEmail': 'Entrez une adresse courriel valide',
    'bot.invalidFullName': 'Entrez un nom valide! Par example: "John Doe"',
    'bot.invalidCode': 'Entrez le code numérique que nous avons envoyé',
    'bot.expiredCode':
      'Malheureusement, cela ne correspond pas au code de vérification que nous avons enregistré.\n' +
      '\n' +
      'Vous pouvez ressaisir le code de vérification ou suivre les options ci-dessous.',
    'bot.emailFound': "Nous vous avons trouvé dans notre système. Préférez-vous l'email ou le message texte?",
    'bot.contactCreated': 'Super. Que voulez-vous savoir?',
    'bot.codeSent': 'Super ! Ensuite, veuillez saisir le **code de vérification** envoyé à votre adresse e-mail.',
    'bot.codeSent.sms': (phoneNumberTip) =>
      `Le code semble incorrect ou expiré, réessayez s'il vous plaît. Il a été envoyé à votre numéro se terminant par ${phoneNumberTip}`,
    'bot.text': (phoneNumberTip) => `Texte a ${phoneNumberTip}`,
    'bot.authed': 'Vous êtes authentifié. Comment pouvons-nous vous aider?',
    'bot.devices': "J'ai compris. Est-ce un de ces appareils?",
    'bot.notFoundTicketMessage': "Merci! Nous trouvons un membre de l'équipe pour vous assister",
    'bot.getTicketMessage': (ticketNumber) =>
      `Le billet #${ticketNumber} a été créé. Nous cherchons un membre de l'équipe pour vous assister. Un instant s'il vous plaît.`,
    'bot.getAssigneeMessage': (assignee) => `${assignee} est là pour vous aider!`,
    'bot.getAssigneeSystem': (assignee) => `vous êtes en ligne avec ${assignee}.`,
    'bot.getDeviceMessage': (deviceName) => `Le problème affecte ${deviceName}`,
    'bot.contactSupport': 'Échec. Veuillez contacter le service technique.',
    'bot.contactSupport.newTicket': "Échec de la création d'un nouveau ticket. Veuillez contacter l'assistance!",
    'bot.reachOutLater': "D'accord, quelqu'un vous contactera bientôt.",
    'bot.connectingWithAgent': "Merci! Nous trouvons un membre de l'équipe pour vous assister",
    'bot.errorMessage': 'Nous avons rencontré une erreur.  Veuillez essayer à nouveau.',
    'bot.newUserAction': 'Merci! Que voulez-vous faire?',
    'bot.companyNotFound': "Nous n'avons pas trouvé de compagnie avec cette adresse courriel.",
    'bot.contactNotFound': "Vous n'avez pas accès. Veuillez contactez le support technique.",
    'bot.notificationPermission': 'Merci! Pour recevoir des notifications sur cette demande, cliquez sur accepter',
    'bot.notificationPermission2': 'Ne manquez pas nos réponses! Pour recevoir des notifications, cliquez sur accepter',
    'bot.ticket.expired':
      "Nous avons archivé ce fil, car il date de plus de 15 jours. Avez-vous besoin d'aide supplémentaire pour cette demande ?",
    'bot.workspace.disabled':
      'Il semble que votre chat ait été désactivé. Pour continuer à utiliser le chat, veuillez en faire la demande auprès de votre administrateur informatique.',
    'bot.offline.message': 'Nous sommes actuellement hors ligne. Vous pourrez nous joindre à nouveau à partir de',

    'mentions.header.title': 'Choisir une option ↑↓',
    'mentions.status.inThread': 'Dans le fil',
    'mentions.status.notInThread': 'Pas dans le fil',
    'mentions.status.memberText': 'Soutien',

    'onboarding.title.step1': (companyName) => `Bienvenue a ${companyName} Support.`,
    'onboarding.title.step2': 'Rapide et facile',
    'onboarding.title.step3': 'Voir la progression en direct',
    'onboarding.desc.step1': "À partir d'aujourd'hui, vous pouvez demander de l'aide via le chat.",
    'onboarding.desc.step2':
      "Plus simple que par email ou par téléphone. Accès plus rapide à quelqu'un qui peut vous aider.",
    'onboarding.desc.step3': 'Démarrez une nouvelle discussion et obtenez des réponses en direct sur votre question.',

    'signin.title': "Nous avons besoin de plus d'informations.",
    'signin.desc': 'se connecter',
    'signin.m365.button': 'Connecter avec Microsoft',
    'signin.email.button': 'Connecter avec email',

    'successPage.title': 'Connexion réussie!',
    'successPage.desc': "Nous allons maintenant ouvrir l'application et vous connecter.",
    'successPage.button.auxiliar': 'Ca ne fonctionne pas?',
    'successPage.button.text': 'Réessayer',

    'errorPage.title': 'Erreur de connexion',
    'errorPage.desc': "On dirait que nous t'avons perdu dans l'immensité de l'espace et du temps...",
    'errorPage.button.text': 'Réessayer',
  },
};
