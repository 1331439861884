import React, { memo } from 'react';
import PropTypes from 'prop-types';
import Common from 'src/components/Common';
import useGetDevices from 'src/hooks/useGetDevices';
import useTheme from 'src/hooks/useTheme';
import DevicesListItem from './DevicesListItem';
import styles from './style';
import useTranslation from 'src/hooks/useTranslation';

const Devices = ({ onClickListItem, listHeader }) => {
  const { data, fetchMore, isFetchingMore, isLoading, refetch, isFetching } = useGetDevices();
  const { translate } = useTranslation();

  const { colors } = useTheme();

  return (
    <Common.View>
      <Common.FlatList
        contentContainerStyle={styles.listContainer}
        ListHeaderComponent={<>{listHeader}</>}
        ListEmptyComponent={
          <Common.View style={styles.emptyWrapper}>
            {isLoading ? (
              <Common.Spinner />
            ) : (
              <Common.Text style={styles.empty} color="#999999">
                {translate('account.device.notFound')}
              </Common.Text>
            )}
          </Common.View>
        }
        style={[styles.list, { backgroundColor: colors.background }]}
        refreshing={Boolean(isFetching && data?.length)}
        onRefresh={refetch}
        data={data || []}
        onEndReached={fetchMore}
        onEndReachedThreshold={0.5}
        renderItem={({ item, index }) => (
          <DevicesListItem item={item} index={index} onPress={() => onClickListItem(item)} />
        )}
        keyExtractor={(item) => item.id?.toString()}
        ListFooterComponent={isFetchingMore && <Common.Spinner />}
      />
    </Common.View>
  );
};

Devices.defaultProps = {
  listHeader: null,
  onClickListItem: () => {},
};

Devices.propTypes = {
  listHeader: PropTypes.any,
  onClickListItem: PropTypes.func,
};

export default memo(Devices);
