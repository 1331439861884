import React from 'react';
import { LongArrowIcon, ShortArrowIcon } from 'src/assets/icons';
import Common from 'src/components/Common';
import { LIVE_CHAT_STATES, NEW_REQUEST_STATES } from 'src/constants';
import useProfile from 'src/hooks/useProfile';
import useTheme from 'src/hooks/useTheme';
import styles from './style';
import useTranslation from 'src/hooks/useTranslation';

const getBubbleStyle = (isDarkMode, theme) => [
  styles.messageBubble,
  isDarkMode && { backgroundColor: theme.foreground, borderWidth: 0 },
];

const EmptyState = () => {
  const { isDarkMode, theme } = useTheme();
  const { companyInfo } = useProfile();
  const supportType = companyInfo.supportType || 'live';
  const showNewRequestButton = NEW_REQUEST_STATES.includes(supportType);
  const showLiveChatButton = LIVE_CHAT_STATES.includes(supportType);
  const isOneButtonVisible =
    (showLiveChatButton && !showNewRequestButton) || (showNewRequestButton && !showLiveChatButton);
  const { translate } = useTranslation();

  return (
    <Common.View flexGrow={1} style={{ backgroundColor: theme.background }}>
      <Common.View style={styles.wrapper}>
        <Common.View style={styles.titleWrapper}>
          <Common.Text weight="bold" style={styles.text}>
            {translate('empty.state.title')}
          </Common.Text>
          <Common.Text style={styles.text}>{translate('empty.state.description')}</Common.Text>
        </Common.View>
        <Common.View style={styles.messagesWrapper}>
          {isOneButtonVisible ? (
            <Common.View style={[styles.bubbleWrapper, styles.rightMessage, styles.centeredMessage]}>
              <Common.View style={getBubbleStyle(isDarkMode, theme)}>
                <Common.Text style={styles.messageText}>
                  {showLiveChatButton
                    ? translate('empty.state.chatDescription')
                    : translate('empty.state.requestDescription')}
                </Common.Text>
              </Common.View>
              <Common.View style={[styles.longArrow, styles.centeredArrow]}>
                <LongArrowIcon />
              </Common.View>
            </Common.View>
          ) : (
            <>
              {showLiveChatButton && (
                <Common.View style={[styles.bubbleWrapper, styles.rightMessage]}>
                  <Common.View style={getBubbleStyle(isDarkMode, theme)}>
                    <Common.Text style={styles.messageText}>{translate('empty.state.chatDescription')}</Common.Text>
                  </Common.View>
                  <Common.View style={[styles.longArrow]}>
                    <LongArrowIcon />
                  </Common.View>
                </Common.View>
              )}
              {showNewRequestButton && (
                <Common.View style={[styles.bubbleWrapper, styles.leftMessage]}>
                  <Common.View style={getBubbleStyle(isDarkMode, theme)}>
                    <Common.Text style={styles.messageText}>{translate('empty.state.requestDescription')}</Common.Text>
                  </Common.View>
                  <Common.View style={styles.shortArrow}>
                    <ShortArrowIcon />
                  </Common.View>
                </Common.View>
              )}
            </>
          )}
        </Common.View>
      </Common.View>
    </Common.View>
  );
};

export default EmptyState;
