import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { appTheme, defaultWorkspaceColors } from 'src/lib/theme';
import { useSafeArea } from 'react-native-safe-area-view';
import { updateProfileAction } from 'src/redux/profile/profileActions';
import { Platform, useColorScheme } from 'react-native';
import Color from 'color';
import { colors } from 'src/constants/theme';
import { useDispatchMapper } from './actionHooks';

const useDarkMode = () => {
  if (Platform.OS === 'web') return false;
  const colorScheme = useColorScheme();
  return colorScheme === 'dark';
};

const useTheme = (params) => {
  const companyInfo = useSelector((store) => store.profile.companyInfo);
  const saveProfile = useDispatchMapper(updateProfileAction);
  const isDarkMode = params?.darkModeOn ?? useDarkMode();

  const updateTheme = (newTheme) => {
    saveProfile({
      companyInfo: {
        ...companyInfo,
        primaryColor: newTheme?.primaryColor,
        secondaryColor: newTheme?.secondaryColor,
      },
    });
  };
  const resetTheme = () => {
    saveProfile({
      companyInfo: {
        ...companyInfo,
        primaryColor: defaultWorkspaceColors?.primaryColor,
        secondaryColor: defaultWorkspaceColors?.secondaryColor,
      },
    });
  };

  useEffect(() => {
    if (companyInfo?.primaryColor && Platform.OS === 'web') {
      document.documentElement.style.setProperty('--primary', companyInfo.primaryColor);
    }
  }, [companyInfo?.primaryColor]);

  return {
    theme: {
      ...appTheme[isDarkMode ? 'dark' : 'light'],
      primaryColor: isDarkMode ? colors.white : companyInfo?.primaryColor,
      secondaryColor: companyInfo?.secondaryColor,
      primaryOpacity: Color('rgba(256,256,256, 1)').mix(Color(companyInfo?.primaryColor), 0.1).toString(),
    },
    isDarkMode,
    colors: appTheme[isDarkMode ? 'dark' : 'light'],
    updateTheme,
    resetTheme,
  };
};

export default useTheme;

export const withTheme = (BaseComponent) => (props) => {
  const themeProps = useTheme();
  const insets = useSafeArea();
  return (
    <BaseComponent {...themeProps} insets={insets} {...props} />
  );
};
