import postHog from 'posthog-js';
import { queryCache } from 'react-query';
import { resetReduxPersist, store } from 'src/redux/store';
import { resetStoreAction } from 'src/redux/rootActions';
import * as Sentry from '@sentry/react-native';
import { reactiveVarClient, reactiveVarSubscription } from '@Thread-Magic/thread-service-utils';
import { isWeb } from './index';
import webchatIframe from './webchatIframe';

const Authentication = {
  logout: async (callback = () => {}) => {
    try {
      const apolloClient = reactiveVarClient();
      const apolloSubscriptions = reactiveVarSubscription();

      queryCache.clear();

      if (isWeb()) {
        webchatIframe.onWebWidgetLogout();
      }

      store?.dispatch(resetStoreAction());
      resetReduxPersist();

      if (window?.ipc) {
        window.ipc?.send('logout');
      }

      postHog.reset();

      if (apolloClient) {
        apolloClient.stop();
        await apolloClient.clearStore();
        apolloSubscriptions.dispose();
      }

      Sentry.configureScope((scope) => scope.setUser(null));
    } catch (err) {
      console.log(err);
    }

    if (callback) {
      callback();
    }
  },
};

export default Authentication;
