import Config from 'react-native-config';

export const redirectUris = {
  supportBot: Config.REDIRECT_URI_SUPPORT_BOT,
  slackRedirect: Config.SLACK_AUTH_REDIRECT_URL,
};

export const slackClientId = Config.SLACK_CLIENT_ID;

export const getPoweredByLink = (companyName) =>
  `${Config.MESSENGER_PAGE_URL}?utm_source=${companyName}&utm_medium=messenger_banner&utm_campaign=powered_by_thread`;

export const auth0Config = {
  audience: Config.AUTH0_AUDIENCE,
  domain: Config.AUTH0_DOMAIN,
  clientId: Config.AUTH0_CLIENT_ID,
  scope: 'openid email profile offline_access',
};

// Enable SMS Authentication for app ids
export const smsEnabledAppIds = [
  // stage
  'f323cf0d-ace5-4946-a3ea-d0f739653bef', // TestIsmoil
  'de50bff5-4322-4d20-808f-91c9482dd5e1', // 31029
  '651b157a-1af5-4ff9-ac48-c77b441b6b60', // 830575
  'bab4a9dd-1daf-4f49-a715-d85046689fd8',
  // production
  '9a5021e5-af20-4be5-ab5f-36a4945429a8', // Thread Production Teams
  'bc479ed9-bb54-4347-b676-030555535e4b', // Thread Production Slack
  // '248d6c67-713d-44ed-8c73-def5de1b80db', // Thread Production Help
];
