import {
  saveMessageAction,
  resetChatAction,
  setChatTicketAction,
  incrementNotifCountAction,
  setTwilioChannelAction,
  setActiveChannelAction,
  setNewTicketCreationStateAction,
} from 'src/redux/chat/chatActions';
import { useDispatchMapper } from 'src/hooks/actionHooks';
import { useSelector } from 'react-redux';

const useChatState = () => {
  const chatState = useSelector((store) => store.chat);

  const saveMessage = useDispatchMapper(saveMessageAction);
  const resetChat = useDispatchMapper(resetChatAction);
  const saveTicket = useDispatchMapper(setChatTicketAction);
  const incrementNotificationCounter = useDispatchMapper(incrementNotifCountAction);
  const setTwilioChannel = useDispatchMapper(setTwilioChannelAction);
  const setActiveChannel = useDispatchMapper(setActiveChannelAction);
  const setNewTicketCreationState = useDispatchMapper(setNewTicketCreationStateAction);

  return {
    chatState,
    saveMessage,
    resetChat,
    saveTicket,
    incrementNotificationCounter,
    setTwilioChannel,
    setActiveChannel,
    setNewTicketCreationState,
  };
};

export default useChatState;
