import React from 'react';
import { Ionicons } from 'src/components/Common/Icon';
import { alertCardColors, colors } from 'src/constants/theme';
import PropTypes from 'prop-types';
import Common, { Row } from 'src/components/Common';

import styles from './style';

const AlertCard = ({
  isLast, item, onOpen, isUnread,
}) => (
  <Common.TouchableOpacity
    onPress={onOpen}
    activeOpacity={0.8}
    style={[
      styles.card,
      {
        marginRight: isLast ? 15 : 0,
        backgroundColor: alertCardColors?.[item?.type] || 'grey',
      },
    ]}
    testID="alert-card"
  >
    <Row alignItems="center" justifyContent="space-between">
      <Common.Text testID="alert-title" bold numberOfLines={1} size={15} color={colors.white}>{item?.title}</Common.Text>
      <Common.View style={styles.alertIconWrapper}>
        {isUnread ? (
          <Ionicons
            name="ios-notifications"
            size={22}
            testID="bell-icon-unread"
            color={colors.white}
          />
        ) : (
          <Ionicons
            name="ios-notifications-outline"
            size={22}
            testID="bell-icon-read"
            color={colors.white}
          />
        )}
      </Common.View>
    </Row>
    <Common.Text testID="alert-description" style={styles.description} numberOfLines={2}>
      {item?.description}
    </Common.Text>
    <Common.Text weight="600" style={styles.learnMore}>Show more</Common.Text>
  </Common.TouchableOpacity>
);

AlertCard.propTypes = {
  item: PropTypes.object.isRequired,
  isLast: PropTypes.bool.isRequired,
  isUnread: PropTypes.bool.isRequired,
  onOpen: PropTypes.func.isRequired,
};

export default React.memo(AlertCard);
