import React from 'react';
import PropTypes from 'prop-types';
import { ActivityIndicator } from 'react-native';
import useTheme from 'src/hooks/useTheme';
import Row from '../Row';

const Spinner = ({ containerProps, indicatorProps }) => {
  const { theme } = useTheme();
  return (
    <Row testID="spinner" flexGrow={1} justifyContent="center" alignItems="center" {...containerProps}>
      <ActivityIndicator color={theme.primaryColor} size="large" {...indicatorProps} />
    </Row>
  );
};

Spinner.defaultProps = {
  containerProps: null,
  indicatorProps: null,
};

Spinner.propTypes = {
  containerProps: PropTypes.object,
  indicatorProps: PropTypes.object,
};

export default React.memo(Spinner);
