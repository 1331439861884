import React from 'react';
import PropTypes from 'prop-types';
import styles, { getParticipantGroupAvatarStyles } from './style';
import Common from 'src/components/Common';
import { Participant, ParticipantUnassigned } from 'src/components/MentionsComponents/Participants';

const ParticipantsGroup = ({ hasTicketAssignee, ticketParticipants = [] }) => {
  const participantsCount = ticketParticipants.length;
  const isLargeGroup = participantsCount > 3 && hasTicketAssignee;

  const renderParticipants = () => {
    let participantElements = ticketParticipants.map((participant, index) => (
      <Participant
        key={participant.id}
        size={25}
        participantName={participant.full_name || participant.name}
        url={participant.profile_image?.path}
        style={
          isLargeGroup && getParticipantGroupAvatarStyles({ offset: index, zIndex: participantsCount - index - 1 })
        }
      />
    ));

    if (!isLargeGroup) {
      return participantElements;
    }
    // first 3 avatars
    participantElements = participantElements.slice(0, 3);

    participantElements.push(
      <Common.Text key={participantsCount} style={styles.participantMultiIndicator}>
        {participantsCount}
      </Common.Text>,
    );

    return participantElements;
  };

  return (
    <Common.View style={[styles.participants, isLargeGroup && styles.participantsLargeGroup]} transparent>
      {!hasTicketAssignee ? <ParticipantUnassigned size={25} /> : renderParticipants()}
    </Common.View>
  );
};

ParticipantsGroup.propTypes = {
  hasTicketAssignee: PropTypes.bool.isRequired,
  ticketParticipants: PropTypes.array,
};

export default ParticipantsGroup;
