import React from 'react';
import navigationService from 'src/utils/navigation';
import routeNames from 'src/constants/routeNames';
import { NewRequestIcon } from 'src/assets/icons';
import Common from 'src/components/Common';
import useTranslation from 'src/hooks/useTranslation';
import useTheme from 'src/hooks/useTheme';

export const NewRequestButton = ({ btnStyle = {}, labelStyle }) => {
  const { theme } = useTheme();
  const { translate } = useTranslation();

  return (
    <Common.TouchableOpacity
      style={btnStyle}
      onPress={() => navigationService.navigate(routeNames.CHAT, { isNewRequest: true })}
    >
      <NewRequestIcon fill={theme.primaryColor} stroke={theme.primaryColor} />
      <Common.Text style={labelStyle} color={theme.primaryColor}>
        {translate('ticket.newRequest.text')}
      </Common.Text>
    </Common.TouchableOpacity>
  );
};
