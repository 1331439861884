import { StyleSheet } from 'react-native';

const styles = StyleSheet.create({
  wrapper: { flexGrow: 1, flexShrink: 1 },
  poweredBy: {
    height: 30,
    justifyContent: 'center',
    alignItems: 'center',
  },
});

export default styles;
