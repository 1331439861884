import { useSubscription } from '@Thread-Magic/thread-service-utils';
import { THREAD_STATES } from 'src/constants';
import { modifyMyThreadsCache } from 'src/graphql/cache';
import {
  ON_CONTACT_THREAD_ADDED,
  ON_CONTACT_THREAD_CLOSED,
  ON_CONTACT_THREAD_REMOVED,
  ON_CONTACT_THREAD_REOPENED,
  ON_CONTACT_THREAD_UPDATED,
} from 'src/graphql/subscriptions';

export default function useThreadSubscriptions({ onThreadUpdated }) {
  useSubscription(ON_CONTACT_THREAD_ADDED(), {
    onSubscriptionData({ subscriptionData: { data } }) {
      const { thread } = data.onContactThreadAdded;
      modifyMyThreadsCache({ threadToAdd: thread });
    },
  });

  useSubscription(ON_CONTACT_THREAD_UPDATED(), {
    onSubscriptionData({ subscriptionData: { data } }) {
      const { thread, delta } = data.onThreadUpdated;
      const previousState = delta?.before?.find((el) => el.name === 'state')?.value;
      const nextState = delta?.after?.find((el) => el.name === 'state')?.value;
      if (previousState && nextState) {
        modifyMyThreadsCache({
          threadToRemove: { ...thread, state: previousState },
          threadToAdd: thread,
        });
      }

      onThreadUpdated(thread);
    },
  });

  useSubscription(ON_CONTACT_THREAD_REMOVED(), {
    onSubscriptionData({ subscriptionData: { data } }) {
      const { thread } = data.onContactThreadRemoved;
      modifyMyThreadsCache({ threadToRemove: thread });
    },
  });

  useSubscription(ON_CONTACT_THREAD_CLOSED(), {
    onSubscriptionData({ subscriptionData: { data } }) {
      const { thread } = data.onContactThreadClosed;
      const previousThreadState = THREAD_STATES.ACTIVE;
      modifyMyThreadsCache({
        threadToRemove: { ...thread, state: previousThreadState },
        threadToAdd: thread,
      });
    },
  });

  useSubscription(ON_CONTACT_THREAD_REOPENED(), {
    onSubscriptionData({ subscriptionData: { data } }) {
      const { thread } = data.onContactThreadReopened;
      const previousThreadState = THREAD_STATES.DONE;

      modifyMyThreadsCache({
        threadToRemove: { ...thread, state: previousThreadState },
        threadToAdd: thread,
      });
    },
  });
}
