import { colors, fonts } from 'src/constants/theme';
import { isWeb } from 'src/utils';

const styles = {
  card: {
    width: '100%',
    padding: isWeb() ? 10 : 15,
    marginTop: 0,
    marginBottom: 0,
    borderRadius: 0,
    borderBottom: '1px solid #ddd',
    backgroundColor: colors.white,
  },
  cardDetailsWrapper: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  cardUserLiveStatus: {
    fontSize: 10,
    fontFamily: fonts.semiBold,
    width: 'fit-content',
    backgroundColor: '#FFDEDE',
    color: colors.red3,
    position: 'absolute',
    top: 24,
    left: 4,
    border: '1px solid #EDF4FE',
    borderRadius: 2,
    paddingLeft: 3,
    paddingRight: 3,
    paddingBottom: 2,
    paddingTop: 2,
  },

  cardDetailsContact: {
    marginRight: 10,
    flexBasis: 37,
  },

  cardBodyWrapper: {
    // 37px for contact div and 10px for margin
    flexBasis: 'calc(100% - 47px)',
  },

  cardBody: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },

  cardBodyLeft: {
    // 37px for contact div, 45px for date/systemId div, rest is for margin
    maxWidth: 'calc(100% - 90px)',
  },

  cardBodyRight: {},

  cardLastNote: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },

  cardContact: {
    fontFamily: fonts.semiBold,
    marginBottom: 5,
  },

  cardSummary: {
    fontFamily: fonts.semiBold,
    color: '#0E1D96',
    marginBottom: 5,
  },

  cardDate: {
    color: colors.grey3,
    marginBottom: 5,
  },

  cardSystemId: {
    color: colors.grey3,
    textAlign: 'right',
  },

  cardHeader: {
    backgroundColor: 'transparent',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-between',
  },

  cardFooter: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginTop: 5,
  },

  participants: {
    display: 'flex',
    flexDirection: 'row',
  },

  participantsLargeGroup: {
    position: 'relative',
    width: 100,
    height: 25,
  },

  participantMultiIndicator: {
    position: 'absolute',
    right: 0,
    fontSize: 12,
    borderRadius: '50%',
    color: colors.grey3,
    backgroundColor: '#99E4D6',
    width: 28,
    height: 28,
    textAlign: 'center',
    lineHeight: 26,
    zIndex: 99,
    border: `1px solid ${colors.white}`,
  },

  assigneeAvatar: {
    borderRadius: '50%',
  },

  cardStatusWrapper: {
    maxWidth: '60%',
  },

  cardStatus: {
    fontFamily: fonts.semiBold,
    padding: 6,
    color: 'rgb(97, 105, 153)',
    background: 'rgb(225, 236, 254)',
    borderRadius: 2,
    fontSize: 11,
    textTransform: 'uppercase',
  },
  unreadIndicator: {
    marginRight: 5,
    backgroundColor: colors.blue2,
    width: 8,
    height: 8,
    borderRadius: 1000,
  },
};

export const getCardStyles = (priorityColor) => ({
  ...styles.card,
  borderLeft: `3px solid ${colors.priority[priorityColor] ?? colors.priority.default}`,
});

export const getParticipantGroupAvatarStyles = ({ offset, zIndex }) => {
  return {
    position: 'absolute',
    right: offset * 12 + 12,
    zIndex,
  };
};

export default styles;
