import React, { useEffect, useRef } from 'react';
import useProfile from '../../../../../../shared/src/hooks/useProfile';
import Content from '../Content';
import { CheckIcon } from 'src/assets/icons';
import { clearSavedVariables } from '../..';

const SuccessContentWindows = () => {
  const { companyInfo } = useProfile();

  useEffect(() => {
    // removing locally stored variables which may affect next onboardings
    clearSavedVariables();
  }, []);

  return (
    <Content
      header={
        <>
          <p className="title">Collaborate smarter with thread.</p>

          <div className="message no-border">
            <div className="check-icon-wrapper">
              <CheckIcon />
            </div>
            <p className="message-title no-border">Connection successful!</p>
            <p className="message-description no-border">
              Next deploy the app on the contact’s desktop through the command prompt.
            </p>
          </div>
        </>
      }
      mainContent={
        <div className="code-wrapper">
          <p>
            <strong>Sillent install the app</strong>
          </p>
          <div className="code">
            msiexec /i "https://assets.getthread.com/messenger/downloads/desktop/messenger.msi" APP_ID=
            {companyInfo?.appId} FLOW=customer INSTALL_TYPE=user /qn
          </div>
        </div>
      }
      sideContent={null}
    />
  );
};

export default React.memo(SuccessContentWindows);
