import { StyleSheet } from 'react-native';

const styles = StyleSheet.create({
  logoutBtn: {
    paddingVertical: 10,
    paddingHorizontal: 15,
  },
  padding: { paddingHorizontal: 15, paddingTop: 10 },
  integrationImage: {
    height: 37,
    width: 69,
    resizeMode: 'contain',
    marginLeft: 10,
  },
  integrationBtn: {
    height: 44,
    borderWidth: 1,
    borderColor: '#DDDDDD',
    borderRadius: 4,
    marginTop: 17,
    backgroundColor: '#FFF',
  },
  checkIcon: {
    position: 'absolute',
    right: 8,
  },
});

export default styles;
