import React, { memo } from 'react';
import PropTypes from 'prop-types';
import Common from 'src/components/Common';
import { colors } from 'src/constants/theme';
import useProfile from 'src/hooks/useProfile';
import { formatDate } from 'src/utils/date';
import styles from './style';
import MarkdownDisplay from './MarkdownDisplay';

const Text = ({ message }) => {
  const { companyInfo } = useProfile();
  const isMine = message?.isMine;
  const author = message?.attributes?.author;
  return (
    <>
      {!isMine && (
        <Common.Text style={styles.company}>
          <Common.Text weight="bold" style={styles.title}>
            {author || `${companyInfo?.botName || 'Thread'}  `}
          </Common.Text>
          {!author ? <Common.Text color={colors.grey}>(bot)</Common.Text> : null}
        </Common.Text>
      )}
      <MarkdownDisplay text={message.message || ''} />
      <Common.Text style={styles.timestamp} size={11} transform="uppercase" color={colors.grey}>
        {formatDate(message?.timestamp || new Date(), 'hh:mm a')}
      </Common.Text>
    </>
  );
};

Text.propTypes = {
  message: PropTypes.object.isRequired,
};

export default memo(Text);
