import { StyleSheet } from 'react-native';
import { colors } from 'src/constants/theme';

const styles = StyleSheet.create({
  wrapper: {
    right: 15,
    top: 'auto',
    marginBottom: 28,
    backgroundColor: 'transparent',
    alignItems: 'flex-end',
  },
  replyAction: {
    borderWidth: 1,
    borderColor: colors.darkblue2,
    borderRadius: 6,
    paddingHorizontal: 10,
    minWidth: 70,
    height: 36,
    marginBottom: 8,
    backgroundColor: '#fff',
    justifyContent: 'center',
    alignItems: 'center',
  },
});

export default styles;
