import React, { useCallback, useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import Common from 'src/components/Common';
import styles, { getAvatarStyle, getInnerContentStyle, getPowerByStyles } from './style';
import { Animated } from 'react-native';
import UserProfilePicture from 'src/components/Common/UserProfilePicture';

export default function TypingIndicator({ children, channel }) {
  const [typingState, setTypingState] = useState({
    isTyping: false,
    members: [],
  });

  const typingFadeAnim = useRef(new Animated.Value(0)).current;
  const typingTranslateAnim = useRef(new Animated.Value(-50)).current;
  const powerByFadeAnim = useRef(new Animated.Value(1)).current;
  const powerByTranslateAnim = useRef(new Animated.Value(0)).current;

  const showTyping = () => {
    Animated.parallel([
      Animated.timing(typingFadeAnim, {
        toValue: 1,
        duration: 150,
      }),
      Animated.timing(typingTranslateAnim, {
        toValue: 0,
        duration: 150,
      }),
      Animated.timing(powerByFadeAnim, {
        toValue: 0,
        duration: 150,
      }),
      Animated.timing(powerByTranslateAnim, {
        toValue: 50,
        duration: 150,
      }),
    ]).start();
  };

  const hideTyping = () => {
    Animated.parallel([
      Animated.timing(typingFadeAnim, {
        toValue: 0,
        duration: 150,
      }),
      Animated.timing(typingTranslateAnim, {
        toValue: -50,
        duration: 150,
      }),
      Animated.timing(powerByFadeAnim, {
        toValue: 1,
        duration: 150,
      }),
      Animated.timing(powerByTranslateAnim, {
        toValue: 0,
        duration: 150,
      }),
    ]).start();
  };

  const handleTypingStart = useCallback(
    (member) => {
      member
        .getUser()
        .then((user) => {
          const isMemberAlreadyExist = typingState.members.findIndex((el) => el.identity === user.identity) !== -1;
          const uniqueMembers = isMemberAlreadyExist
            ? typingState.members
            : [
                {
                  identity: user.identity,
                  friendlyName: user.friendlyName,
                },
                ...typingState.members,
              ];
          setTypingState({
            isTyping: true,
            members: uniqueMembers,
          });
        })
        .catch((err) => Sentry.captureException(err));
    },
    [channel, typingState],
  );
  const handleTypingEnd = useCallback(() => {
    hideTyping();
    setTimeout(
      () =>
        setTypingState({
          isTyping: false,
          members: [],
        }),
      150, // waiting for animation
    );
  }, [channel, typingState]);
  useEffect(() => {
    if (channel) {
      channel.on('typingStarted', handleTypingStart);
      channel.on('typingEnded', handleTypingEnd);
    }
    return () => {
      if (channel) {
        channel.off('typingStarted', handleTypingStart);
        channel.off('typingEnded', handleTypingEnd);
      }
    };
  }, [channel, typingState]);
  useEffect(() => {
    if (typingState.isTyping) {
      showTyping();
    }
  }, [typingState.isTyping]);

  return (
    <Common.Row style={styles.wrapper}>
      <Animated.View style={getInnerContentStyle({ typingFadeAnim, typingTranslateAnim })}>
        {typingState.isTyping && (
          <>
            <Common.View style={styles.avatarList}>
              {typingState.members.map((member, index) => (
                <UserProfilePicture
                  key={member.identity}
                  size={22}
                  alt={member.friendlyName}
                  url={undefined}
                  style={getAvatarStyle({ index })}
                />
              ))}
            </Common.View>
            <Common.Text style={styles.typingText}>
              {typingState.members.length > 1 ? `Multiple people are` : `${typingState.members?.[0]?.friendlyName} is`}
              &nbsp;typing...
            </Common.Text>
          </>
        )}
      </Animated.View>
      <Animated.View style={getPowerByStyles({ powerByFadeAnim, powerByTranslateAnim })}>{children}</Animated.View>
    </Common.Row>
  );
}
TypingIndicator.propTypes = {
  children: PropTypes.node,
  isNewTicket: PropTypes.bool,
};
