/* eslint-disable react-native/no-inline-styles */
import React, { useState, useEffect, useRef, memo } from 'react';
import { View, Animated, TouchableWithoutFeedback } from 'react-native';
import Svg, { Path } from 'react-native-svg';
import { colors } from 'src/constants/theme';
import { styles } from './style';

const STEP_STATUS = {
  CURRENT: 'current',
  FINISHED: 'finished',
  UNFINISHED: 'unfinished',
};

const customStyles = {
  separatorStrokeUnfinishedWidth: 0,
  stepIndicatorSize: 19,
  currentStepIndicatorSize: 22,
  finishedStepIndicatorSize: 22,
  separatorStrokeWidth: 4,
  currentStepStrokeWidth: 4,
  stepStrokeCurrentColor: colors.green2,
  stepStrokeWidth: 4,
  separatorStrokeFinishedWidth: 4,
  stepStrokeUnfinishedWidth: 1,
  stepStrokeFinishedColor: colors.green2,
  stepStrokeUnFinishedColor: colors.grey8,
  separatorFinishedColor: colors.green2,
  separatorUnFinishedColor: colors.grey8,
  stepIndicatorFinishedColor: colors.green2,
  stepIndicatorUnFinishedColor: colors.white,
  stepIndicatorCurrentColor: colors.green2,
};

const StepIndicator = ({ currentPosition = 0, stepCount = 3 }) => {
  const [width, setWidth] = useState(0);
  const [height, setHeight] = useState(0);
  const [progressBarSize, setProgressBarSize] = useState(0);

  const progressAnim = useRef(new Animated.Value(0)).current;
  const sizeAnim = useRef(new Animated.Value(customStyles.stepIndicatorSize)).current;
  const staleSizeAnim = useRef(new Animated.Value(customStyles.stepIndicatorSize)).current;
  const borderRadiusAnim = useRef(new Animated.Value(customStyles.stepIndicatorSize / 2)).current;

  const effectCurrentPosition = () => {
    onCurrentPositionChanged(currentPosition);
  };
  useEffect(effectCurrentPosition, [currentPosition, progressBarSize]);

  const renderCustomStepIndicator = ({ stepStatus }) => (
    <Svg width="13" height="9" viewBox="0 0 13 9" fill="none" xmlns="http://www.w3.org/2000/svg">
      <Path
        d="M11.4138 0.896553L4.20689 8.10345L0.93103 4.82759"
        stroke={stepStatus === 'unfinished' ? colors.grey8 : colors.white}
        strokeWidth="1.31034"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Svg>
  );

  const renderProgressBarBackground = () => {
    let progressBarBackgroundStyle = {
      position: 'absolute',
    };
    const dashLineStyle = {
      height: 2,
      width: 8,
      backgroundColor: colors.grey8,
      borderRadius: 2,
      marginHorizontal: 3,
    };

    progressBarBackgroundStyle = {
      ...progressBarBackgroundStyle,
      top: (height - customStyles.separatorStrokeWidth) / 2,
      left: 16,
      right: 16,
      display: 'flex',
      flexDirection: 'row',
      height:
        customStyles.separatorStrokeUnfinishedWidth === 0
          ? customStyles.separatorStrokeWidth
          : customStyles.separatorStrokeUnfinishedWidth,
    };
    return (
      <View
        onLayout={(event) => {
          setProgressBarSize(event.nativeEvent.layout.width);
        }}
        style={progressBarBackgroundStyle}
      >
        {Array(Math.round(progressBarSize / 8))
          .fill()
          .map((el, index) => (
            <View key={index} style={dashLineStyle} />
          ))}
      </View>
    );
  };

  const renderProgressBar = () => {
    let progressBarStyle = {
      backgroundColor: customStyles.separatorFinishedColor,
      position: 'absolute',
    };

    progressBarStyle = {
      ...progressBarStyle,
      top: (height - customStyles.separatorStrokeWidth) / 2,
      left: 16,
      right: 16,
      height:
        customStyles.separatorStrokeFinishedWidth === 0
          ? customStyles.separatorStrokeWidth
          : customStyles.separatorStrokeFinishedWidth,
      width: progressAnim,
    };
    return <Animated.View style={progressBarStyle} />;
  };

  const renderStepIndicator = () => {
    let steps = [];
    for (let position = 0; position < stepCount; position++) {
      steps.push(
        <TouchableWithoutFeedback key={position}>
          <View style={[styles.stepContainer, { flexDirection: 'row' }]}>{renderStep(position)}</View>
        </TouchableWithoutFeedback>,
      );
    }
    return (
      <View
        onLayout={(event) => {
          setWidth(event.nativeEvent.layout.width);
          setHeight(event.nativeEvent.layout.height);
        }}
        style={[
          styles.stepIndicatorContainer,
          {
            flexDirection: 'row',
            height: customStyles.currentStepIndicatorSize,
          },
        ]}
      >
        {steps}
        <View
          style={{
            position: 'absolute',
            left: '25%',
            height: 12,
            width: 12,
            backgroundColor: getStepStatus(0.5) !== 'unfinished' ? colors.green2 : colors.grey8,
            borderRadius: 6,
            outlineColor: colors.white,
            outlineStyle: 'solid',
            outlineWidth: 3,
          }}
        />
      </View>
    );
  };

  const renderStep = (position) => {
    let stepStyle;
    switch (getStepStatus(position)) {
      case STEP_STATUS.CURRENT: {
        stepStyle = {
          backgroundColor: customStyles.stepIndicatorCurrentColor,
          borderWidth: customStyles.currentStepStrokeWidth,
          borderColor: customStyles.stepStrokeCurrentColor,
          height: sizeAnim,
          width: sizeAnim,
          borderRadius: borderRadiusAnim,
          overflow: 'hidden',
        };
        break;
      }
      case STEP_STATUS.FINISHED: {
        stepStyle = {
          backgroundColor: customStyles.stepIndicatorFinishedColor,
          borderWidth: customStyles.stepStrokeWidth,
          borderColor: customStyles.stepStrokeFinishedColor,
          height: customStyles.finishedStepIndicatorSize,
          width: customStyles.finishedStepIndicatorSize,
          borderRadius: customStyles.finishedStepIndicatorSize / 2,
          overflow: 'hidden',
        };
        break;
      }

      case STEP_STATUS.UNFINISHED: {
        stepStyle = {
          backgroundColor: customStyles.stepIndicatorUnFinishedColor,
          borderWidth: customStyles.stepStrokeUnfinishedWidth,
          borderColor: customStyles.stepStrokeUnFinishedColor,
          height: staleSizeAnim,
          width: staleSizeAnim,
          borderRadius: customStyles.stepIndicatorSize / 2,
          overflow: 'hidden',
        };
        break;
      }
      default:
    }

    return (
      <Animated.View key={'step-indicator'} style={[styles.step, stepStyle]}>
        {renderCustomStepIndicator({
          position,
          stepStatus: getStepStatus(position),
        })}
      </Animated.View>
    );
  };

  const getStepStatus = (stepPosition) => {
    if (stepPosition === currentPosition) {
      return STEP_STATUS.CURRENT;
    } else if (stepPosition < currentPosition) {
      return STEP_STATUS.FINISHED;
    } else {
      return STEP_STATUS.UNFINISHED;
    }
  };

  const onCurrentPositionChanged = (position) => {
    if (position > stepCount - 1) {
      position = stepCount - 1;
    }
    const animateToPosition = (progressBarSize / (stepCount - 1)) * position;
    sizeAnim.setValue(customStyles.stepIndicatorSize);
    staleSizeAnim.setValue(customStyles.stepIndicatorSize);
    borderRadiusAnim.setValue(customStyles.stepIndicatorSize / 2);
    Animated.sequence([
      Animated.timing(progressAnim, {
        toValue: isNaN(animateToPosition) ? 0 : animateToPosition,
        duration: 1000,
        useNativeDriver: false,
      }),
      Animated.parallel([
        Animated.timing(sizeAnim, {
          toValue: customStyles.currentStepIndicatorSize,
          duration: 300,
          useNativeDriver: false,
        }),
        Animated.timing(borderRadiusAnim, {
          toValue: customStyles.currentStepIndicatorSize / 2,
          duration: 200,
          useNativeDriver: false,
        }),
      ]),
    ]).start();
  };

  return (
    <View style={[styles.indicatorContainer, { flexDirection: 'column' }]}>
      {width !== 0 && (
        <>
          {renderProgressBarBackground()}
          {renderProgressBar()}
        </>
      )}
      {renderStepIndicator()}
    </View>
  );
};

export default memo(StepIndicator);
