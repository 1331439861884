export const constants = {
  DEVICE_TOKEN: 'DEVICE_TOKEN',
  FCM_TOKEN: 'FCM_TOKEN',
  LOGGED_OUT: 'LOGGED_OUT',
  AUTH0_SUBDOMAIN: 'AUTH0_SUBDOMAIN',
  MFA_LIST: 'MFA_LIST',
};

export const userRoles = {
  ADMIN: 'admin',
};

export const chatTypes = {
  SLACK: 'slack',
  MS_TEAMS: 'msTeams',
  WINDOWS: 'windows',
};

export const INTEGRATIONS = {
  CLOUD_RADIAL: 'cloudradial',
  MESSENGER_AUTH: 'messenger_auth',
};

export const userFlows = {
  NEW_USER: 'NEW_USER',
  EXISTING_USER: 'EXISTING_USER',
};

export const MAX_CONNECTWISE_SUMMARY_LENGTH = 100;

export const APP_NAVIGATOR_STATES = {
  onload: 'onload',
  hasAppId: 'hasAppId',
  hasNoAppId: 'hasNoAppId',
};

export const NEW_REQUEST_STATES = ['all', 'request'];

export const LIVE_CHAT_STATES = ['all', 'live'];

export const THREAD_STATES = {
  LIVE: 'live',
  ACTIVE: 'active',
  DONE: 'done',
};

export const THREAD_STATE_FILTERS = {
  ACTIVE: [THREAD_STATES.ACTIVE, THREAD_STATES.LIVE],
  DONE: [THREAD_STATES.DONE],
  ALL: [THREAD_STATES.ACTIVE, THREAD_STATES.LIVE, THREAD_STATES.DONE]
};

export const MESSENGER_STATUS = {
  DISABLED: 'DISABLED',
  ENABLED: 'ENABLED',
};
