import React from 'react';
import PropTypes from 'prop-types';

import { StyledRow } from './style';

const Row = ({
  children, flexDirection, alignItems, justifyContent, flexGrow, ...rest
}) => (
  <StyledRow
    testID="row"
    flexDirection={flexDirection}
    alignItems={alignItems}
    justifyContent={justifyContent}
    flexGrow={flexGrow ? 1 : 0}
    {...rest}
  >
    {children}
  </StyledRow>
);

Row.defaultProps = {
  children: null,
  flexGrow: 0,
  alignItems: 'stretch',
  justifyContent: 'flex-start',
};

Row.propTypes = {
  children: PropTypes.node,
  flexGrow: PropTypes.number,
  alignItems: PropTypes.oneOf(['baseline', 'center', 'flex-end', 'flex-start', 'stretch']),
  justifyContent: PropTypes.oneOf([
    'center',
    'flex-end',
    'flex-start',
    'space-around',
    'space-between',
  ]),
};
export default Row;
