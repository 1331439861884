import * as Sentry from '@sentry/react-native';
import { userFlows } from 'src/constants';
import useProfile from 'src/hooks/useProfile';
import { chatgenieAPI } from 'src/lib/api/chatgenieApi';
import { getLiveChatSummary, isElectron } from 'src/utils';
import { normalizeTicket, normalizeUserInfoFromCompany } from 'src/utils/normalizer';
import webchatIframe from 'src/utils/webchatIframe';
import { useDispatchMapper } from 'src/hooks/actionHooks';
import { updateProfileAction } from 'src/redux/profile/profileActions';
import { setActiveTicketAction } from 'src/redux/chat/chatActions';
import { useNotification } from 'src/hooks/useNotification';
import { promptNotifPermission } from 'src/utils/desktop-notification';
import useChatState from 'src/screens/Chat/hooks/useChatState';
import { reportDeviceService } from 'src/lib/api/connectWise';
import useConnectTwilioChannel from 'src/hooks/useConnectTwilioChannel';
import useTranslation from 'src/hooks/useTranslation';
import { quickReplyIds, quickReplyMessages } from 'src/constants/messages';

const isNewUserFlow = (flow) => flow === userFlows.NEW_USER;

export const useUserFlowUtils = ({ addBotMessage, state, updateState }) => {
  const { email, flow, connection, phoneNumberTip } = state;
  const { companyInfo } = useProfile();
  const { translate } = useTranslation();
  const saveProfile = useDispatchMapper(updateProfileAction);
  const saveActiveTicket = useDispatchMapper(setActiveTicketAction);
  const { connectChannel } = useConnectTwilioChannel();
  const {
    setActiveChannel,
    chatState: { activeChannel: channel },
  } = useChatState();
  const { permission } = useNotification();
  const sendOTP = async (data) => {
    try {
      const payload = {
        email: data?.email,
        company_name: data?.companyName,
        connection: data?.connection || 'email',
        fullname: data?.fullname,
        send: 'code',
      };

      const result = await chatgenieAPI.sendCode(payload);
      if (result.status === 200) {
        updateState({ codeSent: true });
        if (phoneNumberTip && data?.connection === 'sms') {
          addBotMessage(translate('bot.codeSent.sms', phoneNumberTip));
        } else {
          addBotMessage(translate('bot.codeSent'));
        }
      }
      return result;
    } catch (err) {
      Sentry.captureException(err);
      const errorMessage = err.response?.data?.message;
      addBotMessage(errorMessage || translate('bot.errorMessage'));
      // phone authentication failed
      if (data?.connection === 'sms') {
        updateState({ smsAuthenticationFailed: true, connection: 'email' });
      }
      return null;
    }
  };

  const joinTwilioChannel = async (existingTicket, messageHandler) => {
    if (!existingTicket) return null;
    const connectedChannel = await connectChannel(existingTicket.channel);
    if (connectedChannel) {
      connectedChannel.subscribeNewMessages(messageHandler);
      setActiveChannel(connectedChannel);
      return connectedChannel;
    }
    return null;
  };

  const createTicket = async (summary, twilioMessageHandler) => {
    const payload = {
      summary: getLiveChatSummary(summary),
      board: 'sales',
      initialDescription: '',
      state: 'live',
    };
    const data = await reportDeviceService({ payload });
    if (data?.channel_name) {
      saveActiveTicket({ ...data, ...normalizeTicket(data.ticket) });
      return joinTwilioChannel(
        {
          channel: data?.channel_name,
          id: data?.ticket_id,
        },
        twilioMessageHandler,
      );
    }
    return null;
  };

  const selectIfOneCompany = (contactCompanies) => {
    try {
      if (!contactCompanies?.length && !isNewUserFlow(flow)) {
        return null;
      }
      let companyPayload = null;
      if (contactCompanies?.length === 1 || (contactCompanies?.length && isNewUserFlow(flow))) {
        companyPayload = {
          ...companyInfo,
          id: contactCompanies?.[0]?.id,
          parentId: contactCompanies?.[0]?.parent_id,
          appId: contactCompanies?.[0]?.app_id,
        };
        if (window) {
          window?.ipc?.send('store-data', {
            key: 'companyInfo',
            value: companyPayload,
          });
          webchatIframe.updateCompanyInfo({
            appId: companyPayload?.appId,
          });
        }
      }
      const profilePayload = {
        userInfo: {
          email,
        },
        loggedIn: flow === userFlows.EXISTING_USER,
        flow,
      };
      if (companyPayload) {
        profilePayload.companyInfo = companyPayload;
        profilePayload.userInfo = {
          email,
          ...normalizeUserInfoFromCompany(contactCompanies?.[0]?.contacts?.[0]),
        };
      }
      saveProfile(profilePayload);
      if (!contactCompanies.length) {
        addBotMessage(translate('bot.contactSupport'));
        updateState({ inputConfig: { editable: false } });
      }
      return companyPayload;
    } catch (err) {
      addBotMessage(translate('bot.contactSupport'));
    }
    return null;
  };

  const fetchAndSelectCompany = async () => {
    try {
      const response = await chatgenieAPI.getCompanies();
      const companies = response.data?.filter((comp) => !!comp?.is_contact) || [];
      return selectIfOneCompany(companies);
    } catch (err) {
      console.log(err);
    }
    return null;
  };

  const validateOTP = (otpCode) =>
    new Promise(async (resolve, reject) => {
      const code = otpCode.toString();
      let token;
      try {
        const response = await chatgenieAPI.verifyCode({
          email,
          realm: connection,
          otp: code,
          audience: 'chatgenie',
          scope: 'openid email profile offline_access',
        });
        token = response?.data?.data?.access_token;
        if (token) {
          Sentry.setUser({ email });
          Sentry.setExtras({
            mspAppId: companyInfo?.appId,
          });
          updateState({ inputConfig: null, authed: true });
          saveProfile({
            token,
            refreshToken: response?.data?.data?.refresh_token,
          });
        }
      } catch (err) {
        addBotMessage(quickReplyMessages()[quickReplyIds.AUTHENTICATION_CODE_REENTER]);
      }

      if (token) {
        if (permission === 'default') {
          addBotMessage(translate('bot.notificationPermission2'));
          if (isElectron()) {
            promptNotifPermission();
          } else {
            webchatIframe.requestNotificationPermission();
          }
          setTimeout(async () => {
            const company = await fetchAndSelectCompany();
            resolve({ token, company });
          }, 5000);
        } else {
          const company = await fetchAndSelectCompany();
          resolve({ token, company });
        }
      }
    });

  return {
    createTicket,
    validateOTP,
    sendOTP,
    channel,
    selectIfOneCompany,
  };
};
