/* eslint-disable camelcase */
/* eslint-disable no-nested-ternary */
import React from 'react';
import { Linking, PermissionsAndroid, Platform, StatusBar } from 'react-native';
import { isIphoneX } from 'react-native-iphone-x-helper';
import { find, keyBy } from 'lodash';
import moize from 'moize';
import { EvilIcons, Feather, Ionicons } from 'src/components/Common';
import { defaultWorkspaceColors } from 'src/lib/theme';
import Config from 'react-native-config';
import Color from 'color';
import { getVersion } from 'react-native-device-info';
import { MAX_CONNECTWISE_SUMMARY_LENGTH } from 'src/constants';
import { UITranslations } from 'src/translations';
import jwtDecode from 'jwt-decode';

export function isWeb() {
  return Platform.OS === 'web';
}

export const isMobileWeb = () => {
  let check = false;
  (function (a) {
    if (
      /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(
        a,
      ) ||
      /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
        a?.substr(0, 4),
      )
    )
      check = true;
  })(window?.navigator?.userAgent || window?.navigator?.vendor || window?.opera);
  return check;
};
export const isIOS = () => Platform.OS === 'ios';

export const isAndroid = () => Platform.OS === 'android';

export const isMobile = () => isIOS() || isAndroid();

export const isElectron = () => window?.navigator?.userAgent?.toLowerCase()?.includes('electron');

export const isInsideMSTeams = () => {
  try {
    return !!document.querySelector('[data-mount-type="teams"]');
  } catch {
    return false;
  }
};

export function isOnlyWeb() {
  return isWeb() && !isElectron();
}

export const getMessengerSource = () => {
  return isInsideMSTeams()
    ? 'messenger_teams'
    : isMobile()
    ? 'messenger_mobile'
    : isElectron()
    ? 'messenger_agent'
    : 'messenger_widget';
};

export const getOSName = () => {
  const userAgent = navigator?.userAgent;

  if (typeof userAgent !== 'string' && !userAgent?.length) return null;

  const isWindows = userAgent.includes('Windows');
  const isMac = userAgent.includes('Macintosh') || userAgent.includes('Mac OS');
  const isLinux = userAgent.includes('Linux');

  if (isWindows) return 'windows';
  if (isMac) return 'macOS';
  if (isLinux) return 'linux';

  return null;
};

export const isProduction = () => process.env.NODE_ENV === 'production';

export const STATUSBAR_HEIGHT = isIOS() ? (isIphoneX() ? 44 : 20) : StatusBar.currentHeight;

export const getAppName = () => {
  if (isIOS()) return 'iOS';
  if (isAndroid()) return 'Android';
  try {
    if (isElectron()) return require('os').platform();
  } catch {
    console.log('nodejs => "os" is not available in react-native');
  }
  // TODO: we can get browser from navigator.userAgentData
  if (isWeb()) return 'Web';

  return 'Unknown platform';
};

export const getAppVersion = () => {
  if (isIOS() || isAndroid()) return getVersion();

  if (isElectron()) return window?.APP_VERSION;

  return Config.APP_VERSION;
};

export async function requestLocationPermissionAndroid() {
  try {
    let granted = await PermissionsAndroid.check(PermissionsAndroid.PERMISSIONS.ACCESS_COARSE_LOCATION);
    if (!granted || granted !== PermissionsAndroid.RESULTS.GRANTED) {
      granted = await PermissionsAndroid.request(PermissionsAndroid.PERMISSIONS.ACCESS_COARSE_LOCATION, {
        title: 'Thread Location Permission',
        message: 'Thread needs location permission to be able to show you the closest office.',
        buttonNegative: 'Cancel',
        buttonPositive: 'OK',
      });
    }
    return granted === PermissionsAndroid.RESULTS.GRANTED;
  } catch (err) {
    console.warn(err);
    return false;
  }
}

export function validateEmail(email) {
  const re =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
}

export function validateFullName(fullName = '') {
  const regExp = /^\S+\s+\S+$/;
  return regExp.test(fullName);
}

export const memoizedDescription = moize((array) => find(array, { detailDescriptionFlag: true }));

export const memoizeComments = moize((array) => {
  if (Array.isArray(array) && array.length) return array.reverse();
  return [];
});

export const objectToArrayMemoized = moize((array, keyName) =>
  Array.isArray(array) && keyName ? keyBy(array, keyName) : null,
);

const logoMapper = {
  mobile: (color) => <Ionicons name="ios-phone-portrait" size={40} color={color} />,
  workstation: (color) => <Ionicons name="ios-desktop" size={40} color={color} />,
  server: (color) => <Feather name="server" size={40} color={color} />,
  others: (color) => <EvilIcons name="gear" size={40} color={color} />,
};

export const getDeviceIcon = (type, color) => {
  if (!type) return logoMapper.others;
  if (/cellphone|mobile/gim.test(type)) {
    return logoMapper.mobile(color);
  }
  if (/server/gim.test(type)) {
    return logoMapper.server(color);
  }
  if (/desktop|workstation|computer/gim.test(type)) {
    return logoMapper.workstation(color);
  }
  return logoMapper.others(color);
};

export const IS_IOS = Platform.OS === 'ios';
export const IS_ANDROID = Platform.OS === 'android';

export const getCompanyColors = (data) => {
  const newColors = {
    primaryColor: data?.primaryColor,
    secondaryColor: data?.secondaryColor,
  };
  if (!newColors.primaryColor) {
    newColors.primaryColor = defaultWorkspaceColors.primaryColor;
  }
  if (!newColors.secondaryColor) {
    newColors.secondaryColor = defaultWorkspaceColors.secondaryColor;
  }
  return newColors;
};

export const getTimeText = (locale) => {
  const translations = UITranslations[locale];
  const currentTime = new Date();
  let greetingText = translations['time.evening'];
  if (currentTime.getHours() < 12) {
    greetingText = translations['time.morning'];
  }
  if (currentTime.getHours() >= 12 && currentTime.getHours() <= 18) {
    greetingText = translations['time.afternoon'];
  }
  return greetingText;
};

export const getProperImageSize = (sourceWidth, sourceHeight, localProps) => {
  const { width, height } = localProps;

  let ratio = 1;

  if (width && height) {
    ratio = Math.min(width / sourceWidth, height / sourceHeight);
  } else if (width) {
    ratio = width / sourceWidth;
  } else if (height) {
    ratio = height / sourceHeight;
  }

  const computedWidth = sourceWidth * ratio;
  const computedHeight = sourceHeight * ratio;

  return {
    height: computedHeight,
    width: computedWidth,
  };
};

export const isCompanyProtek = (parentId) => parentId?.toString() === Config.PROTECH_COMPANY_ID?.toString();

export const isUserAppleTester = (email) => email?.toLowerCase() === Config.APPLE_TEST_EMAIL && isIOS();
export const sendHtmlNotification = (config) => {
  // HTML5 notification
  if (isWeb() && Notification) {
    const myNotification = new Notification(config?.title, {
      body: config?.body,
      data: config?.data,
      icon: config?.icon,
    });

    if (config?.onClick) {
      myNotification.onclick = config?.onClick;
    }
  }
};

export const getCloseBtnBgColor = (bgColor) =>
  Color(bgColor).isDark() ? Color(bgColor).lighten(0.3).hex() : Color(bgColor).darken(0.3).hex();

export const openUrl = (url) => {
  if (isWeb()) {
    window?.open(url, '_blank');
  } else {
    Linking.openURL(url);
  }
};

export const getSlicedString = (text, size = 30) => `${text}`?.slice(0, size) + (text?.length > size ? '...' : '');

export const isStringObject = (str) => {
  try {
    return typeof JSON.parse(str) === 'object';
  } catch (err) {
    return false;
  }
};

export function nameToInitials(fullName = '') {
  if (!fullName?.length) return '';
  const namesArray = fullName.trim().split(' ');
  if (namesArray.length === 1) return `${namesArray[0].charAt(0)}`;
  return `${namesArray[0].charAt(0)}${namesArray[namesArray.length - 1].charAt(0)}`;
}

export const LIVE_CHAT_PREFIX = 'Live Chat:';
export const OUTDATED_CHAT_PREFIX = 'Re:';

export const getLiveChatSummary = (summary) => {
  if (!summary) return '';

  const trimmedSummary = summary.trim();
  // tickets which are reopened after 15 days include "Re:" in the beginning
  const isOutdatedTicket = trimmedSummary.startsWith(OUTDATED_CHAT_PREFIX);
  const formatted = isOutdatedTicket ? trimmedSummary : `${LIVE_CHAT_PREFIX} ${trimmedSummary}`;
  if (formatted.length < MAX_CONNECTWISE_SUMMARY_LENGTH) {
    return formatted;
  }
  return formatted.substr(0, formatted.lastIndexOf(' ', MAX_CONNECTWISE_SUMMARY_LENGTH));
};

export const isTestingEnv = ['staging', 'development'].includes(process.env.REACT_APP_ENV);

export function getEmailFromToken(token) {
  if (!token) return;

  try {
    const decodedToken = jwtDecode(token);
    const emailKey = Object.keys(decodedToken).find((key) => key.includes('email'));

    return decodedToken[emailKey];
  } catch (error) {
    return null;
  }
}
