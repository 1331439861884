import { StyleSheet } from 'react-native';

const styles = StyleSheet.create({
  createBtn: {
    paddingVertical: 10,
    paddingHorizontal: 15,
  },
  title: { marginRight: 20 },
  dropdownBtn: { width: '78%', height: 50 },
  formField: { paddingHorizontal: 19, minHeight: 60, backgroundColor: 'white' },
  summary: {
    flexGrow: 1,
    flexShrink: 1,
    height: 35,
    paddingHorizontal: 5,
  },
  description: {
    padding: 10,
    textAlignVertical: 'top',
  },
});

export default styles;
