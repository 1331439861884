export const styles = {
  wrapper: {
    flexGrow: 1,
    alignItems: 'center',
    padding: 45,
  },
  disabledText: {
    textAlign: 'center',
    marginTop: 20,
  },
};
