import actionTypes from 'src/constants/actionTypes';
import webchatIframe from 'src/utils/webchatIframe';
import { updateDesktopNotificationCount } from 'src/hooks/utils/pushNotification.utils';
import { isElectron } from 'src/utils';

const getTotalUnreadMessages = (tickets = {}) => {
  if (!tickets || !Object.values(tickets).length) return 0;
  return Object.values(tickets).reduce((acc, cur) => acc + cur, 0);
};

export const addTicketNotificationAction = (ticketId) => (dispatch, getState) => {
  const { notifications, chat } = getState();
  const { tickets, enabled, permission } = notifications;
  const currentOpenTicket = chat.activeTicket?.id?.toString();
  const isTicketOpen = currentOpenTicket && currentOpenTicket === ticketId;
  if (!isTicketOpen && ticketId && enabled && permission === 'granted') {
    const ticketUnreadMessagesCount = (tickets[ticketId] || 0) + 1;

    dispatch({
      type: actionTypes.UPDATE_TICKETS_NOTIFICATION,
      payload: { ...tickets, [ticketId]: ticketUnreadMessagesCount },
    });
    if (isElectron()) {
      const totalUnreadMessages = getTotalUnreadMessages(tickets);
      updateDesktopNotificationCount(totalUnreadMessages + 1);
    } else {
      webchatIframe.updateNotificationCount(1);
    }
  }
};

export const removeTicketNotificationAction = (ticketId) => (dispatch, getState) => {
  const { tickets } = getState().notifications;
  if (tickets?.[ticketId]) {
    const ticketUnreadMessagesCount = tickets[ticketId] || 0;
    const ticketsClone = { ...tickets };
    delete ticketsClone[ticketId];
    dispatch({
      type: actionTypes.UPDATE_TICKETS_NOTIFICATION,
      payload: ticketsClone,
    });
    if (isElectron()) {
      const totalUnreadMessages = getTotalUnreadMessages(tickets);
      updateDesktopNotificationCount(totalUnreadMessages - ticketUnreadMessagesCount);
    } else {
      webchatIframe.updateNotificationCount(-ticketUnreadMessagesCount);
    }
  }
};

export const resetTicketNotificationAction = () => (dispatch, getState) => {
  const { notifications } = getState();
  if (notifications.enabled && notifications.permission === 'granted') {
    dispatch({
      type: actionTypes.UPDATE_TICKETS_NOTIFICATION,
      payload: {},
    });
    webchatIframe.updateNotificationCount(0);
  }
};

export const saveNotificationPermissionAction = (payload) => ({
  type: actionTypes.SAVE_NOTIFICATION_PERMISSION,
  payload,
});

export const updateNotificationEnabledAction = (payload) => ({
  type: actionTypes.UPDATE_NOTIFICATION_ENABLED,
  payload,
});
