import React from 'react';
import PropTypes from 'prop-types';
import Markdown, { MarkdownIt } from 'react-native-markdown-display';
import useTheme from 'src/hooks/useTheme';
import { getMarkdownMentionStyles, markdownStyles } from './style';
import Common from 'src/components/Common';
import Hoverable from 'src/components/Hoverable';
import { isWeb } from 'src/utils';
import { replaceMentionTags, MENTIONS_REGEX } from 'src/utils/mention.utils';

const customMarkDownIt = MarkdownIt({ typographer: true, linkify: true });

const onLinkPress = (url) => {
  if (isWeb() && url) {
    window.open(url, '_blank');
    return false;
  }
  return true;
};

const renderHeadingText = (node, children) => (
  <Common.Text key={node.key} style={markdownStyles.heading}>
    {children}
  </Common.Text>
);

const renderLink = (node, children) => (
  <Common.Text style={markdownStyles.link} key={node.key} onPress={() => onLinkPress(node.attributes.href)}>
    {children}
  </Common.Text>
);

const renderCodeBlock = (node, children) => (
  <code key={node.key} style={markdownStyles.code}>
    {node.content}
  </code>
);

const renderText = (node, children) => {
  const { theme } = useTheme();

  if (node.type === 'text') {
    const customPatternRegex = /<@[^|]+\|([^>]+)>/g;
    const contactMatch = new RegExp(MENTIONS_REGEX.CONTACT);

    // first part matches contacts and members: @[^|]+\|([^>]+)
    // second part matches special mentions: !([^>]+)
    const unifiedPatternRegex = new RegExp(MENTIONS_REGEX.COMBINED);

    const matches = node.content.match(unifiedPatternRegex);

    if (matches) {
      const parts = node.content.split(customPatternRegex);
      const elements = [];
      let index = 0;

      for (const match of matches) {
        const startIndex = node.content.indexOf(match, index);
        const endIndex = startIndex + match.length;

        // regular text that comes before mentions tag
        if (startIndex > index) {
          elements.push(node.content.substring(index, startIndex));
        }

        const isContact = contactMatch.test(match);
        // actual mentions tag
        elements.push(
          <Hoverable>
            {(isHovered) => (
              <Common.Text key={startIndex + node.key} style={getMarkdownMentionStyles({ isHovered, isContact })}>
                {replaceMentionTags(match)}
              </Common.Text>
            )}
          </Hoverable>,
        );

        index = endIndex;
      }

      // regular text that comes after mentions tag
      if (index < node.content.length) {
        elements.push(node.content.substring(index));
      }

      return elements;
    }
  }

  // regular text which does not include mentions
  return <Common.Text key={node.key}>{node.content}</Common.Text>;
};

const rules = {
  heading1: renderHeadingText,
  heading2: renderHeadingText,
  heading3: renderHeadingText,
  link: renderLink,
  code_inline: renderCodeBlock,
  text: renderText,
};

function MarkdownDisplay({ text = '' }) {
  const { colors, theme } = useTheme();
  return (
    <Markdown
      rules={rules}
      testID="markdown-message-text"
      markdownit={customMarkDownIt}
      onLinkPress={onLinkPress}
      style={{
        paragraph: markdownStyles.paragraph,
        body: { ...markdownStyles.body, color: colors.text },
        link: { color: theme.primaryColor },
        textgroup: markdownStyles.textgroup,
      }}
    >
      {text}
    </Markdown>
  );
}
MarkdownDisplay.propTypes = {
  text: PropTypes.string.isRequired,
};
export default MarkdownDisplay;
