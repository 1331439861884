import React, { useEffect, useMemo, useState } from 'react';
import { usePostHog } from 'posthog-js/react';
import { View, Modal, Text } from 'react-native';
import Config from 'react-native-config';
import { USER_PROFILE_ONBOARDING_STATES as ONBOARDING_STATES } from 'src/redux/profile/profileStateConstants';
import useProfile from 'src/hooks/useProfile';
import useTranslation from 'src/hooks/useTranslation';
import { getMessengerSource, isElectron } from 'src/utils';
import { exchangeAuthCodeForToken, initMessenger } from 'src/utils/sign-in';
import CompanyImage from 'src/components/CompanyImage';
import Common from 'src/components/Common';
import { TouchableOpacity } from '../Common';
import { LockerIcon, MicrosoftIcon } from 'src/assets/icons';
import { styles } from './style';

const SignInModal = () => {
  const postHog = usePostHog();
  const { onboarding, loggedIn, companyInfo } = useProfile();
  const { onboardingState } = onboarding || {};
  const [authCode, setAuthCode] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [shouldSignInWithEmail, setShouldSignInWithEmail] = useState(false);
  const { translate } = useTranslation();

  const isModalVisible = useMemo(() => {
    if (shouldSignInWithEmail) return false;
    if (companyInfo?.m365Integrated === false) return false;

    const isOnboardingCompleted = onboardingState === ONBOARDING_STATES.DESKTOP_ONBOARDING_COMPLETED;
    const isOnboardingSkipped = onboardingState === ONBOARDING_STATES.DESKTOP_ONBOARDING_SKIPPED;
    const isOnboardingFinished = isOnboardingCompleted || isOnboardingSkipped;

    return isElectron() && !loggedIn && isOnboardingFinished;
  }, [loggedIn, onboardingState, shouldSignInWithEmail, companyInfo?.m365Integrated]);

  const signInWithM365 = () => {
    window?.ipc?.invoke(
      'open-in-default-browser',
      `${Config.BASE_DOMAIN}/auth-start?platform=desktop&appId=${companyInfo.appId}`,
    );
  };

  const signInWithEmail = () => {
    setShouldSignInWithEmail(true);
  };

  if (isElectron() && window.dataUIBridge) {
    window.dataUIBridge.authCode(async (_, authCode) => {
      setAuthCode(authCode);
      setIsLoading(true);
    });
  }

  const signIn = async (authCode, appId) => {
    try {
      if (!authCode) return;

      const data = await exchangeAuthCodeForToken(authCode);

      const sessionApp = getMessengerSource();
      await postHog.capture('m365_signin_success', {
        app_id: companyInfo.appId,
        session_app: sessionApp,
      });

      initMessenger(appId, data.access_token);
    } catch (e) {
      setIsLoading(false);
      setAuthCode(null);
      console.log(e);
    }
  };

  useEffect(() => {
    signIn(authCode, companyInfo.appId);
  }, [authCode, companyInfo.appId]);

  useEffect(() => {}, [loggedIn]);

  useEffect(() => {
    if (loggedIn) {
      setAuthCode(null);
    }

    // NB: for a fluid UI experience, we need to show the spinner until the user is logged in
    if (isModalVisible && !loggedIn) {
      setIsLoading(false);
    }
  }, [isModalVisible, loggedIn]);

  if (isLoading) {
    return (
      <Modal visible={isModalVisible} animationType="fade" transparent>
        <View activeOpacity={1} style={styles.wrap}>
          <Common.Spinner />
        </View>
      </Modal>
    );
  }

  return (
    <Modal visible={isModalVisible} animationType="fade" transparent>
      <View activeOpacity={1} style={styles.wrap}>
        <View style={styles.mediaArea}>
          <View style={styles.companyLogoWrap}>
            <CompanyImage />
          </View>

          <View style={styles.lockerIconWrap}>
            <LockerIcon />
          </View>
        </View>
        <View style={styles.textArea}>
          <Text style={styles.textTitle}>{translate('signin.title')}</Text>
          <Text style={styles.textDesc}>{translate('signin.desc')}</Text>
          <TouchableOpacity style={styles.btnSignInMicrosoft} onPress={signInWithM365}>
            <Text style={styles.commonTxt}>
              <div style={styles.buttonContainer}>
                <MicrosoftIcon style={styles.microsoftIcon} /> {translate('signin.m365.button')}
              </div>
            </Text>
          </TouchableOpacity>
          <br />
          <TouchableOpacity style={styles.loginEmail} onPress={signInWithEmail}>
            <Text style={styles.commonTxt}>{translate('signin.email.button')}</Text>
          </TouchableOpacity>
        </View>
      </View>
    </Modal>
  );
};

export default SignInModal;
