import React, { useEffect, useMemo, useState } from 'react';
import { Image } from 'react-native';
import useProfile from 'src/hooks/useProfile';
import useTheme from 'src/hooks/useTheme';
import { getProperImageSize, isWeb } from 'src/utils';

const getProperSource = (companyInfo, isDarkMode) => {
  if (companyInfo.image) return { uri: companyInfo.image };
  const defaultLogo = isDarkMode
    ? require('src/assets/images/logo-white.png')
    : require('src/assets/images/new-logo.png');
  if (isWeb()) return { uri: defaultLogo };
  return defaultLogo;
};

const CompanyImage = ({ ...props }) => {
  const { companyInfo } = useProfile();
  const { isDarkMode } = useTheme();
  const source = useMemo(() => getProperSource(companyInfo, isDarkMode), [companyInfo, isDarkMode]);
  const [dimensions, setDimensions] = useState(null);
  useEffect(() => {
    if (source?.uri) {
      Image.getSize(source?.uri, (width, height) => {
        const result = getProperImageSize(width, height, { height: 27 });
        setDimensions(result);
      });
    } else if (source) {
      const { width, height } = Image.resolveAssetSource(source);
      const result = getProperImageSize(width, height, { height: 27 });
      setDimensions(result);
    }
  }, []);
  return (
    <Image
      testID="company-image"
      source={source}
      style={{ width: dimensions?.width || 0, height: dimensions?.height || 0 }}
      resizeMode="contain"
      {...props}
    />
  );
};

export default React.memo(CompanyImage);
