import React from 'react';
import Common from 'src/components/Common';
import useProfile from 'src/hooks/useProfile';
import styles from './styles';
import { format, isToday, isSameWeek } from 'date-fns';
import useTranslation from 'src/hooks/useTranslation';

export default function AvailabilityBanner() {
  const { companyInfo: { availability } = {} } = useProfile();
  const availableAt = availability?.available_in?.timestamp;
  const { translate } = useTranslation();

  const renderAvailabilityTimeText = () => {
    const date = new Date(availableAt);
    const today = isToday(date);
    const sameWeek = isSameWeek(date, new Date(), { weekStartsOn: 1 });
    const zeroMinute = format(date, 'm') == 0;
    return (
      `${today ? ' today' : sameWeek ? ' on ' : ' next week on '}` +
      `${!today ? format(date, 'EEEE') : ''} at ${format(date, 'h')}` +
      `${!zeroMinute ? ':' + format(date, 'mm') : ''} ${format(date, 'aaa')}`
    );
  };

  return availability?.status === false && availableAt ? (
    <Common.View style={styles.wrapper}>
      <Common.Text style={styles.text} data-testid="text-offline-message">
        {`${translate('bot.offline.message')}${renderAvailabilityTimeText()}.`}
      </Common.Text>
    </Common.View>
  ) : null;
}
