import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { TouchableOpacity } from 'react-native';
import Common from 'src/components/Common';
import useTheme from 'src/hooks/useTheme';
import { colors } from 'src/constants/theme';
import SafeAreaView from 'react-native-safe-area-view';
import styles, { dragDropStyles } from '../style';
import AttachmentList from '../Attachments/AttachmentList';
import AttachmentButton from '../Attachments/AttachmentButton';
import { useDropzone } from 'react-dropzone';
import { trackEvent } from 'src/utils/analytics/GAtracker';
import { getImageDimensions, getImagePreview, MAX_ATTACHMENT_SIZE, useClipboardFiles } from './utils';
import useTranslation from 'src/hooks/useTranslation';
import { MentionInput } from 'src/components/Chatbox/MessageInput/Mentions/MentionInput';

export default function Textarea({
  canSendAttachment,
  inputProps,
  onChangeAttachments,
  attachments,
  isDisabled,
  handleShowWarning,
  isSendBtnDisabled,
  onComment,
  isAuthChat,
}) {
  const { colors: themeColors, theme, isDarkMode } = useTheme();
  const { translate } = useTranslation();
  const inputAreaRef = useRef();
  const sendBtnRef = useRef();

  const handleChange = async (files, event) => {
    try {
      trackEvent({
        category: 'Attachment',
        action: `accept: ${event?.type || 'pick'}`,
      });
      const attachmentsWithPreview = await Promise.all(
        files
          .filter((el) => {
            const fileSize = el.size;
            if (fileSize > MAX_ATTACHMENT_SIZE) {
              handleShowWarning();
              trackEvent({
                category: 'Attachment',
                action: `reject: file-too-large`,
              });
              return false;
            }
            return true;
          })
          .map(async (attachment, index) => {
            const attachmentObj = {
              file: attachment,
              index,
              id: `${attachment.name}${attachment.size}`,
            };
            const isImage = attachment.type.includes('image');
            if (isImage) {
              const preview = await getImagePreview(attachment);
              const { width, height } = await getImageDimensions(preview);
              return {
                ...attachmentObj,
                width,
                height,
                preview,
              };
            }
            return attachmentObj;
          }),
      );
      onChangeAttachments(attachmentsWithPreview);
    } catch (err) {
      console.log('err', err);
    }
  };

  const handleReject = (files) => {
    const error = files?.[0]?.errors?.[0]?.code;
    trackEvent({
      category: 'Attachment',
      action: `reject: ${error}`,
    });
    console.log('error', error, files);
    if (error === 'file-too-large') {
      handleShowWarning();
    }
  };

  const {
    getRootProps,
    getInputProps,
    isDragAccept,
    open: openFilePicker,
  } = useDropzone({
    onDropAccepted: handleChange,
    onDropRejected: handleReject,
    noClick: true,
    noKeyboard: true,
    maxSize: MAX_ATTACHMENT_SIZE,
    disabled: !canSendAttachment,
  });

  // Pasting files from clipboard
  useClipboardFiles(
    (files) => {
      if (canSendAttachment) {
        handleChange(files);
        trackEvent({
          category: 'Attachment',
          action: `accept: clipboard`,
        });
      }
    },
    [attachments, canSendAttachment],
  );

  const shouldShowMentionsInput = !isAuthChat;

  return (
    <div {...getRootProps()} style={dragDropStyles.container}>
      <div ref={inputAreaRef}>
        {isDragAccept && <div style={dragDropStyles.activePlaceholder}>{translate('common.files.drag')}</div>}
        <input {...getInputProps()} />
        <SafeAreaView
          style={[styles.safeArea, { backgroundColor: themeColors.foreground }, isDarkMode && { shadowOpacity: 0 }]}
          forceInset={{ top: 'never', bottom: 'always' }}
        >
          <Common.View style={styles.actions}>
            {canSendAttachment && (
              <AttachmentButton
                isDisabled={isDisabled}
                onPress={openFilePicker}
                mentionsEnabled={shouldShowMentionsInput}
              />
            )}
            {shouldShowMentionsInput ? (
              <MentionInput
                parentInputProps={inputProps}
                isDisabled={isDisabled}
                maxAreaWidth={inputAreaRef.current?.offsetWidth}
                sendBtnWidth={sendBtnRef.current?.offsetWidth}
                sendBtnRef={sendBtnRef}
              />
            ) : (
              <Common.Input
                style={[
                  styles.textInput,
                  {
                    backgroundColor: isDarkMode ? themeColors.foreground : themeColors.foreground,
                    color: themeColors.text,
                    paddingLeft: canSendAttachment ? 0 : 18,
                  },
                ]}
                {...inputProps}
              />
            )}
            <TouchableOpacity style={styles.sendBtn} disabled={isSendBtnDisabled} onPress={onComment} ref={sendBtnRef}>
              <Common.Text color={isSendBtnDisabled ? colors.grey : theme.primaryColor} bold>
                {translate('common.text.send')}
              </Common.Text>
            </TouchableOpacity>
          </Common.View>
          {canSendAttachment && <AttachmentList attachments={attachments} onChangeAttachments={onChangeAttachments} />}
        </SafeAreaView>
      </div>
    </div>
  );
}

Textarea.propTypes = {
  isAuthChat: PropTypes.bool,
  inputProps: PropTypes.object,
  onChangeAttachments: PropTypes.func,
  attachments: PropTypes.array,
  isDisabled: PropTypes.bool,
  handleShowWarning: PropTypes.func,
  isSendBtnDisabled: PropTypes.bool,
  onComment: PropTypes.func,
};
