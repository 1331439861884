/* istanbul ignore file */

import { getMyDevices } from 'src/lib/api/connectWise';
import reactQueryKeys from 'src/constants/reactQueryKeys';
import { isWeb } from 'src/utils';
import useProfile from './useProfile';
import { useFetchInfinite } from './useFetch';

const useGetDevices = () => {
  const { userInfo, companyInfo } = useProfile();
  return useFetchInfinite(
    userInfo && companyInfo && [
      reactQueryKeys.DEVICES,
      { userId: userInfo?.id, role: userInfo?.role, companyId: companyInfo?.id },
    ],
    getMyDevices,
    {
      refetchInterval: isWeb() ? 15000 : false,
    },
  );
};

export default useGetDevices;
