import styled from 'styled-components/native';

const Card = styled.View`
  border-radius: 5px;
  margin: 5px 0px;
  width: 95%;
  align-self: center;
  border: none;
  box-shadow: 0px 3px 5px  rgba(0,0,0,0.1);
  elevation: 2;
`;

export default Card;
