import React from 'react';
import navigationService from 'src/utils/navigation';
import routeNames from 'src/constants/routeNames';
import { ChatIcon } from 'src/assets/icons';
import Common from 'src/components/Common';
import useTranslation from 'src/hooks/useTranslation';
import useTheme from 'src/hooks/useTheme';
import useProfile from 'src/hooks/useProfile';

export const NewChatButton = ({ btnStyle = {}, labelStyle = {} }) => {
  const { theme } = useTheme();
  const { translate } = useTranslation();
  const { companyInfo } = useProfile();
  const isOutsideOfBusinessHours = companyInfo?.availability?.status === false;

  return (
    <Common.TouchableOpacity
      style={btnStyle}
      onPress={() => navigationService.navigate(routeNames.CHAT, { isNewRequest: isOutsideOfBusinessHours })}
    >
      <ChatIcon fill={theme.primaryColor} />
      <Common.Text style={labelStyle} color={theme.primaryColor}>
        {translate('ticket.liveChat.text')}
      </Common.Text>
    </Common.TouchableOpacity>
  );
};
