/* eslint-disable camelcase */
import { useEffect, useRef, useState } from 'react';
import { Keyboard } from 'react-native';
import usePrevious from 'src/hooks/usePrevious';
import { quickReplyIds, quickReplyMessages } from 'src/constants/messages';
import { MESSENGER_STATUS, userFlows } from 'src/constants';
import { useAuth } from 'src/hooks/useAuth';
import { isWeb, validateEmail } from 'src/utils';
import useProfile from 'src/hooks/useProfile';
import useMessageHandlers from './useMessageHandlers';
import { useExistingUserFlow } from './useExistingUserFlow';
import { useNewUserFlow } from './useNewUserFlow';
import useTranslation from 'src/hooks/useTranslation';

const useAuthenticationFlow = () => {
  const chatboxRef = useRef(null);
  const [messages, setMessages] = useState([]);
  const { state, updateState, isEmailFound } = useAuth();
  const { companyInfo, userInfo, companyBasicInfoLoading } = useProfile();
  const { translate, locale } = useTranslation();

  const saveMessage = (newMessage) => setMessages((prev) => [...prev, newMessage]);

  const { addMyMessage, addBotMessage, addMessage, onTwilioMessage } = useMessageHandlers({
    saveMessage,
    contactId: userInfo?.contactId,
  });

  const clearChatMessages = () => {
    setMessages([]);
  };

  const {
    handleOldUserMessage,
    handleVerificationMethod,
    handleSilentAuthOldUser,
    handleSmsAuthFailure,
    handleAuthFlowRestart,
    handleCodeReenter,
  } = useExistingUserFlow({
    state,
    updateState,
    addBotMessage,
    addMessage,
    addMyMessage,
    clearChatMessages,
  });

  const { handleNewUserMessage, handleNewUserAction, handleSilentAuthNewUser } = useNewUserFlow({
    updateState,
    state,
    addBotMessage,
    addMessage,
    addMyMessage,
    onTwilioMessage,
    clearChatMessages,
  });

  const onSubmitText = async (newMessage, options) => {
    if (state.isPortalIntegration && !state.email) {
      addMyMessage(newMessage);
      if (validateEmail(newMessage)) {
        const isFound = await isEmailFound(newMessage);
        if (isFound) {
          updateState({ email: newMessage, flow: userFlows.EXISTING_USER });
          handleSilentAuthOldUser(newMessage);
        } else {
          updateState({ email: newMessage, flow: userFlows.NEW_USER });
          handleSilentAuthNewUser(newMessage);
        }
      }
      return;
    }
    if (state.flow === userFlows.EXISTING_USER) {
      handleOldUserMessage(newMessage);
    }
    if (state.flow === userFlows.NEW_USER) {
      handleNewUserMessage(newMessage, options);
    }
  };

  const prevMessagesLength = usePrevious(messages.length);

  const scrollToBottom = () => {
    if (isWeb()) {
      chatboxRef.current?.scrollTo({
        top: chatboxRef.current?.scrollHeight || 0,
        left: 0,
        behavior: 'smooth',
      });
    } else {
      chatboxRef.current?.scrollToEnd({ duration: 100, animated: true });
    }
  };

  useEffect(() => {
    if (messages.length > prevMessagesLength) {
      setTimeout(() => {
        scrollToBottom();
      }, 300);
    }
  }, [messages]);

  const handleSilentAuth = async (reply) => {
    if (reply.value) {
      if (state.flow === userFlows.EXISTING_USER) {
        handleSilentAuthOldUser(state.email);
      } else if (state.flow === userFlows.NEW_USER) {
        handleSilentAuthNewUser(state.email);
      }
    } else {
      updateState({ email: null });
      addBotMessage(translate('bot.askEmail'));
    }
  };

  const onQuickReply = (id, reply) => {
    addMyMessage(reply.label);
    switch (id) {
      case quickReplyIds.SELECT_VERIFY_METHOD:
        handleVerificationMethod(reply);
        break;
      case quickReplyIds.SELECT_USER_FLOW:
        updateState({ flow: reply.value });
        break;
      case quickReplyIds.SELECT_NEW_USER_ACTION:
        handleNewUserAction(reply);
        break;
      case quickReplyIds.EMAIL_CONFIRMATION:
        handleSilentAuth(reply);
        break;
      case quickReplyIds.SMS_AUTHENTICATION_FAILURE:
        // can only happen for existing users
        handleSmsAuthFailure(reply);
        break;
      case quickReplyIds.AUTHENTICATION_FLOW_RESTART:
        handleAuthFlowRestart(reply);
        break;
      case quickReplyIds.AUTHENTICATION_CODE_REENTER:
        handleCodeReenter(reply);
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    if (!state.flow && !companyBasicInfoLoading) {
      addMessage(quickReplyMessages(locale)[quickReplyIds.SELECT_USER_FLOW]);
    }

    if (!companyBasicInfoLoading && companyInfo.messengerStatus === MESSENGER_STATUS.DISABLED) {
      addBotMessage(translate('bot.workspace.disabled'));
    }

    scrollToBottom();
    const keyboardDidShowListener = Keyboard.addListener('keyboardDidShow', scrollToBottom);

    return () => {
      keyboardDidShowListener.remove();
    };
  }, [companyBasicInfoLoading]);

  useEffect(() => {
    if (!state.flowStarted && state.isPortalIntegration && state.email) {
      clearChatMessages();
      const quickReplyMessage = quickReplyMessages(locale)[quickReplyIds.EMAIL_CONFIRMATION];
      quickReplyMessage.message = translate('bot.getEmailConfirmation', state.email);
      addBotMessage(translate('bot.silentAuthGreeting', companyInfo?.name || state.companyName));
      addMessage(quickReplyMessage);
    }
  }, [state]);

  return {
    inputConfig: state.inputConfig,
    messages,
    chatboxRef,
    onSubmitText,
    onQuickReply,
  };
};

export default useAuthenticationFlow;
