export const styles = {
  container: {},
  wrap: {
    display: 'flex',
    backgroundColor: '#fff',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    flowDirection: 'row',
    gap: 100,
    height: '90%',
  },
  content: {
    alignItems: 'center',
    textAlign: 'center',
  },
  errorImage: {
    width: 310,
    height: 255,
    marginBottom: 20,
  },
  title: {
    fontSize: 20,
    fontWeight: 600,
    lineHeight: '40px',
  },
  commonTxt: {
    fontSize: 16,
  },
  companyLogoWrap: {
    marginBottom: 200,
  },
  image: {
    resizeMode: 'contain',
    width: '100%',
    height: '100%',
  },
  btnNextStep: {
    fontSize: 14,
    color: '#fff',
    textAlign: 'center',
    padding: 14,
    paddingLeft: 20,
    paddingRight: 20,
    borderRadius: 4,
  },
  footer: {
    marginTop: 'auto',
    marginBottom: 40,
    alignItems: 'center',
  },
  poweredBy: {
    fontSize: 14,
    fontWeight: 600,
    marginBottom: 5,
  },
};

export const getNextButton = ({ theme }) => [
  styles.btnNextStep,
  {
    backgroundColor: theme.primaryColor,
    color: theme.foreground,
  },
];
