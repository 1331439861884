import React from 'react';
import PropTypes from 'prop-types';
import { getUnassignedParticipantStyles } from './style';
import Common from 'src/components/Common';

export const ParticipantUnassigned = ({ size = 25 }) => {
  return <Common.Text style={getUnassignedParticipantStyles(size)}>?</Common.Text>;
};

ParticipantUnassigned.propTypes = {
  size: PropTypes.number,
};
