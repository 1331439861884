import React from 'react';
import styled from 'styled-components/native';
import { colors } from 'src/constants/theme';
import PropTypes from 'prop-types';

const Divider = ({ color, ...props }) => (
  <Line color={color} {...props} />
);

Divider.propTypes = {
  color: PropTypes.string,
};

Divider.defaultProps = {
  color: colors.black,
};

export default Divider;

const Line = styled.View`
  width: 100%;
  height: 1px;
  background-color: ${({ color }) => color};
`;
