import React, { memo, useEffect } from 'react';
import Common, {
  View, Text, TouchableOpacity, Row,
} from 'src/components/Common';
import styled from 'styled-components/native';
import { colors } from 'src/constants/theme';
import navigationService from 'src/utils/navigation';
import routeNames from 'src/constants/routeNames';
import { getDeviceIcon } from 'src/utils';
import { trackScreenView } from 'src/utils/analytics/GAtracker';
import useTheme from 'src/hooks/useTheme';
import { formatDate } from 'src/utils/date';

// eslint-disable-next-line react/prop-types
const SingleDevice = ({ route }) => {
  const device = route.params?.device;
  const { colors: themeColors, isDarkMode } = useTheme();
  if (!device) {
    return <Text size={20}>No Device!</Text>;
  }
  const onReportDevice = () => {
    navigationService.navigate(routeNames.NEW_REQUEST, { device });
  };

  useEffect(() => {
    trackScreenView(routeNames.SINGLE_DEVICE);
  }, []);

  const iconColor = isDarkMode ? colors.grey500 : colors.darkblue;
  return (
    <Common.ScrollView flexGrow={1} style={{ backgroundColor: themeColors.background }}>
      <Wrapper>
        <Row justifyContent="center" alignItems="center">
          {getDeviceIcon(device?.type?.name, iconColor)}
          <View style={{ marginLeft: 10 }} transparent>
            <Text size={21} bold>{device?.name ?? 'Unknown'}</Text>
            <Text size={17}>{device?.type?.name ?? 'Unknown'}</Text>
          </View>
        </Row>
        <Section>
          <Item isDarkMode={isDarkMode}>
            <Text size={17}>Device Settings Status</Text>
            <Text size={17} color={colors.grey500}>{device?.status?.name ?? '-'}</Text>
          </Item>
          <Item isDarkMode={isDarkMode}>
            <TouchableOpacity onPress={onReportDevice}>
              <Text color={colors.red} size={17}>Request Device Service</Text>
            </TouchableOpacity>
          </Item>
        </Section>
        <Section>
          {
            device.lastLoginName ? (
              <Item isDarkMode={isDarkMode}>
                <Text size={17}>Last Login</Text>
                <Text size={17} color={colors.grey500}>{device.lastLoginName}</Text>
              </Item>
            ) : null
          }
          {
            device.modelNumber ? (
              <Item isDarkMode={isDarkMode}>
                <Text size={17}>Model</Text>
                <Text size={17} color={colors.grey500}>{device.modelNumber}</Text>
              </Item>
            ) : null
          }
          {
            device.ram ? (
              <Item isDarkMode={isDarkMode}>
                <Text size={17}>RAM</Text>
                <Text size={17} color={colors.grey500}>{device.ram}</Text>
              </Item>
            ) : null
          }
          {
            device.localHardDrives ? (
              <Item isDarkMode={isDarkMode}>
                <Text size={17}>Hard Drive</Text>
                <Text size={17} color={colors.grey500}>{device.localHardDrives}</Text>
              </Item>
            ) : null
          }
          {
            device.purchaseDate && (
              <Item isDarkMode={isDarkMode}>
                <Text size={17}>Purchase Date</Text>
                <Text size={17} color={colors.grey500}>{formatDate(device.purchaseDate, 'MM/dd/yyyy')}</Text>
              </Item>
            )
          }
          {
            device.warrantyExpirationDate && (
              <Item isDarkMode={isDarkMode}>
                <Text size={17}>Warranty Expiration Date</Text>
                <Text size={17} color={colors.grey500}>{formatDate(device.warrantyExpirationDate, 'MM/dd/yyyy')}</Text>
              </Item>
            )
          }
        </Section>
      </Wrapper>
    </Common.ScrollView>
  );
};

export default memo(SingleDevice);

export const Wrapper = styled.View`
  padding-top: 30px;
  background-color: transparent;
`;

const Section = styled.View`
  margin: 30px 0px 20px 0px;
`;

const Item = styled.View`
  flex-direction: row;
  background-color: transparent;
  padding: 0px 20px;
  min-height: 50px;
  border-bottom-color: ${({ isDarkMode }) => (isDarkMode ? colors.grey800 : colors.grey5)};
  border-bottom-width: 1px;
  align-items: center;
  justify-content: space-between;
`;
