/**
 * This file supports push notifications for Messenger desktop app
 */
import { saveNotificationPermissionAction } from 'src/redux/notifications/actions';
import { store } from 'src/redux/store';
import { trackEvent } from 'src/utils/analytics/GAtracker';

function isNotificationSupported() {
  return 'Notification' in window;
}

export const promptNotifPermission = () => {
  if (isNotificationSupported()) {
    return Notification.requestPermission().then((permission) => {
      store.dispatch(saveNotificationPermissionAction(permission));
      return permission;
    });
  }
  return null;
};

export async function notifyDesktop({ title, options = {} }) {
  let notification;
  if (!isNotificationSupported()) {
    return null;
  }
  // electron app has default sound, but we are using custom audio sounds
  options.silent = true;

  if (Notification.permission === 'granted') {
    notification = new Notification(title, options);
  } else if (Notification.permission === 'default') {
    const permission = await promptNotifPermission();
    if (permission === 'granted') {
      notification = new Notification(title, options);
    }
  }

  return notification;
}

export const getNotifPermission = () => {
  if (isNotificationSupported()) {
    return Notification.permission;
  }
  return null;
};

export const NOTIFICATION_ANALYTICS_ACTIONS = {
  create: 'message_notification_create',
  click: 'message_notification_click',
};

const DESKTOP_TRACK_DATA = {
  category: 'Notifications',
  label: `desktop-${window.navigator.platform}`,
  value: 1,
};

/**
 * Google track event for Messenger Desktop Push-Notifications
 * @param analyticsData
 */
export const trackDesktopNotification = (analyticsData = {}) => {
  trackEvent({ ...DESKTOP_TRACK_DATA, ...analyticsData });
};
