import React, { useEffect } from 'react';
import useProfile from 'src/hooks/useProfile';
import { useDispatchMapper } from 'src/hooks/actionHooks';
import { updateProfileAction } from 'src/redux/profile/profileActions';

const AVAILABILITY_CHECK_INTERVAL = 1000 * 60 * 5; // 5m

const useAvailabilityHours = () => {
  const saveProfile = useDispatchMapper(updateProfileAction);
  const { companyInfo } = useProfile();
  const availabilityInfo = companyInfo?.availability || {};
  const availableStatus = availabilityInfo.status;
  const availableAt = availabilityInfo.available_in?.timestamp;

  // TODO: we should also implement logic to display back the out of hours banner
  // TODO: for now it is blocked by [CLB-414]
  useEffect(() => {
    if (!availableAt) return;

    const intervalId = setInterval(() => {
      const currenTimeStamp = Date.now();
      const availableTimeStamp = new Date(availableAt).getTime();

      if (currenTimeStamp - availableTimeStamp > 0 && availableStatus === false) {
        saveProfile({
          companyInfo: {
            availability: {
              ...availabilityInfo,
              status: true,
            },
          },
        });
      }
    }, AVAILABILITY_CHECK_INTERVAL);

    return () => {
      clearInterval(intervalId);
    };
  }, [availableAt]);
};

export { useAvailabilityHours };
