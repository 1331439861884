import React, { useEffect, useState } from 'react';
import useIntegrationActions from '../../../../shared/src/hooks/useIntegrationActions';
import SlackTeamsAuth from './components/SlackTeamsAuth';
import SuccessContent from './components/SuccessContent';
import SuccessContentWindows from './components/SuccessContentWindows';
import useProfile from '../../../../shared/src/hooks/useProfile';
import { useQueryValues } from '../../../../shared/src/hooks/useQueryValues';
import { useDispatchMapper } from '../../../../shared/src/hooks/actionHooks';
import { chatgenieAPI } from '../../../../shared/src/lib/api/chatgenieApi';
import { updateProfileAction } from '../../../../shared/src/redux/profile/profileActions';
import Spinner from '../../../../shared/src/components/Common/Spinner';
import { getCompanyDesign } from '../../../../shared/src/utils/normalizer';
import './index.css';
import { parseJwt } from '../../utils';

export const clearSavedVariables = () => {
  localStorage.removeItem('teamsAppId');
  localStorage.removeItem('teamsAppName');
  localStorage.removeItem('customToken');
  localStorage.removeItem('appId');
};

const checkIfTokenExpired = (token) => {
  try {
    const expirationTime = new Date(parseJwt(token).exp * 1000).getTime();
    const difference = expirationTime - new Date().getTime();
    if (difference > 0) {
      return false;
    }
    return true;
  } catch {
    return true;
  }
};

const SupportBot = () => {
  const { state, updateState } = useIntegrationActions();
  const { companyInfo, loggedIn } = useProfile();
  const { appId = localStorage.getItem('appId'), teamsAppId, teamsAppName, token } = useQueryValues();
  const updateProfile = useDispatchMapper(updateProfileAction);
  const setLocalStorage = (key, value) => value && localStorage.setItem(key, value);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    setLocalStorage('teamsAppId', teamsAppId);
    setLocalStorage('teamsAppName', teamsAppName);
    setLocalStorage('customToken', token);
    setLocalStorage('appId', appId);

    const customToken = token || localStorage.getItem('customToken');
    const isTokenExpired = checkIfTokenExpired(customToken);

    updateProfile({
      token: customToken,
      loggedIn: customToken && !isTokenExpired,
    });

    document.querySelector('html').style.height = 'auto';
  }, []);

  useEffect(() => {
    if (loggedIn) {
      setIsLoading(true);
      chatgenieAPI
        .getCompanyBasicInfo(appId)
        .then(({ data: { data } }) => {
          const normalized = {
            companyInfo: {
              ...companyInfo,
              ...getCompanyDesign(data),
              appId,
            },
            loggedIn: true,
          };
          updateProfile(normalized);
          setIsLoading(false);
        })
        .catch((err) => {
          console.error(err);
          updateState({ error: `Error while fetching company data: ${err?.message}`, loading: false });
          setIsLoading(false);
        });
    } else {
      setIsLoading(false);
    }
  }, [loggedIn]);

  if (isLoading) {
    return (
      <div className="support-bot-spinner-wrapper">
        <Spinner />
      </div>
    );
  }

  // Windows success page (only consent to M365)
  if (state.success && state.chatType === 'windows') {
    return <SuccessContentWindows />;
  }

  // Slack or Teams success page
  if (state.success) {
    return <SuccessContent />;
  }

  // Pick Slack or Teams as chat option
  return <SlackTeamsAuth />;
};

export default SupportBot;
