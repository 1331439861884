import Axios from 'axios';
import { isCompanyProtek } from 'src/utils';
import moize from 'moize';
import map from 'lodash/map';
import { normalizeAlert } from 'src/utils/normalizer';
import Config from 'react-native-config';

const creds = {
  protek: {
    spaceId: Config.PROTEK_CONTENTFUL_SPACE_ID,
    token: Config.PROTEK_CONTENTFUL_TOKEN,
  },
  default: {
    spaceId: Config.CONTENTFUL_SPACE_ID,
    token: Config.CONTENTFUL_TOKEN,
  },
};

const memoizeResponse = moize((arr) => map(arr, normalizeAlert));

export const fetchAlerts = ({ parentId }) => {
  let data = creds.default;
  if (isCompanyProtek(parentId)) {
    data = creds.protek;
  }
  if (!data.spaceId || !data.token) return null;
  return Axios.get(
    `https://cdn.contentful.com/spaces/${data.spaceId}/environments/master/entries?select=fields,sys.id&content_type=alert&access_token=${data.token}`,
  ).then((res) => {
    if (res.status === 200 && res?.data?.items?.length) {
      return memoizeResponse(res?.data?.items);
    }
    return [];
  });
};
