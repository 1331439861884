import Axios from 'axios';
import { Platform } from 'react-native';
import { store } from 'src/redux/store';
import { v4 as uuidv4 } from 'uuid';
import Config from 'react-native-config';
import * as Sentry from '@sentry/react-native';
import {
  getAppName,
  getAppVersion, isElectron, isProduction, isWeb,
} from '../index';

const getElectronUserAgent = () => {
  const electronVersion = window.navigator.appVersion.match(/Electron\/(.*\s)/g) || 'Unknown';
  const platform = window.navigator.appVersion.match(/\(.*\d+\) /gi) || 'Unknown Device';
  return `${electronVersion} ${platform}`;
};

const ga = (params) => {
  if (!isProduction()) return null;
  const cid = isElectron() ? window?.ipc?.sendSync('get-cid') : store.getState().profile?.uuid;

  const payload = new URLSearchParams({
    v: 1,
    tid: Config.ANALYTICS_ID,
    ua: isElectron() ? getElectronUserAgent() : window?.navigator?.userAgent,
    cid: cid || uuidv4(),
    an: `Messenger ${getAppName()}`,
    av: getAppVersion(),
    ...params,
  }).toString();
  return Axios.post('https://google-analytics.com/collect', payload);
};

export const trackEvent = ({
  category,
  action,
  label,
  value,
}) => {
  const payload = {
    t: 'event',
    ec: category,
    ea: action,
  };
  if (label) payload.el = label;
  if (value) payload.ev = value;
  if (payload.ec && payload.ea) {
    return ga(payload);
  }
  Sentry.captureMessage('GA event does not have ec and ea properties');
  return null;
};

const getDocumentHost = () => {
  if (isElectron()) {
    return window?.navigator?.platform;
  }
  if (isWeb()) {
    return document?.referrer;
  }
  return Platform.OS;
};

export const trackScreenView = (screenName) => {
  if (isProduction()) {
    ga({
      dh: getDocumentHost(),
      dr: getDocumentHost(),
      ds: isElectron() ? 'desktop' : Platform.OS,
      t: 'pageview',
      dp: screenName.toLowerCase(), // Document Path
      dt: screenName, // Document Title
      cd: screenName, // Screen Name
    });
  }
};
