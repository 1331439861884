import { useSelector } from 'react-redux';
import { redirectUris, slackClientId } from 'src/config';
import { chatTypes } from 'src/constants';
import { chatgenieAPI } from 'src/lib/api/chatgenieApi';
import { updateIntegrationAction } from 'src/redux/integration/actions';
import { getSlackAuthUrl } from 'src/utils/api';
import { useDispatchMapper } from './actionHooks';
import useProfile from './useProfile';

const useIntegrationActions = () => {
  const { companyInfo } = useProfile();
  const state = useSelector((store) => store.integration);
  const updateState = useDispatchMapper(updateIntegrationAction);

  const connectSlack = () => {
    // TODO: we need to fetch this url from backend to be consistent with teams setup
    window.location = getSlackAuthUrl({
      redirectUri: encodeURIComponent(redirectUris.slackRedirect),
      appId: companyInfo?.appId,
      clientId: companyInfo.slackClientId || slackClientId,
    });
  };

  const connectTeams = async () => {
    try {
      updateState({ error: null, loading: true, chatType: chatTypes.MS_TEAMS });
      const response = await chatgenieAPI.getTeamsAuthUrl(
        redirectUris.supportBot,
        companyInfo?.appId,
        chatTypes.MS_TEAMS,
      );
      if (response?.data?.data?.authUrl) {
        window.location = response.data.data.authUrl;
      } else {
        const errorMessage = response?.response?.data?.message;
        updateState({ loading: false, error: `Thread Teams App authentication failure: ${errorMessage}` });
      }
    } catch (err) {
      const errorMessage = err?.response?.data?.message;
      updateState({ loading: false, error: `Thread Teams App authentication failure: ${errorMessage}` });
    }
  };

  const connectWindows = async () => {
    try {
      updateState({ error: null, loading: true, chatType: chatTypes.WINDOWS });
      const response = await chatgenieAPI.getTeamsAuthUrl(
        redirectUris.supportBot,
        companyInfo?.appId,
        chatTypes.WINDOWS,
      );

      if (response?.data?.data?.authUrl) {
        window.location = response.data.data.authUrl;
      } else {
        const errorMessage = response?.response?.data?.message;
        updateState({ loading: false, error: `Thread Windows App authentication failure: ${errorMessage}` });
      }
    } catch (err) {
      const errorMessage = err?.response?.data?.message;
      updateState({ loading: false, error: `Thread Windows App authentication failure: ${errorMessage}` });
    }
  };

  return {
    connectSlack,
    connectTeams,
    connectWindows,
    state,
    updateState,
  };
};
export default useIntegrationActions;
