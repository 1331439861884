const actionTypes = {
  CHAT_MESSAGES: 'CHAT_MESSAGES',
  CHAT_HISTORY_UNREAD_UPDATE: 'CHAT_HISTORY_UNREAD_UPDATE',
  CHAT_TICKET: 'CHAT_TICKET',
  CHAT_NEW_TICKET_CREATION_STATE: 'CHAT_NEW_TICKET_CREATION_STATE', // used when new ticket is being created
  RESET_CHAT: 'RESET_CHAT',
  MFA_ADD: 'MFA_ADD',
  MFA_DELETE: 'MFA_DELETE',
  MFA_UPDATE: 'MFA_UPDATE',
  MFA_LIST_UPDATE: 'MFA_LIST_UPDATE',
  NOTIFICATION_COUNT_INCREMENT: 'NOTIFICATION_COUNT_INCREMENT',
  THEME_UPDATE: 'THEME_UPDATE',
  RESET_THEME: 'RESET_THEME',
  UPDATE_PROFILE: 'UPDATE_PROFILE',
  UPDATE_PROFILE_SOUND_PREFERENCE: 'UPDATE_PROFILE_SOUND_PREFERENCE',
  UPDATE_PROFILE_ONBOARDING: 'UPDATE_PROFILE_ONBOARDING',
  SET_CACHE_FETCHED: 'SET_CACHE_FETCHED',
  RESET_STORE: 'RESET_STORE',
  CLOSE_POPUP: 'CLOSE_POPUP',
  OPEN_POPUP: 'OPEN_POPUP',
  RESET_POPUP: 'RESET_POPUP',
  SET_ACTIVE_CHANNEL: 'SET_ACTIVE_CHANNEL',
  SET_TWILIO_CHANNEL: 'SET_TWILIO_CHANNEL',
  SET_UNREAD_ALERTS: 'SET_UNREAD_ALERTS',
  SET_READ_ALERTS: 'SET_READ_ALERTS',
  RESET_UNREAD_ALERTS: 'RESET_UNREAD_ALERTS',
  SET_OPEN_ALERT: 'SET_OPEN_ALERT',
  UPDATE_INTEGRATION: 'UPDATE_INTEGRATION',
  AUTH_UPDATE: 'AUTH_UPDATE',
  SET_ACTIVE_TICKET: 'SET_ACTIVE_TICKET',
  UPDATE_TICKETS_NOTIFICATION: 'UPDATE_TICKETS_NOTIFICATION',
  SAVE_NOTIFICATION_PERMISSION: 'SAVE_NOTIFICATION_PERMISSION',
  UPDATE_NOTIFICATION_ENABLED: 'UPDATE_NOTIFICATION_ENABLED',
  SET_APOLLO_CONNECTION_INDICATOR: 'SET_APOLLO_CONNECTION_INDICATOR',
};

export default actionTypes;
