import Axios from 'axios';
import Config from 'react-native-config';
import { store } from 'src/redux/store';
import { updateProfileAction } from 'src/redux/profile/profileActions';
import { chatgenieAPI } from 'src/lib/api/chatgenieApi';
import { normalizeUserInfoFromCompany } from 'src/utils/normalizer';
import { userFlows } from 'src/constants';

// TODO: later we can refactor Messenger to only use this util function
export function exchangeAuthCodeForToken(authCode) {
  return Axios.post(`${Config.CHATGENIE_API_URL}/oauth/token`, {
    code: authCode,
    redirect_uri: `${Config.BASE_DOMAIN}/auth-end`,
  }).then((res) => res.data.data);
}

// TODO: later we can refactor Messenger to only use this util function
export async function initMessenger(appId, accessToken, refreshToken = null) {
  store.dispatch(
    updateProfileAction({
      token: accessToken,
      refreshToken: refreshToken,
      companyInfo: { appId },
    }),
  );

  const state = await fetchAndSelectCompany();
  store.dispatch(updateProfileAction(state));
}

const fetchAndSelectCompany = async () => {
  const response = await chatgenieAPI.getCompanies();
  const companies = response?.data?.filter((company) => company.is_contact) || [];
  const profilePayload = {
    flow: userFlows.EXISTING_USER,
    loggedIn: true,
  };

  if (companies.length === 1) {
    profilePayload.companyInfo = {
      id: companies[0].id,
      parentId: companies[0].parent_id,
      appId: companies[0].app_id,
    };
    profilePayload.userInfo = {
      ...(store.getState().profile?.userInfo || {}),
      ...normalizeUserInfoFromCompany(companies[0].contacts?.[0]),
    };
  }

  return profilePayload;
};
