import React, { useState } from 'react';
import { colors } from 'src/constants/theme';
import { useAuth } from 'src/hooks/useAuth';
import { isInsideMSTeams } from 'src/utils';
import Common from '../Common';
import styles from './style';
import useTranslation from 'src/hooks/useTranslation';
import useProfile from 'src/hooks/useProfile';
import { INTEGRATIONS } from 'src/constants';

export default function LogoutButton({ style, children }) {
  const [loggingOut, setLoggingOut] = useState(false);
  const { logout } = useAuth();
  const { connectionType } = useProfile();
  const { translate } = useTranslation();

  const onLogout = () => {
    setLoggingOut(true);
    logout(() => setLoggingOut(false));
  };

  const shouldShowLogout =
    !isInsideMSTeams() && ![INTEGRATIONS.CLOUD_RADIAL, INTEGRATIONS.MESSENGER_AUTH].includes(connectionType);

  return (
    <Common.Row>
      {shouldShowLogout && (
        <>
          {children}
          <Common.TouchableOpacity
            disabled={loggingOut}
            onPress={onLogout}
            testID="logout-button"
            style={[styles.logoutBtn, style || {}]}
          >
            <Common.Text color={colors.red} size={15} weight="800">
              {loggingOut ? translate('account.auth.logOut.loading') : translate('account.auth.logOut')}
            </Common.Text>
          </Common.TouchableOpacity>
        </>
      )}
    </Common.Row>
  );
}
