import React from 'react';
import { Linking } from 'react-native';

const useInitialUrl = () => {
  const [url, setUrl] = React.useState(null);
  const [processing, setProcessing] = React.useState(true);

  const handleLinkChangeEvents = (event) => {
    if (event?.url) {
      setUrl(event.url);
      setProcessing(false);
    }
  };

  React.useEffect(() => {
    const getUrlAsync = async () => {
      // Get the deep link used to open the app
      const initialUrl = await Linking.getInitialURL();
      setUrl(initialUrl);
      setProcessing(false);
    };
    Linking.addEventListener('url', handleLinkChangeEvents);

    getUrlAsync();
    return () => {
      Linking.removeEventListener('url', handleLinkChangeEvents);
    };
  }, []);

  return { url, processing };
};

export default useInitialUrl;
