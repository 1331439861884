import { StyleSheet } from 'react-native';

const arrowStyle = {
  position: 'absolute',
  top: 80,
};

const styles = StyleSheet.create({
  leftArrow: {
    ...arrowStyle,
    left: 0,
  },
  rightArrow: {
    ...arrowStyle,
    right: 0,
  },
});

export default styles;
