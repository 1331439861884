import { colors } from 'src/constants/theme';

export const styles = {
  participantAvatar: {
    borderRadius: '50%',
    border: '1px solid #fff',
  },
  participantUnassignedAvatar: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '50%',
    color: colors.grey4,
    backgroundColor: colors.grey9,
    fontWeight: 'bold',
  },
};

export const getUnassignedParticipantStyles = (size) => {
  return {
    ...styles.participantUnassignedAvatar,
    width: size,
    height: size,
  };
};
