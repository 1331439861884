import * as React from "react";

const Spinner = props => <svg className="spinner" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" {...props}><circle cx={16} cy={16} fill="none" r={14} strokeWidth={4} style={{
    stroke: "#051275",
    opacity: 0.2
  }} /><circle cx={16} cy={16} fill="none" r={14} strokeWidth={4} style={{
    stroke: "#051275",
    strokeDasharray: 115,
    strokeDashoffset: 60
  }}><animateTransform attributeName="transform" attributeType="XML" type="rotate" from="0 16 16" to="360 16 16" dur="1s" repeatCount="indefinite" /></circle></svg>;

export default Spinner;