import { StyleSheet } from 'react-native';
import { colors } from 'src/constants/theme';
import { responsiveWidth } from 'src/utils/responsive';

const styles = StyleSheet.create({
  card: {
    borderRadius: 4,
    padding: 10,
    width: responsiveWidth(70),
    minWidth: responsiveWidth(70),
    height: 120,
    marginLeft: 15,
  },
  sectionTitle: {
    marginLeft: 15,
    fontWeight: '600',
    marginTop: 15,
  },
  description: {
    marginTop: 8,
    color: colors.white,
  },
  alertIconWrapper: {
    backgroundColor: colors.red2,
    width: 25,
    height: 25,
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 1000,
  },
  learnMore: {
    color: colors.white,
    position: 'absolute',
    bottom: 10,
    left: 10,
  },
});

export default styles;
