import { useCallback } from 'react';
import { v4 } from 'uuid';
import { normalizeTwilioMessage } from 'src/utils/normalizer';

const useMessageHandlers = ({ saveMessage, contactId }) => {
  const addMessage = useCallback((message) => {
    // eslint-disable-next-line no-param-reassign
    if (!message.timestamp) {
      message.timestamp = new Date();
    }
    if (!message.id) {
      message.id = v4();
    }
    saveMessage(message);
  }, []);

  const addBotMessage = (message) => {
    if (!message) return;

    if (typeof message === 'string') {
      addMessage({ type: 'text', isMine: false, message });
    } else {
      addMessage({ ...message, isMine: false });
    }
  };

  const addMyMessage = (message) => {
    if (!message) return;
    addMessage({ type: 'text', isMine: true, message });
  };

  const onTwilioMessage = async (newMessage) => {
    const convertedMessage = normalizeTwilioMessage(newMessage, contactId);
    if (convertedMessage) {
      addMessage(convertedMessage);
    }
  };

  return {
    addBotMessage,
    addMyMessage,
    addMessage,
    onTwilioMessage,
  };
};

export default useMessageHandlers;
