import React, { memo, useLayoutEffect } from 'react';
import Common from 'src/components/Common';
import DevicesList from 'src/components/DevicesList';
import useTheme from 'src/hooks/useTheme';
import styles from './selectDevice.style';
import { screenCommonStyles } from 'src/screens/common.styles';

const SelectDevice = ({ route, navigation }) => {
  const onClickListItem = route.params?.onClickListItem;
  const { colors } = useTheme();

  useLayoutEffect(() => {
    navigation.setOptions({
      headerTitleContainerStyle: screenCommonStyles.headerTitleContainerStyle
    });
  }, []);

  return (
    <Common.View style={styles.devicesWrapper} flexGrow={1} color={colors.background}>
      <DevicesList onClickListItem={onClickListItem} />
    </Common.View>
  );
};

export default memo(SelectDevice);
