import routeNames from 'src/constants/routeNames';
import Account from 'src/screens/Account';
import Auth from 'src/screens/Auth';
import SingleDevice from 'src/screens/Account/SingleDevice';
import NewRequest from 'src/screens/NewRequest';
import SelectDevice from 'src/screens/SelectDevice';
import SelectUser from 'src/screens/SelectUser';
import SelectCompany from 'src/screens/SelectCompany';
import Now from 'src/screens/Now';
import Chat from 'src/screens/Chat';

export const routesConfig = {
  [routeNames.AUTH]: '/',
  [routeNames.NOW]: '/main',
  [routeNames.CHAT]: '/chat',
  [routeNames.ACCOUNT]: '/account',
  [routeNames.NEW_REQUEST]: '/new-request',
  [routeNames.SELECT_COMPANY]: '/select-company',
  [routeNames.SELECT_USER]: '/select-user',
};

export const routes = {
  auth: [{ name: routeNames.AUTH, component: Auth }],
  main: [
    { name: routeNames.NOW, component: Now },
    { name: routeNames.CHAT, component: Chat },
    { name: routeNames.SELECT_DEVICE, component: SelectDevice },
    { name: routeNames.SELECT_USER, component: SelectUser },
    { name: routeNames.SINGLE_DEVICE, component: SingleDevice },
    { name: routeNames.NEW_REQUEST, component: NewRequest },
    { name: routeNames.SELECT_COMPANY, component: SelectCompany },
    { name: routeNames.ACCOUNT, component: Account },
  ],
};
