import axios from 'axios';
import fileDownload from 'js-file-download';
import { captureMessage } from '@sentry/react-native';

/**
 *
 * @param url - url to file
 * @param fileName - filename with extension
 */
export const downloadFile = async (url, fileName) => {
  try {
    const res = await axios.get(url, {
      responseType: 'blob',
    });
    fileDownload(res.data, fileName);
  } catch (err) {
    captureMessage(`Could not download ${fileName}: ${err.message}`);
  }
};