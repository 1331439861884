import React from 'react';
import { TouchableOpacity } from 'react-native';
import { XIcon } from 'src/assets/icons';
import { isElectron, isMobileWeb } from 'src/utils';
import webchatIframe from 'src/utils/webchatIframe';

const buttonStyle = {
  height: 30,
  width: 30,
  alignItems: 'center',
  justifyContent: 'center',
  borderRadius: 100,
  backgroundColor: 'rgba(118, 118, 128, 0.12)',
  marginRight: 16,
};

const XButton = () => {
  const close = () => {
    if (isMobileWeb()) {
      webchatIframe.close();
    } else if (isElectron()) {
      window?.ipc?.send('close');
    }
  };
  if (isMobileWeb() || isElectron()) {
    return (
      <TouchableOpacity style={buttonStyle} onPress={close}>
        <XIcon />
      </TouchableOpacity>
    );
  }
  return null;
};

export default XButton;
