/* eslint-disable no-nested-ternary */
import React, { useEffect, useRef } from 'react';
import Spinner from '../../../../../../shared/src/components/Common/Spinner';
import { redirectUris } from '../../../../../../shared/src/config';
import { chatTypes } from '../../../../../../shared/src/constants';
import { chatgenieAPI } from '../../../../../../shared/src/lib/api/chatgenieApi';
import useIntegrationActions from '../../../../../../shared/src/hooks/useIntegrationActions';
import useProfile from '../../../../../../shared/src/hooks/useProfile';
import { isStringObject } from '../../../../../../shared/src/utils';
import IntegrationButtons from '../../../../../../shared/src/components/IntegrationButtons';
import Content from '../Content';
import chatgenieLogo from '../../../../../../shared/src/assets/images/new-logo.png';
import { useQueryValues } from '../../../../../../shared/src/hooks/useQueryValues';
import { clearSavedVariables } from '../..';

const SlackTeamsAuth = () => {
  const { connectSlack, connectTeams, connectWindows, state, updateState } = useIntegrationActions();
  const { code, state: callbackState, type, teamsAppId: queryTeamsAppId } = useQueryValues();
  const teamsAppId = useRef(queryTeamsAppId || localStorage.getItem('teamsAppId'));
  const { loggedIn, companyInfo } = useProfile();

  const getIntegrationApp = (chatType) => {
    if (chatType === chatTypes.SLACK) {
      return 'Slack';
    }

    if (chatType === chatTypes.MS_TEAMS) {
      return 'Teams';
    }

    return 'Windows';
  };

  const handleRunIntegration = async (integrationRequest, chatType) => {
    const integrationApp = getIntegrationApp(chatType);

    try {
      updateState({ error: null, loading: true, chatType });
      const response = await integrationRequest();

      if (response.status === 201) {
        updateState({ loading: false, success: true });
      } else {
        const errorMessage = response?.response?.data?.message;
        updateState({ loading: false, error: `Thread ${integrationApp} App authentication failure: ${errorMessage}` });
        clearSavedVariables();
        console.error(errorMessage);
      }
    } catch (err) {
      const errorMessage = err?.response?.data?.message;
      updateState({
        loading: false,
        error: `Thread ${integrationApp} App authentication or installation failure: ${errorMessage}`,
      });
      clearSavedVariables();
      console.error(err);
    }
  };

  const runSlackIntegration = (code) => {
    handleRunIntegration(() => chatgenieAPI.slackAuth(code, redirectUris.slackRedirect), chatTypes.SLACK);
  };

  const runTeamsIntegration = async (code, parsedState) => {
    handleRunIntegration(
      () => chatgenieAPI.teamsAuth(code, parsedState?.verificationHash, redirectUris.supportBot, teamsAppId.current),
      chatTypes.MS_TEAMS,
    );
  };

  const runWindowsIntegration = async (code, parsedState) => {
    handleRunIntegration(
      () => chatgenieAPI.windowsAuth(code, parsedState?.verificationHash, redirectUris.supportBot),
      chatTypes.WINDOWS,
    );
  };

  useEffect(() => {
    if (state.loading) return;
    if (!isStringObject(callbackState)) return;

    const parsedState = JSON.parse(callbackState);
    if (code && parsedState.chatType) {
      if (parsedState.chatType === chatTypes.SLACK) {
        runSlackIntegration(code, parsedState);
      }
      if (parsedState.chatType === chatTypes.MS_TEAMS) {
        runTeamsIntegration(code, parsedState);
      }
      if (parsedState.chatType === chatTypes.WINDOWS) {
        runWindowsIntegration(code, parsedState);
      }
    }
  }, []);

  // automatically choosing integration type based on 'type' query param
  useEffect(() => {
    if (loggedIn && companyInfo?.appId && !state.loading) {
      if (type === 'ms_teams') {
        connectTeams();
      } else if (type === 'windows') {
        connectWindows();
      } else if (type === 'slack') {
        connectSlack();
      }
    }
  }, [loggedIn, companyInfo]);

  useEffect(() => {
    if (!loggedIn) {
      updateState({ error: 'You are not authenticated! Please try another magic link.' });
    }
  }, []);

  return (
    <Content
      header={
        <>
          <img data-testid="logo" className="logo" src={companyInfo?.image || chatgenieLogo} alt="thread logo" />
          <p className="title">Collaborate smarter with thread.</p>
          <p className="description">
            Thread is the one stop shop for awesome support collaboration, with instant messenger integration that fits
            your workflow.
          </p>
        </>
      }
      mainContent={
        <>
          <p className="error-message">{state.error}</p>
          {state.loading ? (
            <div className="loading">
              <Spinner />
            </div>
          ) : loggedIn && type !== 'windows' ? (
            <div>
              <p className="question">Where do you chat today?</p>
              <div className="buttons-wrapper">
                <IntegrationButtons loading={state.loading} connectSlack={connectSlack} connectTeams={connectTeams} />
              </div>
            </div>
          ) : null}
        </>
      }
      sideContent={<div className="overlay" />}
    />
  );
};

export default SlackTeamsAuth;
